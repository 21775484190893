import React, { useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_ALERT_WARNING, IC_REFRESH, IC_TRASH } from "../assets";
import IconContainer from "../components/IconContainer";

export default function LoadRecentTemplateDialog(props: DialogProps<any>) {
  const [busy, setBusy] = useState(false);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_REFRESH />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Load Recent Template
              </Typography>
            </Stack>
          </Stack>
          <Divider />

          <Typography fontSize={14} fontWeight={400} sx={{ px: "24px" }}>
            Do you want to load the most recently created configuration
            template?
          </Typography>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={() => props.closeHandler(false)}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              No thanks
            </Button>
            <Button
              onClick={() => {
                setBusy(true);
                props.closeHandler(true);
              }}
              disabled={busy}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
            >
              Load
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
