import React from "react";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { ELLIPTICAL_GLOW, HAND_DRAWING, LOGO_VERTICAL } from "../assets";
import { Player } from "@lottiefiles/react-lottie-player";

export default function LoadingOverlay(props: {
  busy: boolean;
  loadingText?: string;
}) {
  const desktop = useMediaQuery("(min-width:720px)");
  return (
    <Stack
      sx={{
        display: props.busy ? "flex" : "none",
        height: "100vh",
        width: "100vw",
        position: "absolute",
        zIndex: 100,
        background: "#101010B0",
      }}
    >
      <img
        style={{
          position: desktop ? "fixed" : "initial",
          top: "64px",
          left: "80px",
          pointerEvents: "none",
          height: "80px",
          objectFit: "contain",
          margin: desktop ? undefined : "28px",
        }}
        alt="PFM Logo"
        src={LOGO_VERTICAL}
      />
      <img
        style={{
          position: "fixed",
          left: "0px",
          bottom: "0px",
          pointerEvents: "none",
          height: desktop ? undefined : "50px",
          zIndex: -1,
        }}
        alt="Hand Drawing"
        src={HAND_DRAWING}
      />
      <img
        style={{
          position: "fixed",
          right: "0px",
          top: "0px",
          pointerEvents: "none",
        }}
        alt="Elliptical Glow"
        src={ELLIPTICAL_GLOW}
      />
      <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
        <Player
          autoplay
          loop
          src="https://lottie.host/6b473308-ee05-415d-bcb2-3ad6d6d3e513/MLpDXNuUB7.json"
          style={{ height: "180px" }}
        />
      </Stack>
    </Stack>
  );
}
