import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import { IC_PEN, IC_ADD_USER, IC_ALERT_WARNING, IC_CLOSE } from "../assets";
import IconContainer from "../components/IconContainer";
import PFMAutoComplete from "../components/PFMAutoComplete";
import { enqueueSnackbar } from "notistack";
import { RestAddUser, RestDeleteProduct, RestDisableUser } from "../core/rest";
import { Subscription, User } from "@pfm/types";
import PFMCheckbox from "../components/PFMCheckbox";
import moment from "moment";
import { parseStripeAmount } from "../core/helper";

export default function UpdateSubscriptionDialog(
  props: DialogProps<{ new: Subscription; old: Subscription }>
) {
  const [busy, setBusy] = useState(false);

  async function updateSubscription() {
    props.closeHandler(true);
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_PEN />
            </IconContainer>
            <Stack>
              <Stack direction={"row"} alignItems={"center"} spacing="8px">
                <Typography fontSize={18} fontWeight={600}>
                  Update Subscription?
                </Typography>
                <Tooltip title="Click to copy subscription id.">
                  <Chip
                    onClick={() =>
                      navigator.clipboard.writeText(props.data?.new.id || "")
                    }
                    size="small"
                    label={props.data?.new.id}
                    variant="identifier"
                  />
                </Tooltip>
              </Stack>
              <Typography fontSize={14} fontWeight={400}>
                Are you sure you want to update the subscription model?
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Subscription name
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.old.name} ➡️ {props.data?.new.name}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Products
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px", textTransform: "capitalize" }}
              >
                {props.data?.new.products.map((p, ind) => (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={"8px"}
                  >
                    <Avatar
                      src={p.images?.[0]}
                      sx={{ width: "20px", height: "20px" }}
                    />
                    <Typography>
                      {p.name +
                        (ind + 1 < props.data?.new.products.length!
                          ? ", "
                          : "")}
                    </Typography>
                  </Stack>
                ))}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Kind
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px", textTransform: "capitalize" }}
              >
                {props.data?.old.kind} ➡️ {props.data?.new.kind}
              </Typography>
            </Stack>
            {props.data?.new.kind === "recurring" && (
              <Stack direction={"row"} sx={{ width: "100%" }}>
                <Typography
                  fontSize={14}
                  fontWeight={400}
                  sx={{ minWidth: "150px" }}
                >
                  Schedule
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  sx={{ minWidth: "150px", textTransform: "capitalize" }}
                >
                  {props.data?.new.schedule}
                </Typography>
              </Stack>
            )}
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Updated Price
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px", textTransform: "capitalize" }}
              >
                {props.data?.old.price.currency.toUpperCase()}{" "}
                {parseStripeAmount(props.data?.old.price.amount!).dollars +
                  "." +
                  parseStripeAmount(props.data?.old.price.amount!).cents}
                ➡️
                {props.data?.new.price.currency.toUpperCase()}{" "}
                {parseStripeAmount(props.data?.new.price.amount!).dollars +
                  "." +
                  parseStripeAmount(props.data?.new.price.amount!).cents}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Created
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {moment
                  .unix(props.data?.old.createdTimestamp!)
                  .format("MMM DD, YYYY HH:mm:ss")}{" "}
                by {props.data?.old.createdByName}
              </Typography>
            </Stack>
            <Alert
              color="warning"
              variant="filled"
              icon={<IC_ALERT_WARNING stroke="#F79009" fill="transparent" />}
            >
              <AlertTitle>Warning</AlertTitle>
              <Typography>
                Updating a subscription does not affect any associated active
                subscriptions. The existing subscriptions will continue to use
                the old subscription model. If you wish to update the customer
                active subscriptions to the newest ones, you must upgrade their
                subscription from customer profile.
              </Typography>
            </Alert>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={updateSubscription}
              disabled={busy}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
            >
              Update
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
