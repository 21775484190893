import {
  Button,
  Chip,
  IconButton,
  Paper,
  Typography,
  Stack,
  useTheme,
  Divider,
  Tooltip,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { IC_HELP, IC_SETTINGS, LOGO_PFM } from "../assets";
import { StripeProduct, Subscription } from "@pfm/types";
import { getCurrencySymbol, parseStripeAmount } from "../core/helper";
import { useModal } from "mui-modal-provider";
import ProductInfoDialog from "../dialogs/ProductInfo";
import { enqueueSnackbar } from "notistack";
import { RestCreateCheckoutSession } from "../core/rest";
export default function BuySubscriptionCard(props: {
  subscription: Subscription;
}) {
  const theme = useTheme();
  const { showModal } = useModal();
  const desktop = useMediaQuery("(min-width: 700px)");
  const [busy, setBusy] = useState(false);

  const priceInfo = parseStripeAmount(props.subscription.price.amount);
  const priceTag = `${getCurrencySymbol(props.subscription.price.currency)} ${
    priceInfo.dollars
  }.${priceInfo.cents}`;

  function showInfoDialog(p: StripeProduct) {
    const modal = showModal(ProductInfoDialog, {
      closeHandler(result) {
        modal.destroy();
      },
      data: p,
    });
  }

  async function buyNow() {
    try {
      setBusy(true);
      const res = await RestCreateCheckoutSession(props.subscription.id);
      window.open(res.url);
    } catch (err: any) {
      enqueueSnackbar("Error generating checkout url. Please try again.", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  return (
    <Paper
      sx={{
        width: desktop ? "388px" : "300px",
        background: "#101828",
        borderRadius: "12px",
        pt: "36px",
        pb: "24px",
      }}
      elevation={0}
    >
      <Stack spacing="24px">
        {/* Name and description  */}
        <Stack spacing="8px" sx={{ px: "24px" }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontSize={24} fontWeight={500}>
              {props.subscription.name}
            </Typography>
          </Stack>

          {/* <Typography
            sx={{
              textOverflow: "ellipsis",
              overflow: "auto",
              maxHeight: "48px",
            }}
          >
            Subscription description
          </Typography> */}
        </Stack>

        {/* Price  */}
        <Stack spacing="0px" sx={{ px: "24px" }}>
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <Typography fontSize={16} fontWeight={500}>
              Price
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing="8px">
            <Typography fontSize={30} fontWeight={600}>
              {priceTag}
            </Typography>
          </Stack>
        </Stack>

        {/* Subscription Info Labels  */}
        <Stack spacing="8px" sx={{ px: "24px" }}>
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <Typography fontSize={16} fontWeight={500}>
              Included Products
            </Typography>
            <Tooltip
              title="Click on a product's name to view its details."
              arrow
              placement="right"
            >
              <IC_HELP />
            </Tooltip>
          </Stack>
          <Stack direction={"row"} spacing="8px">
            {props.subscription.products.map((p) => (
              <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                <Avatar
                  sx={{ height: "32px", width: "32px" }}
                  src={p.images?.[0] || LOGO_PFM}
                />
                <Typography
                  sx={{ cursor: "pointer" }}
                  onClick={() => showInfoDialog(p)}
                >
                  {p.name}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>

        <Stack
          spacing="8px"
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
          sx={{ px: "24px" }}
        >
          <Stack spacing="8px">
            <Typography fontSize={16} fontWeight={500}>
              Subscription
            </Typography>
            <Stack direction={"row"} spacing="8px">
              <Chip
                size="small"
                variant="identifier"
                sx={{ textTransform: "capitalize" }}
                label={props.subscription.kind}
              />
              {props.subscription.schedule && (
                <Chip
                  sx={{ textTransform: "capitalize" }}
                  size="small"
                  variant="identifier"
                  label={props.subscription.schedule}
                />
              )}
            </Stack>
          </Stack>
        </Stack>

        <Divider />
        <Stack
          sx={{ px: "24px" }}
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          spacing="8px"
        >
          <Button
            disabled={busy}
            onClick={buyNow}
            color="primary"
            variant="contained"
          >
            Buy Now
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}
