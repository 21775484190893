import axios from "axios";
import { GetCurrentToken } from "../authentication/firebase";
import { AppConfig } from "../config";
import {
  AffiliateTransaction,
  BatchPayout,
  PaginatedResponse,
  PayoutRequest,
  User,
} from "@pfm/types";

const BASE_URL = AppConfig.api;

/**
 * Returns the list of referrees. Supports pagination.
 */
export async function RestGetReferrees(
  page: number,
  count: number,
  search?: string
) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(
    BASE_URL +
      `/admin/affiliation/?page=${page}&count=${count}${
        search ? `&search=${search}` : ""
      }`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as PaginatedResponse<User>;
}

/**
 * Returns the transactions of an affiliate user. Supports pagination and search.
 */
export async function RestListAffiliationTransactions(
  page: number,
  count: number,
  search?: string
) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(
    BASE_URL +
      `/admin/affiliation/transactions?page=${page}&count=${count}${
        search ? `&search=${search}` : ""
      }`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as PaginatedResponse<AffiliateTransaction>;
}

/**
 * Returns the current in process payouts.
 */
export async function RestListAffiliationPayouts(
  page: number,
  count: number,
  search?: string
) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(
    BASE_URL +
      `/admin/affiliation/payouts?page=${page}&count=${count}${
        search ? `&search=${search}` : ""
      }`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as PaginatedResponse<PayoutRequest>;
}

/**
 * Lists the referrals of a specified user.
 */
export async function RestListReferralsByUser(uid: string) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(
    BASE_URL + `/admin/affiliation/${uid}/referrals`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as User[];
}

/**
 * Returns overview or summary data for affiliation section
 * @returns
 */
export async function RestGetAffiliationOverview() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + `/admin/affiliation/overview`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as {
    balance: number;
    withdrawn: number;
    requested: number;
  };
}

/**
 * Confirm payout for a specified user.
 * @param payout
 * @returns
 */
export async function RestConfirmPayout(uid: string) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.post(
    BASE_URL + `/admin/affiliation/${uid}/pay`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as string;
}

/**
 * Updates the affiliate type of the specified user.
 * @param uid
 * @param type
 * @returns
 */
export async function RestUpdateAffilateType(
  uid: string,
  type: "firm" | "user"
) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.post(
    BASE_URL + `/admin/affiliation/${uid}/update`,
    { type: type },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as string;
}

/**
 * Returns the details for a new mass payout operation.
 * @returns
 */
export async function RestGetPayoutDetails() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + `/admin/affiliation/mass-payout`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as {
    totals: {
      stripe: number;
      crypto: number;
      total: number;
    };
    crypto: { [key: string]: number };
    users: {
      name: string;
      email: string;
      image?: string;
      method: string;
      amount: number;
    }[];
  };
}

/**
 * Starts a mass payout operation
 * @returns
 */
export async function RestSendMassPayout() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.post(
    BASE_URL + `/admin/affiliation/mass-payout`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as string;
}

export async function RestGetBatchPayouts() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + `/admin/affiliation/batch-payouts`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as BatchPayout[];
}

export async function RestSetImpersonationId(uid: string) {
  const token = await GetCurrentToken();
  const resp = await axios.post(
    BASE_URL + `/admin/users/impersonate`,
    { uid: uid },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  // Reload user's token
  await GetCurrentToken();
  return resp.data as string;
}
