import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_ALERT_WARNING, IC_CARD } from "../assets";
import IconContainer from "../components/IconContainer";
import { enqueueSnackbar } from "notistack";
import { User } from "@pfm/types";
import { parseStripeAmount } from "../core/helper";
import { RestConfirmPayout } from "../api/admin";

export default function ConfirmPayoutDialog(props: DialogProps<User>) {
  const [busy, setBusy] = useState(false);
  const amnt = parseStripeAmount(props.data?.affiliation?.balance || 0);

  const hasValidPayoutProfile = useMemo(() => {
    if (props.data?.affiliation?.preferred === "crypto") {
      return (
        props.data.affiliation.web3?.walletAddress &&
        props.data.affiliation.web3?.walletCoin &&
        props.data.affiliation.web3?.walletNetwork
      );
    } else if (props.data?.affiliation?.preferred === "stripe") {
      return Boolean(props.data.affiliation.stripe);
    } else return false;
  }, []);

  async function pay() {
    try {
      setBusy(true);
      // Make pay request
      await RestConfirmPayout(props.data?.uid!);
      enqueueSnackbar(
        "The payout is being processed. Please check the payouts section on the connected platform to view details.",
        { variant: "success" }
      );
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar("Error processing your request. ", { variant: "error" });
      console.error("Error processing withdrawal request.", err);
    }
    setBusy(false);
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_CARD />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Confirm Payout for {props.data?.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Confirm the payout details
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Typography>
              <span style={{ color: "#FFFA" }}>User: </span> {props.data?.name}
            </Typography>
            <Typography>
              <span style={{ color: "#FFFA" }}>Email: </span>{" "}
              {props.data?.email}
            </Typography>
            <Typography>
              <span style={{ color: "#FFFA" }}>Payout Method: </span>{" "}
              {props.data?.affiliation?.preferred === "stripe"
                ? "Stripe"
                : "Crypto"}
            </Typography>
            {props.data?.affiliation?.preferred === "crypto" && (
              <>
                <Typography>
                  <span style={{ color: "#FFFA" }}>Network: </span>{" "}
                  {props.data.affiliation?.web3?.walletNetwork}
                </Typography>
                <Typography>
                  <span style={{ color: "#FFFA" }}>Coin: </span>{" "}
                  {props.data.affiliation?.web3?.walletCoin}
                </Typography>
                <Typography>
                  <span style={{ color: "#FFFA" }}>Address: </span>{" "}
                  {props.data.affiliation?.web3?.walletAddress}
                </Typography>
              </>
            )}
            <Typography>
              <span style={{ color: "#FFFA" }}>Amount: </span>${amnt.dollars}.
              {amnt.cents}
            </Typography>
          </Stack>

          {!hasValidPayoutProfile && (
            <Alert
              color="error"
              icon={<IC_ALERT_WARNING stroke="red" fill="transparent" />}
            >
              <AlertTitle>Invalid Payout Profile</AlertTitle>
              <Typography>
                The user does not have a valid payout profile. Please advise
                them to set up their payout profile to make a transfer.
              </Typography>
            </Alert>
          )}

          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              disabled={busy || !hasValidPayoutProfile}
              onClick={pay}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
