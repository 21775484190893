import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Grid,
  Paper,
  Stack,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import {
  IC_BALANCE,
  IC_CUSTOMERS,
  IC_REVENUES,
  IC_TREND_UP,
} from "../../assets";
import InsightCard from "../../components/InsightCard";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { StyledToggleButtonGroup } from "../../components/ToggleButtonGroup";
import { enqueueSnackbar } from "notistack";
import { RestGetAdminDashboard } from "../../core/rest";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import { PFMActivity } from "@pfm/types";
import moment from "moment";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { AppConfig } from "../../config";
import { PFMServerMessage } from "../../core/sockTypes";
import { FbAuth } from "../../authentication/firebase";

export default function SectionAdminDashboard() {
  const [profile] = useRecoilState(aProfile);
  const [section, setSection] = useRecoilState(aCurrentSection);
  const [sectionDescription, setSectionDescription] = useRecoilState(
    aCurrentSectionDescription
  );
  const [range, setRange] = useState<"week" | "month" | "year">("week");
  const [balance, setBalance] = useState("N/A");
  const [sales, setSales] = useState("N/A");
  const [customers, setCustomers] = useState("N/A");
  const [activities, setActivities] = useState<PFMActivity[]>([]);

  const [busy, setBusy] = useState(false);

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    AppConfig.socks,
    { share: true }
  );
  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  async function load() {
    try {
      setBusy(true);
      const time =
        range === "week"
          ? moment().subtract(7, "days").unix()
          : range === "month"
          ? moment().subtract(30, "days").unix()
          : moment().subtract(1, "year").unix();

      const _data = await RestGetAdminDashboard(time);
      const salesCurrencySym = getCurrencySymbol(_data.currency);
      const salesAmnt = parseStripeAmount(_data.sales);
      const balanceCurrencySym = getCurrencySymbol(_data.currency);
      const balnceAmnt = parseStripeAmount(_data.balance);

      setBalance(
        `${balanceCurrencySym}${balnceAmnt.dollars}.${balnceAmnt.cents}`
      );
      setSales(`${salesCurrencySym}${salesAmnt.dollars}.${salesAmnt.cents}`);
      setCustomers(_data.customers.toString());
      setActivities(_data.activities);
      sendJsonMessage({
        type: "ping",
        token: await FbAuth.currentUser?.getIdToken(),
      });
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  function getTypeColor(type: string) {
    switch (type) {
      case "intrusion":
        return "error";
      case "information":
        return "info";
      case "signup":
        return "success";
      case "cancellation":
        return "error";
      case "restriction":
        return "warning";
      default:
        return "info";
    }
  }

  useEffect(() => {
    if (lastJsonMessage) {
      const _msg = lastJsonMessage as PFMServerMessage;
      if (_msg.type === "activity") {
        setActivities([_msg.data, ...activities.slice(0, 49)]);
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    load();
  }, [range]);

  useEffect(() => {
    setSection(`Dashboard`);
    setSectionDescription(`Welcome back, ${profile?.name}!`);
  }, []);

  function RenderActivity(act: PFMActivity) {
    return (
      <TableRow>
        <TableCell>
          <Chip
            label={act.type}
            color={getTypeColor(act.type) as any}
            variant="outlined"
            sx={{ textTransform: "capitalize" }}
          />
        </TableCell>
        <TableCell sx={{ width: "auto", minWidth: "200px" }}>
          {moment.unix(act.created).format("MMM DD, YYYY HH:mm:ss")}
        </TableCell>
        <TableCell align="justify" sx={{ overflowWrap: "anywhere" }}>
          {act.message}
        </TableCell>
        <TableCell>
          {act.user && (
            <Stack direction={"row"} alignItems={"center"} spacing="10px">
              <Avatar src={act.user?.image} />
              <Stack>
                <Stack>
                  <Typography fontSize={14} fontWeight={500}>
                    {act.user?.name}
                  </Typography>
                  <Typography fontSize={14} fontWeight={400} color="#94969C">
                    {act.user?.email}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ p: "32px" }}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
      >
        <Stack>
          <Typography fontSize={24} fontWeight={400}>
            Insights
          </Typography>
          <Typography fontSize={14} fontWeight={400} color="#94969C">
            Current balance, sales, and new customers.
          </Typography>
        </Stack>
        <StyledToggleButtonGroup
          exclusive
          value={range}
          onChange={(c, e) => setRange(e)}
        >
          <ToggleButton value={"year"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
              spacing="16px"
              sx={{ px: "16px" }}
            >
              <Badge
                variant="dot"
                color={range === "year" ? "success" : undefined}
              ></Badge>
              <Typography>12 months</Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton value={"month"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
              spacing="16px"
              sx={{ px: "16px" }}
            >
              <Badge
                variant="dot"
                color={range === "month" ? "success" : undefined}
              ></Badge>
              <Typography>30 days</Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton value={"week"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
              spacing="16px"
              sx={{ px: "16px" }}
            >
              <Badge
                variant="dot"
                color={range === "week" ? "success" : undefined}
              ></Badge>
              <Typography>7 days</Typography>
            </Stack>
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Stack>
      <Grid
        spacing={"8px"}
        container
        sx={{ pt: "32px" }}
        columns={4}
        rowSpacing={"32px"}
      >
        <Grid item>
          <InsightCard
            accent="#2E90FA"
            icon={<IC_BALANCE />}
            title="Balance"
            subheading="Current Balance"
            value={balance}
          />
        </Grid>
        <Grid item>
          <InsightCard
            accent="#17B26A"
            icon={<IC_REVENUES />}
            title="Sales"
            subheading={"Total of the invoices"}
            value={sales}
          />
        </Grid>
        <Grid item>
          <InsightCard
            accent="#D444F1"
            icon={<IC_CUSTOMERS fill="white" />}
            title="New Customers"
            subheading={"New customers in selected time"}
            value={customers}
          />
        </Grid>
        <Grid item xs={4}>
          <PFMTable
            key={activities as any}
            title="Activities"
            titleBadge={activities.length.toString()}
            description="Recent activities on the platform, notifications, and alerts."
            actionButtons={
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => load()}
                  disabled={busy}
                >
                  Refresh
                </Button>
              </>
            }
            tableHead={
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Date</TableCell>
                <TableCell align="justify" sx={{ flex: 1 }}>
                  Description
                </TableCell>
                <TableCell>User</TableCell>
              </TableRow>
            }
            tableBody={<>{activities.map((a) => RenderActivity(a))}</>}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
