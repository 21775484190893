import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { enqueueSnackbar } from "notistack";
import { RestGetProductMedia } from "../core/rest";
import { StripeProduct } from "@pfm/types";
import ButtonSwitch from "../components/ButtonedSwitch";
import ProductSlideshow from "../components/Slideshow";
import { IC_SHOPPING_CART_VIDEO, IC_USER_CHECK } from "../assets";

export default function ProductInfoDialog(props: DialogProps<StripeProduct>) {
  const [busy, setBusy] = useState(false);
  const [images, setImages] = useState<string[]>([]);
  const [videos, setVideos] = useState<string[]>([]);
  const [photos, setPhotos] = useState(false);
  const product = props.data;

  const desktop = useMediaQuery("(min-width: 700px)");

  async function loadMedia() {
    try {
      if (product?.deleted) {
        // Product is deleted. We load media from provided info only.
        if (product.metadata?.images) {
          setImages(JSON.parse(product.metadata.images));
        }
        if (product.metadata?.videos) {
          setImages(JSON.parse(product.metadata.videos));
        }
      } else {
        const media = await RestGetProductMedia(product!.id);
        setImages(media.images);
        setVideos(media.videos);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading product media. Please try again. ", {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    loadMedia();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
      fullScreen={!desktop}
    >
      <DialogContent>
        <Stack spacing="16px" sx={{ py: "24px", mx: "24px" }}>
          <Stack
            justifyContent={"space-between"}
            direction={"row"}
            alignItems={"center"}
          >
            <Stack
              sx={{
                width: "56px",
                height: "56px",
                border: "1px solid #333741",
                borderRadius: "12px",
              }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {product?.images?.[0] && (
                <img
                  src={product?.images?.[0]}
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "contain",
                    objectPosition: "center",
                  }}
                />
              )}
              {!product?.images?.[0] && <IC_SHOPPING_CART_VIDEO />}
            </Stack>
            {/* <Button variant="contained" color="secondary">
              Learn More
            </Button> */}
          </Stack>
          <Stack spacing={"8px"}>
            <Typography fontSize={18} fontWeight={600}>
              {product?.name}
            </Typography>
            <Typography color="#94969C" fontSize={14} fontWeight={400}>
              {product?.description}
            </Typography>
          </Stack>

          <Stack justifyContent={"center"} alignItems={"center"}>
            <ButtonSwitch
              label="Videos"
              checkedLabel="Photos"
              checked={photos}
              onChange={(c) => setPhotos(c)}
            />
          </Stack>

          {photos && images.length === 0 && (
            <Typography>No images were found.</Typography>
          )}

          {photos && images.length > 0 && (
            <ProductSlideshow
              urls={images}
              kind="images"
              sx={{ height: "400px" }}
            />
          )}

          {!photos && videos.length === 0 && (
            <Typography>No videos were found.</Typography>
          )}

          {!photos && videos.length > 0 && (
            <ProductSlideshow
              urls={videos}
              kind="videos"
              sx={{ height: "400px" }}
              disableAnimation
            />
          )}

          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
