import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Button,
  Chip,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import PFMInput from "../../components/PFMInput";
import { IC_DISCORD, IC_REFRESH, IC_SEARCH, LOGO_PFM } from "../../assets";
import { useModal } from "mui-modal-provider";
import { RestListCustomers } from "../../core/rest";
import { enqueueSnackbar } from "notistack";
import { StripeProduct, Subscription, User } from "@pfm/types";
import moment from "moment";
import CustomerDialog from "../../dialogs/Customer";
import ViewProductConfigDialog from "../../dialogs/ViewProductConfig";

export default function SectionAdminCustomers() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const { showModal } = useModal();
  const [busy, setBusy] = useState(true);
  const [search, setSearch] = useState<string>("");

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [data, setData] = useState<User[]>([]);

  async function loadCustomers(page: number) {
    try {
      //Load data
      setBusy(true);
      const _data = await RestListCustomers(
        page,
        10,
        Boolean(search) ? search : undefined
      );
      if (_data) {
        setTotalPages(_data.pageCount);
        setPageNumber(_data.currentPage);
        setTotalUsers(_data.total);
        setData(_data.data);
        console.log(_data);
      } else {
        enqueueSnackbar("No data returned.", { variant: "error" });
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
    }
    setBusy(false);
  }

  function getCustomerProducts(subs: Subscription[]) {
    const prods: {
      product: StripeProduct;
      subscription: Subscription;
      subscriptionId: string;
    }[] = [];
    for (let sub of subs) {
      for (let p of sub.products || []) {
        prods.push({
          product: p,
          subscription: sub,
          subscriptionId: sub.data?.id!,
        });
      }
    }
    return prods;
  }

  function showCustomerDialog(user: User) {
    const modal = showModal(CustomerDialog, {
      closeHandler(result) {
        modal.destroy();
      },
      data: user,
    });
  }
  function showViewConfigDialog(
    c: User,
    prod: StripeProduct,
    subscriptionId: string
  ) {
    const modal = showModal(ViewProductConfigDialog, {
      closeHandler(result) {
        modal.destroy();
      },
      data: {
        customer: c,
        product: prod,
        subscriptionId: subscriptionId,
      },
    });
  }

  useEffect(() => {
    setSection(`Customers`);
    setSectionDescription(
      `View and manage your customers across the platform.`
    );
    loadCustomers(0);
  }, []);

  function RenderCustomer(c: User) {
    return (
      <TableRow>
        <TableCell align="left">
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={c.image} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {c.name || c.customer_info.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {c.email || c.customer_info.email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            {moment
              .unix(c.registeredTimestamp || c.customer_info.created)
              .format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          {c.subscriptions && c.subscriptions.length > 0 && (
            <AvatarGroup
              sx={{
                justifyContent: "start",
                "& .MuiAvatar-root": {
                  width: "32px",
                  height: "32px",
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#94969C",
                  background: "#1F242F",
                },
              }}
            >
              {getCustomerProducts(c.subscriptions || []).map((s) => (
                <Tooltip title={s.product.name} arrow>
                  <Avatar
                    src={s.product.images?.[0] || LOGO_PFM}
                    onClick={() => {
                      showViewConfigDialog(c, s.product, s.subscriptionId);
                    }}
                  />
                </Tooltip>
              ))}
            </AvatarGroup>
          )}
        </TableCell>
        <TableCell>
          {c.discord && (
            <Stack direction={"row"} alignItems={"center"} spacing="8px">
              <Avatar
                src={`https://cdn.discordapp.com/avatars/${c.discord.id}/${c.discord.avatar}.png`}
              />
              <Typography>{c.discord.global_name}</Typography>
              <Typography>(@{c.discord.username})</Typography>
            </Stack>
          )}
        </TableCell>
        <TableCell align="right">
          <Button onClick={() => showCustomerDialog(c)}>View</Button>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ p: "40px" }} flex={1}>
      <PFMTable
        sx={{ height: "100%" }}
        pageNumber={pageNumber}
        paginated
        totalPages={totalPages}
        nextPage={(pn) => loadCustomers(pn)}
        prevPage={(pn) => loadCustomers(pn)}
        titleBadge={totalUsers + " Customers"}
        title="Customers"
        description="View and manage customers."
        busy={busy}
        actionButtons={
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <PFMInput
              placeholder="Type and press enter to search..."
              startIcon={IC_SEARCH}
              text={search}
              onReturn={() => {
                setPageNumber(0);
                loadCustomers(0);
              }}
              onUpdate={(t) => setSearch(t)}
            />
            <Button
              sx={{ height: "44px", px: "28px" }}
              onClick={() => loadCustomers(pageNumber)}
              variant="contained"
              color="secondary"
              startIcon={<IC_REFRESH />}
            >
              Refresh
            </Button>
          </Stack>
        }
        tableHead={
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>Registered</TableCell>
            <TableCell>Products</TableCell>
            <TableCell>Discord</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderCustomer(d))}</>}
      />
    </Stack>
  );
}
