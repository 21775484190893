import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { IC_CART_PLUS, IC_PEN, IC_REFRESH, LOGO_PFM } from "../../assets";
import { useModal } from "mui-modal-provider";
import AddProductDialog from "../../dialogs/AddProduct";
import { RestListProducts } from "../../core/rest";
import { enqueueSnackbar } from "notistack";
import EditProductDialog from "../../dialogs/EditProduct";

export default function SectionAdminProducts() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const { showModal } = useModal();
  const [busy, setBusy] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [data, setData] = useState<any[]>([]);

  async function loadProducts() {
    try {
      //Load data
      setBusy(true);
      const _data = await RestListProducts();
      setData(_data || []);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
    }
    setBusy(false);
  }

  function showAddProductDialog() {
    const modal = showModal(AddProductDialog, {
      closeHandler(result) {
        modal.destroy();
        loadProducts();
      },
    });
  }

  function showEditProductDialog(product: any) {
    const modal = showModal(EditProductDialog, {
      closeHandler(result) {
        modal.destroy();
        loadProducts();
      },
      data: product,
    });
  }

  useEffect(() => {
    setSection(`Products`);
    setSectionDescription(`View and manage your products.`);
    loadProducts();
  }, []);

  function RenderProduct(product: any) {
    return (
      <TableRow>
        <TableCell align="left">
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={product.images[0] || LOGO_PFM} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {product.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {product.id}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">{product.description}</Typography>
        </TableCell>
        <TableCell color="#F5F5F6">
          {product.subscriptions.map((s: any) => (
            <Chip label={s.name} variant="identifier" sx={{ mx: "2px" }} />
          ))}
        </TableCell>
        <TableCell align="right">
          <Button onClick={() => showEditProductDialog(product)}>Edit</Button>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ p: "40px" }} flex={1}>
      <PFMTable
        sx={{ height: "100%" }}
        title="Products"
        description="View and manage products."
        titleBadge={data.length + " Products"}
        actionButtons={
          <>
            <Button
              onClick={loadProducts}
              disabled={busy}
              variant="contained"
              color="secondary"
              startIcon={<IC_REFRESH />}
            >
              Refresh
            </Button>
            <Button
              onClick={showAddProductDialog}
              disabled={busy}
              variant="contained"
              color="primary"
              startIcon={<IC_CART_PLUS />}
            >
              Add product
            </Button>
          </>
        }
        tableHead={
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Subscriptions</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderProduct(d))}</>}
      />
    </Stack>
  );
}
