import _ic_email from "./icons/ic_email.svg";
import _ic_password from "./icons/ic_password.svg";
import _ic_name from "./icons/ic_user.svg";
import _ic_show_password from "./icons/ic_show_password.svg";
import _ic_hide_password from "./icons/ic_hide_password.svg";
import _ic_dashboard from "./icons/ic_dashboard.svg";
import _ic_checkbox from "./icons/ic_checkbox.svg";
import _hand_drawing from "./art/hand_drawing.png";
import _elliptical_glow from "./art/elliptical_glow.png";
import _pfm from "./brand/Logo/avatar.png";
import _prop_firm_masters from "./brand/Logo/pfm.png";
import _pfm_sidebar from "./brand/Logo/sidebar.png";
import _ic_products from "./icons/ic_products.svg";
import _ic_customers from "./icons/ic_customers.svg";
import _ic_users from "./icons/ic_users.svg";
import _ic_subscriptions from "./icons/ic_subscriptions.svg";
import _ic_payments from "./icons/ic_payments.svg";
import _ic_settings from "./icons/ic_settings.svg";
import _ic_support from "./icons/ic_support.svg";
import _ic_signout from "./icons/ic_signout.svg";
import _ic_balance from "./icons/ic_balance.svg";
import _ic_revenues from "./icons/ic_revenues.svg";
import _ic_trend_up from "./icons/ic_trend_up.svg";
import _ic_refresh from "./icons/ic_refresh.svg";
import _ic_pen from "./icons/ic_pen.svg";
import _ic_card from "./icons/ic_card.svg";
import _ic_user_rounded from "./icons/ic_user_rounded.svg";
import _ic_search from "./icons/ic_search.svg";
import _ic_add_user from "./icons/ic_add_user.svg";
import _ic_back from "./icons/ic_back.svg";
import _ic_add_image from "./icons/ic_add_image.svg";
import _ic_close from "./icons/ic_close.svg";
import _ic_add from "./icons/ic_add.svg";
import _ic_help from "./icons/ic_help.svg";
import _ic_alert_warning from "./icons/ic_alert_warning.svg";
import _ic_edit from "./icons/ic_edit.svg";
import _ic_download from "./icons/ic_download.svg";
import _ic_upload from "./icons/ic_upload.svg";
import _ic_visa from "./icons/ic_visa.svg";
import _ic_master_card from "./icons/ic_mastercard.svg";
import _ic_stripe from "./icons/ic_stripe.svg";
import _ic_paypal from "./icons/ic_paypal.svg";
import _ic_discover from "./icons/ic_discover.svg";
import _ic_applepay from "./icons/ic_applepay.svg";
import _ic_amex from "./icons/ic_amex.svg";
import _ic_discord from "./icons/ic_discord.svg";
import _ic_user_minus from "./icons/ic_user_minus.svg";
import _ic_user_block from "./icons/ic_user_block.svg";
import _ic_user_check from "./icons/ic_user_check.svg";
import _ic_shopping_cart_video from "./icons/ic_shopping_cart_video.svg";
import _ic_trash from "./icons/ic_trash.svg";
import _ic_bag_check from "./icons/ic_bag_check.svg";
import _ic_bag_outlined from "./icons/ic_bag_outlined.svg";
import _ic_cart_plus from "./icons/ic_cart_plus.svg";
import _logo_vertical from "./brand/Logo/pfm.png";

export const IC_CART_PLUS = _ic_cart_plus as any;
export const IC_BAG_CHECKED = _ic_bag_check as any;
export const IC_BAG_OUTLINED = _ic_bag_outlined as any;
export const IC_CHECKBOX = _ic_checkbox as any;
export const IC_EMAIL = _ic_email as any;
export const IC_PASSWORD = _ic_password as any;
export const IC_SHOW_PASSWORD = _ic_show_password as any;
export const IC_NAME = _ic_name as any;
export const HAND_DRAWING = _hand_drawing as any;
export const LOGO_VERTICAL = _logo_vertical as any;
export const ELLIPTICAL_GLOW = _elliptical_glow as any;
export const IC_HIDE_PASSWORD = _ic_hide_password as any;
export const LOGO_PFM = _pfm;
export const LOGO_PROP_FIRM_MASTERS = _prop_firm_masters;
export const LOGO_SIDEBAR = _pfm_sidebar;

export const IC_DASHBOARD = _ic_dashboard as any;
export const IC_PRODUCTS = _ic_products as any;
export const IC_CUSTOMERS = _ic_customers as any;
export const IC_USERS = _ic_users as any;
export const IC_SUBSCRIPTIONS = _ic_subscriptions as any;
export const IC_PAYMENTS = _ic_payments as any;
export const IC_SETTINGS = _ic_settings as any;
export const IC_SUPPORT = _ic_support as any;
export const IC_SIGNOUT = _ic_signout as any;
export const IC_BALANCE = _ic_balance as any;
export const IC_REVENUES = _ic_revenues as any;
export const IC_TREND_UP = _ic_trend_up as any;
export const IC_REFRESH = _ic_refresh as any;
export const IC_PEN = _ic_pen as any;
export const IC_CARD = _ic_card as any;
export const IC_USER_ROUNDED_ = _ic_user_rounded as any;
export const IC_SEARCH = _ic_search as any;
export const IC_ADD_USER = _ic_add_user as any;
export const IC_BACK = _ic_back as any;
export const IC_ADD_IMAGE = _ic_add_image as any;
export const IC_CLOSE = _ic_close as any;
export const IC_HELP = _ic_help as any;
export const IC_ADD = _ic_add;
export const IC_ALERT_WARNING = _ic_alert_warning as any;
export const IC_EDIT = _ic_edit as any;
export const IC_DOWNLOAD = _ic_download as any;
export const IC_UPLOAD = _ic_upload as any;
export const IC_MASTERCARD = _ic_master_card;
export const IC_VISA = _ic_visa;
export const IC_APPLE_PAY = _ic_applepay as any;
export const IC_DISCOVER = _ic_discover as any;
export const IC_PAYPAL = _ic_paypal as any;
export const IC_STRIPE = _ic_stripe as any;
export const IC_USER_MINUS = _ic_user_minus as any;
export const IC_USER_BLOCK = _ic_user_block as any;
export const IC_USER_CHECK = _ic_user_check as any;
export const IC_SHOPPING_CART_VIDEO = _ic_shopping_cart_video as any;
export const IC_TRASH = _ic_trash as any;

export const IC_AMEX = _ic_amex as any;
export const IC_DISCORD = _ic_discord as any;
