import React, { useEffect, useMemo, useState } from "react";
import { DialogProps } from "./props";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  MenuItem,
  RadioGroup,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_ADD, IC_PEN, IC_CLOSE, IC_HELP, IC_CARD } from "../assets";
import IconContainer from "../components/IconContainer";
import PFMInput from "../components/PFMInput";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../components/PFMAutoComplete";
import PFMRadio from "../components/PFMRadio";
import { enqueueSnackbar } from "notistack";
import { RestCreateSubscription, RestListProducts } from "../core/rest";
import { getCurrencySymbol } from "../core/helper";

export default function CreateSubscriptionDialog(props: DialogProps<any>) {
  const [name, setName] = useState("");
  const [products, setProducts] = useState<AutocompleteOption[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<
    AutocompleteOption[]
  >([]);
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("usd");
  const [kind, setKind] = useState<"onetime" | "recurring">("onetime");
  const [schedule, setSchedule] = useState("");
  const [metadata, setMetadata] = useState<{ key: string; value: string }[]>(
    []
  );

  const [busy, setBusy] = useState(false);

  async function createSubscription() {
    try {
      // Validate
      if (!name) {
        enqueueSnackbar("Please enter a subscription name.", {
          variant: "error",
        });
        return;
      }
      if (!selectedProducts || selectedProducts.length === 0) {
        enqueueSnackbar("Please add at least 1 product.", {
          variant: "error",
        });
        return;
      }
      if (!price) {
        enqueueSnackbar("Please enter the price for this subscription.", {
          variant: "error",
        });
        return;
      }
      for (let md of metadata) {
        if (!md.key || !md.value) {
          enqueueSnackbar(
            "Please remove empty metadata entries, or enter text.",
            { variant: "error" }
          );
          return;
        }
      }
      setBusy(true);
      const _prods = [];
      for (let p of selectedProducts) {
        _prods.push({
          id: p.id,
          name: p.label,
          images: [p.icon],
        });
      }
      // Add the subscription
      await RestCreateSubscription({
        name: name,
        kind: kind,
        metadata: metadata,
        price: {
          amount: Math.floor(parseFloat(price) * 100),
          currency: currency,
        },
        products: _prods as any,
        schedule:
          kind === "recurring" ? (schedule.toLowerCase() as any) : undefined,
      });
      enqueueSnackbar("Subscription has been added. ", { variant: "success" });
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar(
        "Error creating a new subscription. Error: " + err.message,
        {
          variant: "error",
        }
      );
    }
    setBusy(false);
  }

  async function loadProducts() {
    try {
      const _prods = await RestListProducts();
      const _allProds = [];
      for (let p of _prods) {
        _allProds.push({
          label: p.name,
          id: p.id,
          icon: p.images[0] || undefined,
        });
      }
      setProducts(_allProds);
    } catch (err: any) {
      enqueueSnackbar("Error loading products. Please try again.", {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    loadProducts();
  }, []);

  function RenderMetadata(index: number, key: string, value: string) {
    return (
      <Stack direction={"row"} spacing="12px" alignItems={"center"}>
        <PFMInput
          placeholder="Key"
          fullWidth
          text={key}
          onUpdate={(t) => {
            // Update key
            metadata[index].key = t;
            setMetadata([...metadata]);
          }}
        />
        <PFMInput
          placeholder="Value"
          fullWidth
          text={value}
          onUpdate={(t) => {
            // Update key
            metadata[index].value = t;
            setMetadata([...metadata]);
          }}
        />
        <IconButton
          size="small"
          sx={{ width: "40px", height: "40px" }}
          onClick={() => {
            // Delete
            setMetadata([
              ...metadata.slice(0, index),
              ...metadata.slice(index + 1),
            ]);
          }}
        >
          <IC_CLOSE stroke="#94969C" />
        </IconButton>
      </Stack>
    );
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px", width: "580px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_CARD />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Create Subscription
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Add a new subscription for users.
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing={"24px"} sx={{ px: "24px" }}>
            <PFMInput
              label="Subscription Name"
              placeholder="Write a subscription name "
              important
              text={name}
              onUpdate={(t) => setName(t)}
              disabled={busy}
            />
            <PFMAutoComplete
              multiple
              key={products.length}
              options={products}
              label="Assign Products"
              placeholder="Add products.."
              onUpdate={(sel) => setSelectedProducts(sel as any)}
              value={selectedProducts}
              important
            />

            <PFMInput
              startAdornment={
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{ width: "48px", height: "100%" }}
                >
                  <Typography>{getCurrencySymbol(currency)}</Typography>
                </Stack>
              }
              text={price}
              onUpdate={(p) => setPrice(p)}
              label="Subscription Price"
              placeholder="500"
              important
              endAdornment={
                <Select
                  variant="standard"
                  disableUnderline
                  onChange={(c) => setCurrency(c.target.value)}
                  defaultValue={"usd"}
                  value={currency}
                  sx={{ background: "transparent" }}
                >
                  <MenuItem value={"usd"}>USD</MenuItem>
                  <MenuItem value={"gbp"}>GBP</MenuItem>
                  <MenuItem value={"eur"}>EUR</MenuItem>
                </Select>
              }
            />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                Type of subscription
              </Typography>
              <RadioGroup radioGroup="subscriptionKind">
                <PFMRadio
                  checked={kind === "onetime"}
                  onUpdate={(c) => setKind("onetime")}
                  label="One-time"
                  group="subscriptionKind"
                  labelProps={{ fontSize: 16, fontWeight: 500 }}
                />
                <PFMRadio
                  checked={kind === "recurring"}
                  onUpdate={(c) => setKind("recurring")}
                  label="Recurring"
                  group="subscriptionKind"
                  labelProps={{ fontSize: 16, fontWeight: 500 }}
                />
              </RadioGroup>
              {kind === "recurring" && (
                <PFMAutoComplete
                  options={[
                    { label: "Daily" },
                    { label: "Weekly" },
                    { label: "Monthly" },
                    { label: "Every 3 months" },
                    { label: "Every 6 months" },
                    { label: "Yearly" },
                  ]}
                  value={{ label: schedule }}
                  onUpdate={(v: any) => setSchedule(v.label)}
                  label="Recurring Schedule"
                  placeholder="Select a schedule..."
                  important
                />
              )}
            </Stack>
          </Stack>
          <Stack direction={"row"} spacing={"32px"} sx={{ px: "24px" }}>
            <Stack
              direction={"row"}
              sx={{
                maxWidth: "220px",
                minWidth: "150px",
              }}
              spacing={"8px"}
            >
              <Typography fontSize={14} fontWeight={500}>
                Metadata
              </Typography>
              <Tooltip title="Add extra key-value pairs to the subscription. Can be used for comments and extra reasoning.">
                <IC_HELP />
              </Tooltip>
            </Stack>
            <Stack
              flex={1}
              spacing="12px"
              sx={{ width: "100%" }}
              key={metadata as any}
            >
              {metadata.map((kv, ind) => RenderMetadata(ind, kv.key, kv.value))}
              <Button
                disabled={busy}
                onClick={() =>
                  setMetadata([...metadata, { key: "", value: "" }])
                }
                sx={{ alignSelf: "start" }}
                startIcon={<IC_ADD />}
              >
                Add metadata
              </Button>
            </Stack>
          </Stack>
          <Divider />
          <Stack sx={{ px: "24px" }} direction={"row"} spacing={"12px"}>
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              color="secondary"
              variant="contained"
              fullWidth
            >
              Cancel
            </Button>
            <Button
              disabled={busy}
              onClick={createSubscription}
              color="primary"
              variant="contained"
              fullWidth
            >
              Create Subscription
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
