import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Radio,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_CARD, IC_USER_CHECK } from "../assets";
import IconContainer from "../components/IconContainer";
import { enqueueSnackbar } from "notistack";
import {
  Business,
  CurrencyBitcoin,
  LocalPostOffice,
} from "@mui/icons-material";
import { User } from "@pfm/types";
import { RestGetPayoutDetails, RestSendMassPayout } from "../api/admin";
import { parseStripeAmount } from "../core/helper";
import PFMTable from "../components/PFMTable";

export default function MassPayoutDialog(props: DialogProps<any>) {
  const [busy, setBusy] = useState(false);
  const theme = useTheme();
  const [details, setDetails] = useState<{
    totals: {
      stripe: number;
      crypto: number;
      total: number;
    };
    crypto: { [key: string]: number };
    users: {
      name: string;
      email: string;
      image?: string;
      method: string;
      amount: number;
    }[];
  }>();

  function centsToUsd(cents?: number) {
    const pi = parseStripeAmount(cents || 0);
    return `$${pi.dollars}.${pi.cents}`;
  }

  // Send the payout
  async function start() {
    try {
      setBusy(true);
      await RestSendMassPayout();
      enqueueSnackbar(
        "Payout succeeded. Please check status in batch payouts, and on respective platforms.",
        { variant: "success" }
      );
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar("Error sending mass payout. Please try later.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  // Load payout details.
  async function load() {
    try {
      setBusy(true);
      const _details = await RestGetPayoutDetails();
      // Load the details.
      if (_details) {
        setDetails(_details);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading details. Please try later.", {
        variant: "error",
      });
      console.log(err);
      props.closeHandler(false);
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_CARD />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Initiate Mass Payout
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Automatically send payout to all the users.
              </Typography>
            </Stack>
          </Stack>
          <Divider />

          {busy && !details && (
            <Stack alignItems={"center"} spacing="8px">
              <CircularProgress />
              <Typography>Calculating, please wait...</Typography>
            </Stack>
          )}
          {details && (
            <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
              <Typography>
                Total Amount:{" "}
                <span style={{ fontWeight: 600 }}>
                  {centsToUsd(details.totals.total)}
                </span>
              </Typography>
              <Typography>
                Total for Crypto:{" "}
                <span style={{ fontWeight: 600 }}>
                  {centsToUsd(details.totals.crypto)}
                </span>
              </Typography>
              <Typography>
                Total for Stripe:{" "}
                <span style={{ fontWeight: 600 }}>
                  {centsToUsd(details.totals.stripe)}
                </span>
              </Typography>
              {/* Breakdown crypto details  */}
              {details.totals.crypto > 0 && (
                <>
                  <Typography fontWeight={500}>
                    Crypto-currencies Breakdown
                  </Typography>
                  <Typography fontWeight={400} fontSize={12}>
                    Total amounts of different crypto-currencies selected by
                    users
                  </Typography>
                  <PFMTable
                    tableBody={Object.entries(details.crypto).map((o) => (
                      <TableRow>
                        <TableCell>
                          <Typography>{o[0].toUpperCase()}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography fontWeight={500}>
                            {centsToUsd(o[1])}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                    tableHead={
                      <TableRow>
                        <TableCell>Coin</TableCell>
                        <TableCell align="right">Amount</TableCell>
                      </TableRow>
                    }
                  />
                </>
              )}
              {/* List all users  */}
              <Typography fontWeight={500}>Users Breakdown</Typography>
              <Typography fontWeight={400} fontSize={12}>
                List of all the users to be paid
              </Typography>
              <PFMTable
                tableBody={details.users.map((u) => (
                  <TableRow>
                    <TableCell>
                      {" "}
                      <Stack
                        direction={"row"}
                        spacing="12px"
                        alignItems={"center"}
                      >
                        <Avatar src={u.image || ""} sx={{ color: "white" }} />
                        <Stack>
                          <Typography fontSize={14} fontWeight={500}>
                            {u.name}
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={400}
                            color="#94969C"
                          >
                            {u.email}
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography>{u.method}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography fontWeight={500}>
                        {centsToUsd(u.amount)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
                tableHead={
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Method</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                }
              />
            </Stack>
          )}

          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
            <Button
              disabled={busy}
              size="large"
              fullWidth
              onClick={start}
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
