import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import { IC_HELP } from "../../assets";
import PFMAutoComplete from "../../components/PFMAutoComplete";
import { enqueueSnackbar } from "notistack";
import {
  RestGetConfigs,
  RestGetJeffPrompt,
  RestSetConfig,
  RestToggleJeff,
  RestUpdateJeff,
} from "../../core/rest";
import PFMInput from "../../components/PFMInput";
import { FbAuth, FbStorage } from "../../authentication/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { PFMSwitch } from "../../components/PFMSwitch";

export default function SectionSettingsAdmin() {
  const [profile] = useRecoilState(aProfile);
  const [currency, setCurrency] = useState<any>();
  const [instructions, setInstructions] = useState("");
  const [files, setFiles] = useState("");
  const [busy, setBusy] = useState(false);
  const [jeffOnline, setJeffOnline] = useState(false);

  const values = [{ label: "USD" }, { label: "EUR" }, { label: "GBP" }];

  async function saveConfig(key: string, value: string) {
    try {
      setBusy(true);
      await RestSetConfig(key.toLowerCase(), value);
      enqueueSnackbar("Configuration updated.");
      return true;
    } catch (err: any) {
      enqueueSnackbar("Error saving data. Please try again.", {
        variant: "error",
      });
    }
    setBusy(false);
    return false;
  }

  async function saveJeff() {
    try {
      if (!instructions) {
        enqueueSnackbar("Please enter the instructions. ", {
          variant: "error",
        });
        return;
      }
      setBusy(true);
      // Make a post request.
      await RestUpdateJeff(instructions, files);
      enqueueSnackbar("Updated successfully.");
    } catch (err: any) {
      console.log("Error saving instructions. ");
      console.log(err);
      enqueueSnackbar("Error saving data. Please check logs for details.", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function load() {
    try {
      const cfgs = await RestGetConfigs();
      const currencyCfg = cfgs.find((c) => c.key === "currency");
      if (currencyCfg) setCurrency({ label: currencyCfg.value.toUpperCase() });

      const prmpt = await RestGetJeffPrompt();
      if (prmpt) {
        setInstructions(prmpt.prompt);
        setFiles(prmpt.files || "");
        setJeffOnline(prmpt.status === "ACTIVE");
        console.log(prmpt);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading configuration. Please try again.", {
        variant: "error",
      });
    }
  }

  async function toggleJeff() {
    try {
      setBusy(true);
      // Make a post request.
      await RestToggleJeff(jeffOnline ? false : true);
      await load();
      enqueueSnackbar("Jeff updated successfully.");
    } catch (err: any) {
      console.log("Error updating jeff. ");
      console.log(err);
      enqueueSnackbar("Error updating jeff. Please check logs for details.", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function updateCurrency(value: any) {
    if (await saveConfig("currency", value.label)) setCurrency(value);
  }

  async function uploadFile(attachment: File) {
    try {
      setBusy(true);
      const r = ref(
        FbStorage,
        "/uploads/" + FbAuth.currentUser?.uid! + "/" + attachment.name
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          uid: FbAuth.currentUser!.uid,
        },
      });
      task.on("state_changed", (snap) => {
        //setUploadState("uploading");
        //setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          //setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          // append url to the files
          setFiles(url + "\n" + files);
          //setUploadState("uploaded");
          //setUploadInfo(url);
          // Here we update profile
          //await updateProfile(FbAuth.currentUser!, {
          //  photoURL: url,
          //});
          enqueueSnackbar("File uploaded successfully.", {
            variant: "success",
          });
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
    }
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Stack sx={{ p: "40px" }} alignItems={"start"} spacing="8px">
      <Stack direction={"row"} alignItems={"center"} spacing="8px">
        <Typography>Invoice Currency</Typography>
        <Tooltip
          arrow
          placement="right"
          title="The currency to use for customer invoices. Enforces customers to pay in a specific currency. Defaults to USD."
        >
          <IC_HELP />
        </Tooltip>
      </Stack>
      <Stack direction={"row"} sx={{ width: "300px" }}>
        <PFMAutoComplete
          important
          options={values}
          key={currency}
          onUpdate={(o: any) => updateCurrency(o)}
          value={currency}
        />
      </Stack>
      <Divider sx={{ width: "100%" }} />
      <Stack direction={"row"} alignItems={"center"} spacing="8px">
        <Typography>Sire Joffery Baratheon (aka. Jeff)</Typography>
        <Tooltip
          arrow
          placement="right"
          title="Updates the prompt and knowledge file for the livechat agent Jeff."
        >
          <IC_HELP />
        </Tooltip>
      </Stack>
      <Stack direction={"row"} alignItems={"center"} spacing="8px">
        <PFMSwitch checked={jeffOnline} onChange={toggleJeff} />
        <Typography color={jeffOnline ? "primary" : "error"}>
          {jeffOnline ? "Jeff is active." : "Jeff is offline!"}
        </Typography>
      </Stack>
      <Typography>Instructions</Typography>
      <PFMInput
        multiline
        rows={5}
        placeholder="Enter prompt..."
        text={instructions}
        onUpdate={(t) => setInstructions(t)}
      />
      <Typography>Knowledgebase Files</Typography>
      <PFMInput
        text={files}
        onUpdate={(t) => setFiles(t)}
        multiline
        rows={5}
        placeholder="Separate each link on a new line."
        helperText="Include direct links only. Supported file types are PDF and TXT."
      />
      <Stack direction={"row"} spacing="8px">
        <Button disabled={busy} onClick={saveJeff} variant="contained">
          Save
        </Button>
        <input
          id="file-input"
          style={{ position: "absolute", opacity: 0, zIndex: -999999 }}
          type="file"
          onChange={(f) => {
            if (f.target.files && f.target.files?.length > 0) {
              uploadFile(f.target.files[0]);
            }
          }}
        />
        <Button
          onClick={() => document.getElementById("file-input")?.click()}
          disabled={busy}
          color="secondary"
          variant="contained"
        >
          Upload a File
        </Button>
      </Stack>
    </Stack>
  );
}
