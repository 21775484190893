import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Chip,
  Grid,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import { IC_SEARCH, LOGO_PFM } from "../../assets";
import PFMTable from "../../components/PFMTable";
import SimpleInsightCard from "../../components/SimpleInsightCard";
import PFMInput from "../../components/PFMInput";
import { enqueueSnackbar } from "notistack";
import { RestGetUserInvoices } from "../../core/rest";
import moment from "moment";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";

export default function SectionUserPayments() {
  const [profile] = useRecoilState(aProfile);
  const [section, setSection] = useRecoilState(aCurrentSection);
  const [sectionDescription, setSectionDescription] = useRecoilState(
    aCurrentSectionDescription
  );
  const [busy, setBusy] = useState(true);

  const [transactions, setTransactions] = useState<any[]>([]);
  const [nextPayment, setNextPayment] = useState<any>();
  const [filter, setFilter] = useState("");
  const desktop = useMediaQuery("(min-width: 700px)");

  const filteredTransactions = useMemo(() => {
    if (filter) {
      return transactions.filter(
        (t) =>
          t.subscription_info?.name?.includes(filter) || t.id.includes(filter)
      );
    } else return transactions;
  }, [transactions, filter]);

  async function loadData() {
    try {
      //Load data
      setBusy(true);
      const resp = await RestGetUserInvoices();
      setTransactions(resp.transactions);
      setNextPayment(resp.next_invoice);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`Payments`);
    setSectionDescription(`Insights on your entire payment history. `);
    loadData();
  }, []);

  function RenderTx(tx: any) {
    const amnt = parseStripeAmount(tx.amount_paid || tx.amount_due);
    const crnc = getCurrencySymbol(tx.currency);
    return (
      <TableRow>
        <TableCell>
          <Typography color="#94969C">
            <Chip
              label={tx.id}
              variant="identifier"
              onClick={() => navigator.clipboard.writeText(tx.id)}
            />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            <Chip
              label={tx.status}
              sx={{ textTransform: "capitalize" }}
              variant="identifier"
              onClick={() => navigator.clipboard.writeText(tx.status)}
            />
          </Typography>
        </TableCell>
        <TableCell align="left">
          {tx.subscription_info && (
            <Stack direction={"row"} spacing="12px" alignItems={"center"}>
              <AvatarGroup>
                {tx.subscription_info.products.map((p: any) => (
                  <Tooltip title={p.name} arrow>
                    <Avatar src={p.images?.[0] || LOGO_PFM} />
                  </Tooltip>
                ))}
              </AvatarGroup>
              <Stack>
                <Typography fontSize={14} fontWeight={500}>
                  {tx.subscription_info.name}
                </Typography>
              </Stack>
            </Stack>
          )}
        </TableCell>
        <TableCell sx={{ width: "auto", minWidth: "200px" }}>
          <Typography color="#94969C">
            {moment.unix(tx.created).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          {crnc}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
      </TableRow>
    );
  }

  function getNextPaymentAmount() {
    if (nextPayment) {
      const amtn = parseStripeAmount(nextPayment.amount_due);
      const crnc = getCurrencySymbol(nextPayment.currency);
      return crnc + amtn.dollars + "." + amtn.cents;
    }
  }

  function getNextPaymentDate() {
    if (nextPayment) {
      return moment.unix(nextPayment.created).format("MMM DD, YYYY");
    }
  }
  function getNextPaymentTime() {
    if (nextPayment) {
      return moment.unix(nextPayment.created).format("hh:mm a (UTCZ)");
    }
  }

  return (
    <Stack sx={{ p: desktop ? "32px" : "20px", flex: 1 }}>
      <Grid spacing={"8px"} container columns={4} rowSpacing={"32px"}>
        <Grid item>
          <SimpleInsightCard
            accent="#2E90FA"
            title="Invoices"
            value={transactions.length.toString()}
          />
        </Grid>
        {nextPayment && (
          <Grid item>
            <SimpleInsightCard
              accent="#17B26A"
              title="Next Payment"
              value={getNextPaymentAmount()}
              subheading={
                <Typography fontSize={12}>
                  {getNextPaymentDate()}
                  <br />
                  {getNextPaymentTime()}
                </Typography>
              }
            />
          </Grid>
        )}
        <Grid item xs={4}></Grid>
      </Grid>
      <PFMTable
        sx={{ flex: 1 }}
        title="Transactions"
        titleBadge={transactions.length.toString()}
        description="List of all the invoices."
        actionButtons={
          <>
            <PFMInput
              placeholder="Search id, subscription..."
              startIcon={IC_SEARCH}
              text={filter}
              onUpdate={(t) => setFilter(t)}
            />
          </>
        }
        tableHead={
          <TableRow>
            <TableCell>Invoice</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Subscription</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        }
        tableBody={<>{filteredTransactions.map((t) => RenderTx(t))}</>}
      />
    </Stack>
  );
}
