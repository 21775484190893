import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Chip,
  Grid,
  Stack,
  TableCell,
  TableRow,
  ToggleButton,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import { StyledToggleButtonGroup } from "../../components/ToggleButtonGroup";
import InsightCard from "../../components/InsightCard";
import {
  IC_BALANCE,
  IC_CUSTOMERS,
  IC_REVENUES,
  IC_SEARCH,
  IC_TREND_UP,
} from "../../assets";
import PFMTable from "../../components/PFMTable";
import SimpleInsightCard from "../../components/SimpleInsightCard";
import PFMInput from "../../components/PFMInput";
import { RestListPayments } from "../../core/rest";
import { enqueueSnackbar } from "notistack";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import moment from "moment";

export default function SectionAdminPayments() {
  const [profile] = useRecoilState(aProfile);
  const [section, setSection] = useRecoilState(aCurrentSection);
  const [sectionDescription, setSectionDescription] = useRecoilState(
    aCurrentSectionDescription
  );
  const [busy, setBusy] = useState(true);

  const [transactions, setTransactions] = useState<any[]>([]);
  const [balance, setBalance] = useState("$0");
  const [revenue, setRevenue] = useState("$0");
  const [filter, setFilter] = useState("");

  const filteredTransactions = useMemo(() => {
    if (filter) {
      return transactions.filter(
        (t) =>
          t.customer?.name?.includes(filter) ||
          t.customer?.email?.includes(filter) ||
          t.id.includes(filter)
      );
    } else return transactions;
  }, [transactions, filter]);
  const amountDue = useMemo(() => {
    let amnt = 0;
    let c = "usd";
    for (let t of transactions) {
      if (t.status === "open") {
        amnt += t.amount_due;
        c = t.currency;
      }
    }
    const si = parseStripeAmount(amnt);
    return getCurrencySymbol(c) + si.dollars + "." + si.cents;
  }, [transactions]);

  async function loadData() {
    try {
      //Load data
      setBusy(true);
      const resp = await RestListPayments();
      setTransactions(resp.transactions);
      const bal = parseStripeAmount(resp.balance);
      setBalance(
        getCurrencySymbol(resp.currency) + bal.dollars + "." + bal.cents
      );
      const sales = parseStripeAmount(resp.sales);
      const sc = getCurrencySymbol(resp.currency);
      setRevenue(sc + sales.dollars + "." + sales.cents);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`Payments`);
    setSectionDescription(`Insights on your payments in one place. `);
    loadData();
  }, []);

  function RenderTx(tx: any) {
    const amnt = parseStripeAmount(tx.amount_paid || tx.amount_due);
    const crnc = getCurrencySymbol(tx.currency);
    return (
      <TableRow>
        <TableCell align="left">
          {tx.customer && (
            <Stack direction={"row"} spacing="12px" alignItems={"center"}>
              <Stack>
                <Typography fontSize={14} fontWeight={500}>
                  {tx.customer.name}
                </Typography>
                <Typography fontSize={14} fontWeight={400} color="#94969C">
                  {tx.customer.email}
                </Typography>
              </Stack>
            </Stack>
          )}
        </TableCell>
        <TableCell sx={{ width: "auto", minWidth: "200px" }}>
          <Typography color="#94969C">
            {moment.unix(tx.created).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          <Chip
            label={tx.id}
            variant="identifier"
            onClick={() => navigator.clipboard.writeText(tx.id)}
          />
        </TableCell>
        <TableCell align="left">
          <Chip
            label={tx.status}
            variant="identifier"
            sx={{ textTransform: "capitalize" }}
          />
        </TableCell>
        <TableCell>
          {crnc}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ p: "32px", overflow: "hidden" }} flex={1}>
      <Typography fontSize={18} fontWeight={600}>
        Balance overview
      </Typography>
      <Grid
        spacing={"28px"}
        container
        sx={{ py: "28px" }}
        columns={4}
        rowSpacing={"32px"}
      >
        <Grid item>
          <SimpleInsightCard
            accent="#2E90FA"
            title="Total Balance"
            value={balance}
          />
        </Grid>
        <Grid item>
          <SimpleInsightCard
            accent="#17B26A"
            title="Monthly Sales"
            value={revenue}
          />
        </Grid>
        <Grid item>
          <SimpleInsightCard
            accent="#D444F1"
            title="Open Invoices"
            value={amountDue || "$0"}
            // subheading={
            //   <Stack spacing="8px" direction={"row"} alignItems={"center"}>
            //     <IC_TREND_UP />
            //     <Typography color="#47CD89">30%</Typography>{" "}
            //     <Typography>vs last month</Typography>
            //   </Stack>
            // }
          />
        </Grid>
      </Grid>
      <PFMTable
        sx={{ flex: 1 }}
        title="Invoices"
        description="Recent payment invoices"
        actionButtons={
          <>
            <PFMInput
              placeholder="Search customer, invoice..."
              startIcon={IC_SEARCH}
              text={filter}
              onUpdate={(t) => setFilter(t)}
            />
          </>
        }
        tableHead={
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>Tx Date</TableCell>
            <TableCell>Invoice</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        }
        tableBody={<>{filteredTransactions.map((t) => RenderTx(t))}</>}
      />
    </Stack>
  );
}
