import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { enqueueSnackbar } from "notistack";
import {
  ProductConfig,
  ProductConfigTemplate,
  StripeProduct,
  TemplateField,
} from "@pfm/types";
import { IC_SETTINGS, IC_UPLOAD } from "../assets";
import { RestGetProductConfig, RestSetProductConfig } from "../core/rest";
import PFMInput from "../components/PFMInput";
import PFMCheckbox from "../components/PFMCheckbox";
import PFMAutoComplete from "../components/PFMAutoComplete";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { FbAuth, FbStorage } from "../authentication/firebase";

export default function ProductConfigDialog(
  props: DialogProps<StripeProduct & { subscriptionId: string }>
) {
  const [busy, setBusy] = useState(false);
  const [config, setConfig] = useState<Partial<ProductConfig>>();
  const [fields, setFields] = useState<TemplateField[]>([]);
  const product = props.data;
  const [uploadInfo, setUploadInfo] = useState<string>();
  const [uploadState, setUploadState] = useState<
    "uploading" | "uploaded" | "error"
  >();
  const [uploadProgress, setUploadProgress] = useState(0);

  const isFormValid = useMemo(() => {
    for (let f of fields) {
      if (f.required && !f.value) return false;
    }
    return true;
  }, [fields]);

  async function loadConfig() {
    try {
      setBusy(true);
      const cfg = await RestGetProductConfig(
        product?.id!,
        props.data?.subscriptionId!
      );
      if (cfg) {
        setConfig(cfg);
        if (!cfg.fields) cfg.fields = [];
        if (cfg.fields?.length !== product?.template?.fields.length) {
          // Set fields to template's fields.
          for (let f of product?.template?.fields || []) {
            if (fields.filter((fx) => fx.id === f.id).length > 0) continue;
            cfg.fields.push(f);
          }
        }
        setFields([...cfg.fields]);
      } else {
        setConfig({
          productId: product?.id!,
          subscriptionId: props.data?.subscriptionId!,
          fields: product?.template?.fields || [],
        });
        setFields(product?.template?.fields || []);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading product config. Please try again. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function saveConfig() {
    try {
      setBusy(true);
      const _config = config;
      _config!.subscriptionId = props.data?.subscriptionId;
      _config!.fields = fields;
      await RestSetProductConfig(product?.id!, _config!);
      enqueueSnackbar("Product configuration updated. ", {
        variant: "success",
      });
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar("Error saving product config. Please try again. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function uploadAttachment(attachment: File, fieldIndex: number) {
    try {
      setBusy(true);
      const r = ref(
        FbStorage,
        "/uploads/" + FbAuth.currentUser?.uid! + "/" + attachment.name
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          uid: FbAuth.currentUser!.uid,
        },
      });
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      await task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setUploadInfo(url);
          fields[fieldIndex].value = url;
          setFields([...fields]);
          enqueueSnackbar("File uploaded successfully.", {
            variant: "success",
          });
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
    }
  }

  useEffect(() => {
    loadConfig();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="16px" sx={{ py: "24px", mx: "24px" }}>
          <Stack
            justifyContent={"space-between"}
            direction={"row"}
            alignItems={"center"}
          >
            <Stack
              sx={{
                width: "56px",
                height: "56px",
                border: "1px solid #333741",
                borderRadius: "12px",
              }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <IC_SETTINGS fill="white" />
            </Stack>
          </Stack>
          <Stack spacing={"8px"}>
            <Typography fontSize={18} fontWeight={600}>
              {product?.name}
            </Typography>
            <Typography color="#94969C" fontSize={14} fontWeight={400}>
              View or update product configuration for administrators.
            </Typography>
          </Stack>
          <Divider />
          <Stack spacing="8px">
            {/* Render the config form here  */}
            {product?.template?.fields.map((f, i) => (
              <>
                {f.type === "label" && (
                  <Typography color="#94969C">{f.label}</Typography>
                )}
                {f.type === "divider" && <Divider />}
                {f.type === "text_input" && (
                  <PFMInput
                    label={f.label}
                    placeholder={f.placeholder}
                    important={f.required}
                    helperText={f.hint}
                    text={fields[i]?.value}
                    onUpdate={(t) => {
                      fields[i].value = t;
                      setFields([...fields]);
                    }}
                  />
                )}
                {f.type === "text_area" && (
                  <PFMInput
                    label={f.label}
                    placeholder={f.placeholder}
                    important={f.required}
                    helperText={f.hint}
                    multiline
                    rows={4}
                    text={fields[i]?.value}
                    onUpdate={(t) => {
                      fields[i].value = t;
                      setFields([...fields]);
                    }}
                  />
                )}
                {f.type === "hidden" && (
                  <PFMInput
                    label={f.label}
                    placeholder={f.placeholder}
                    important={f.required}
                    helperText={f.hint}
                    password
                    text={fields[i]?.value}
                    onUpdate={(t) => {
                      fields[i].value = t;
                      setFields([...fields]);
                    }}
                  />
                )}
                {f.type === "checkbox" && (
                  <PFMCheckbox
                    label={f.label}
                    value={Boolean(fields[i]?.value)}
                    onUpdate={(t) => {
                      fields[i].value = Boolean(t);
                      setFields([...fields]);
                    }}
                  />
                )}
                {f.type === "file" && (
                  <>
                    <input
                      id="file-upload-3"
                      type="file"
                      style={{
                        opacity: 0,
                        position: "absolute",
                        zIndex: "-9999",
                      }}
                      onChange={(fe) => {
                        uploadAttachment(fe.target?.files?.[0]!, i);
                      }}
                    />
                    <PFMInput
                      placeholder="Select file or enter url..."
                      buttonIcon={IC_UPLOAD}
                      label={f.label}
                      helperText={f.hint}
                      buttonClick={() => {
                        document.getElementById("file-upload-3")?.click();
                      }}
                      important={f.required}
                      text={fields[i]?.value}
                      onUpdate={(t) => {
                        fields[i].value = t;
                        setFields([...fields]);
                      }}
                    />
                  </>
                )}
                {(f.type === "option" || f.type === "options") && (
                  <PFMAutoComplete
                    label={f.label}
                    key={fields as any}
                    placeholder={f.placeholder}
                    important={f.required}
                    helperText={f.hint}
                    value={fields[i]?.value}
                    onUpdate={(t) => {
                      fields[i].value = t;
                      setFields([...fields]);
                    }}
                    multiple={f.type === "options"}
                    options={
                      (f.options?.map((o) => {
                        return { label: o };
                      }) as any) || []
                    }
                  />
                )}
              </>
            ))}
          </Stack>

          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
            <Button
              disabled={busy || !isFormValid}
              onClick={saveConfig}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
            >
              Update
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
