import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import { IC_ADD_USER } from "../assets";
import IconContainer from "../components/IconContainer";
import PFMAutoComplete from "../components/PFMAutoComplete";
import { enqueueSnackbar } from "notistack";
import { RestAddUser } from "../core/rest";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { AppConfig } from "../config";
import { Ticket } from "@pfm/types";
import { PFMClientMessage, PFMServerMessage } from "../core/sockTypes";
import { FbAuth } from "../authentication/firebase";

export default function CreateTicketDialog(props: DialogProps<any>) {
  const [comments, setComments] = useState("");
  const [subject, setSubject] = useState("");
  const [category, setCategory] = useState("");
  const [priority, setPriority] = useState("");

  const desktop = useMediaQuery("(min-width: 700px)");

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    AppConfig.socks,
    { share: true }
  );
  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const [busy, setBusy] = useState(false);

  async function createTicket() {
    try {
      if (readyState !== ReadyState.OPEN) {
        enqueueSnackbar("Unable to connect to the remote service.", {
          variant: "error",
        });
        return;
      }
      setBusy(true);
      const _ticket: Partial<Ticket> = {
        priority: priority.toLowerCase() as any,
        subject: subject,
        category: category,
      };
      const _msg: PFMClientMessage = {
        data: { ticket: _ticket, message: comments },
        token: await FbAuth.currentUser!.getIdToken(true),
        type: "createTicket",
      };
      await sendJsonMessage(_msg);
    } catch (err: any) {
      enqueueSnackbar("Error creating a new ticket. Error: " + err.message, {
        variant: "error",
      });
      setBusy(false);
    }
  }

  useEffect(() => {
    if (lastJsonMessage) {
      const msg = lastJsonMessage as PFMServerMessage;
      if (msg.type === "ticket") {
        // Ticket created.
        enqueueSnackbar(
          `Your ticket has been created. An administrator will get back to you as soon as possible.`,
          {
            variant: "success",
          }
        );
        props.closeHandler(true);
      }
    }
  }, [lastJsonMessage]);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
      fullScreen={!desktop}
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_ADD_USER />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Create Ticket
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Please provide the details for your new ticket.
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Subject
              </Typography>
              <PFMInput
                onUpdate={(t) => setSubject(t)}
                text={subject}
                fullWidth
                placeholder="What is this about?"
              />
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Category
              </Typography>
              <PFMAutoComplete
                options={[{ label: "General" }, { label: "Product Help" }]}
                placeholder="Select a category.."
                onUpdate={(v) => setCategory((v as any)?.label || "")}
                value={{ label: category } as any}
              />
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Priority
              </Typography>
              <PFMAutoComplete
                options={[
                  { label: "Low" },
                  { label: "Medium" },
                  { label: "High" },
                  { label: "Urgent" },
                ]}
                placeholder="Select a priority.."
                onUpdate={(v) => setPriority((v as any)?.label || "")}
                value={{ label: priority } as any}
              />
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Message
              </Typography>
              <PFMInput
                onUpdate={(t) => setComments(t)}
                multiline
                text={comments}
                rows={4}
                fullWidth
                placeholder="Please write the details."
              />
            </Stack>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              disabled={busy || readyState !== ReadyState.OPEN}
              onClick={createTicket}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
            >
              Create
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
