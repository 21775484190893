import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import {
  IC_ADD,
  IC_ADD_IMAGE,
  IC_PEN,
  IC_CLOSE,
  IC_DOWNLOAD,
  IC_HELP,
  IC_SUPPORT,
  IC_UPLOAD,
} from "../assets";
import IconContainer from "../components/IconContainer";
import { enqueueSnackbar } from "notistack";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { FbAuth, FbStorage } from "../authentication/firebase";
import {
  RestAddProduct,
  RestEditProduct,
  RestEditProductMedia,
} from "../core/rest";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import DeleteProductDialog from "./DeleteProduct";
import { StripeProduct } from "@pfm/types";
import ReactPlayer from "react-player";

export default function ManageProductMediaDialog(
  props: DialogProps<StripeProduct>
) {
  const [name, setName] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [images, setImages] = useState<string[]>([]);
  const [videos, setVideos] = useState<string[]>([]);

  // File upload
  const [uploadInfo, setUploadInfo] = useState<string>();
  const [uploadState, setUploadState] = useState<
    "uploading" | "uploaded" | "error"
  >();
  const [uploadProgress, setUploadProgress] = useState(0);
  const { showModal } = useModal();

  const [busy, setBusy] = useState(false);

  async function uploadAttachment(attachment: File) {
    try {
      setBusy(true);
      const r = ref(
        FbStorage,
        "/uploads/" + FbAuth.currentUser?.uid! + "/" + attachment.name
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          uid: FbAuth.currentUser!.uid,
        },
      });
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setUploadInfo(url);
          setImages([...images, url]);
          enqueueSnackbar("File uploaded successfully.", {
            variant: "success",
          });
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
    }
  }

  async function updateProduct() {
    try {
      // Update
      setBusy(true);
      await RestEditProductMedia(props.data!.id, videos, images);
      enqueueSnackbar("Product media has been updated.", {
        variant: "success",
      });
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar("Error updating product media. Error: " + err.message, {
        variant: "error",
      });
    }
    setBusy(false);
  }

  function RenderImage(img: string) {
    return (
      <Stack
        sx={{
          m: "4px",
          width: "100px",
          height: "100px",
          borderRadius: "12px",
          position: "relative",
          background: "#303030",
        }}
      >
        <img
          src={img}
          style={{
            objectFit: "cover",
            height: "100%",
            width: "100%",
            borderRadius: "12px",
            opacity: 0.6,
          }}
        />
        <IconButton
          sx={{
            height: "40px",
            width: "40px",
            position: "absolute",
            top: 4,
            right: 4,
          }}
          title="delete image"
        >
          <IC_CLOSE stroke="white" />
        </IconButton>
      </Stack>
    );
  }

  function RenderVideo(url: string) {
    return (
      <Stack
        sx={{
          m: "4px",
          width: "100px",
          height: "100px",
          borderRadius: "12px",
          overflow: "hidden",
          position: "relative",
          background: "#303030",
        }}
      >
        <ReactPlayer
          url={url}
          height={100}
          width={100}
          style={{ borderRadius: "12px" }}
        />
        <IconButton
          sx={{
            height: "40px",
            width: "40px",
            position: "absolute",
            top: 4,
            right: 4,
          }}
          title="delete image"
        >
          <IC_CLOSE stroke="white" />
        </IconButton>
      </Stack>
    );
  }

  useEffect(() => {
    if (props.data?.metadata.images) {
      const imgs = JSON.parse(props.data.metadata.images);
      setImages(imgs || []);
    }
    if (props.data?.metadata.videos) {
      const vds = JSON.parse(props.data.metadata.videos);
      setVideos(vds || []);
    }
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_PEN />
            </IconContainer>
            <Stack>
              <Stack direction={"row"} alignItems={"center"} spacing="8px">
                <Typography fontSize={18} fontWeight={600}>
                  Manage Product Media
                </Typography>
              </Stack>
              <Typography fontSize={14} fontWeight={400}>
                View or manage product media, such as images.
              </Typography>
            </Stack>
          </Stack>

          {/* The content  */}
          <Stack sx={{ px: "24px" }} spacing="8px">
            <Typography>Product Media</Typography>
            <Stack direction={"row"} flexWrap={"wrap"}>
              {images.length === 0 && videos.length === 0 && (
                <Typography sx={{ opacity: 0.5, textAlign: "center" }}>
                  No images or videos found.
                </Typography>
              )}
              {videos.map((url) => RenderVideo(url))}
              {images.map((img) => RenderImage(img))}
            </Stack>
          </Stack>

          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <input
              id="file-upload-2"
              type="file"
              style={{ opacity: 0, position: "absolute", zIndex: "-9999" }}
              onChange={(fe) => {
                uploadAttachment(fe.target?.files?.[0]!);
              }}
            />
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Add Media
              </Typography>
              <Stack flex={1} spacing="8px">
                <Stack
                  direction={"row"}
                  sx={{ width: "100%" }}
                  spacing="20px"
                  alignItems={"center"}
                >
                  <Stack
                    onClick={(c) => {
                      if (busy) return;
                      document.getElementById("file-upload-2")?.click();
                    }}
                    flex={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                      background: "#182230",
                      p: "16px",
                      borderRadius: "12px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography fontSize={14} fontWeight={600} color="#CECFD2">
                      Click to upload
                    </Typography>
                    <Typography fontSize={12} fontWeight={400} color="#94969C">
                      PNG, JPG (max. 800x400)
                    </Typography>
                  </Stack>
                </Stack>
                <PFMInput
                  buttonClick={() => setVideos([...videos, videoUrl])}
                  placeholder="Enter video url..."
                  buttonIcon={IC_UPLOAD}
                  text={videoUrl}
                  onUpdate={(t) => setVideoUrl(t)}
                />
              </Stack>
            </Stack>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={updateProduct}
              disabled={busy}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
            >
              Update
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
