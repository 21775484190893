import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_USERS } from "../assets";
import IconContainer from "../components/IconContainer";
import { User } from "@pfm/types";
import PFMTable from "../components/PFMTable";
import { RestListReferralsByUser } from "../api/admin";
import { enqueueSnackbar } from "notistack";
import { parseStripeAmount } from "../core/helper";
import moment from "moment";

export default function ViewReferralsDialog(props: DialogProps<User>) {
  const [busy, setBusy] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const theme = useTheme();

  async function load() {
    // Set the network
    try {
      const refs = await RestListReferralsByUser(props.data?.uid!);
      if (refs) {
        setUsers(refs);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
      console.error("Error loading referrals by specified user. ", err);
    }
  }

  useEffect(() => {
    load();
  }, []);

  function RenderRef(ui: User & { earnings: number }) {
    const pi = parseStripeAmount(ui.earnings);
    return (
      <TableRow>
        <TableCell>
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={ui.image} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {ui.name || ui.customer_info.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {ui.email || ui.customer_info.email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            {moment
              .unix(ui.registeredTimestamp)
              .format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>
            ${pi.dollars}.{pi.cents}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
      maxWidth={"lg"}
    >
      <DialogContent sx={{ overflow: "hidden" }}>
        <Stack spacing="20px" sx={{ py: "24px", overflow: "hidden" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_USERS fill="white" />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Referrals
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Users invited by <b>{props.data?.name}</b>
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <PFMTable
            sx={{ maxHeight: "500px", overflow: "auto" }}
            title="Users"
            description="List of all the referred users. "
            tableHead={
              <TableRow>
                <TableCell>
                  <Typography>User</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Date Registered</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Earnings</Typography>
                </TableCell>
              </TableRow>
            }
            tableBody={users.map((u) => RenderRef(u as any))}
          />
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              size="large"
              onClick={props.closeHandler}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
