import React, { useRef } from "react";
import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DialogProps } from "./props";
import SignatureCanvas from "react-signature-canvas";
import PFMCheckbox from "../components/PFMCheckbox";
import { enqueueSnackbar } from "notistack";
import { TransparentPaper } from "../components/TransparentPaper";

export default function SignupAgreementDialog(props: DialogProps<any>) {
  const [busy, setBusy] = useState(false);
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [automatedTrading, setAutomatedTrading] = useState(false);
  const [marketConditions, setMarketConditions] = useState(false);
  const [reApply, setReApply] = useState(false);
  const [redistribution, setRedistribution] = useState(false);
  const desktop = useMediaQuery("(min-width:720px)");

  const sigRef = useRef<any>();
  const theme = useTheme();

  function accept() {
    // Get the signature
    if (sigRef.current.isEmpty()) {
      enqueueSnackbar("Please provide a signature.", { variant: "error" });
      return;
    }
    // Make sure all checks are turned on
    if (
      termsOfUse &&
      automatedTrading &&
      marketConditions &&
      reApply &&
      redistribution
    ) {
      props.closeHandler({
        data: sigRef.current.toDataURL("image/jpeg"),
      });
    } else {
      enqueueSnackbar("You must accept all the terms of use and policy.", {
        variant: "error",
      });
    }
  }

  function clearSignature() {
    if (sigRef.current) {
      sigRef.current.clear();
    }
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      maxWidth="lg"
      fullScreen={!desktop}
    >
      <DialogContent
        sx={{
          background: "#182230",
          borderRadius: "16px",
          p: 0,
          m: 0,
        }}
      >
        <Stack sx={{ width: desktop ? "736px" : "100%" }} spacing={"6px"}>
          <Stack sx={{ p: desktop ? "40px" : "20px" }}>
            <Typography fontSize={"14px"} fontWeight={"500"}>
              Signature
            </Typography>
            <div
              style={{
                width: "320px",
                height: "128px",
                background: "#101828",
                borderRadius: "8px",
              }}
            >
              <SignatureCanvas
                ref={(ref) => (sigRef.current = ref)}
                penColor="white"
                canvasProps={{
                  width: 320,
                  height: 128,
                  className: "sigCanvas",
                }}
              />
            </div>
            <Button
              onClick={clearSignature}
              variant="text"
              color="error"
              sx={{ alignSelf: "start", fontSize: "12px", fontWeight: 400 }}
            >
              Clear Signature
            </Button>

            <Stack sx={{ pt: "26px" }} spacing={"16px"}>
              <Typography fontSize={"20px"} fontWeight={500}>
                Agreements with Prop Firm Masters
              </Typography>

              <Typography fontSize={"16px"} fontWeight={400}>
                Terms of Use Policy
              </Typography>
              <Stack spacing="12px">
                <PFMCheckbox
                  value={termsOfUse}
                  onUpdate={(c) => setTermsOfUse(c)}
                  typographySx={{ fontSize: "14px" }}
                  label="I agree to Terms of Use Policy"
                />
                <PFMCheckbox
                  value={automatedTrading}
                  onUpdate={(c) => setAutomatedTrading(c)}
                  typographySx={{ fontSize: "14px" }}
                  label="I understand that Prop Firm Masters may provide me with an PFM EA (Expert Advisor) for automated trading on my behalf."
                />
                <PFMCheckbox
                  value={marketConditions}
                  onUpdate={(c) => setMarketConditions(c)}
                  typographySx={{ fontSize: "14px" }}
                  label="I acknowledge that I may not pass Prop Firm Evaluations on the first try due to unforeseen market conditions. "
                />
                <PFMCheckbox
                  value={reApply}
                  onUpdate={(c) => setReApply(c)}
                  typographySx={{ fontSize: "14px" }}
                  label="I understand that after passing Prop Firm Evaluations, the EA may encounter drawdown due to unforeseen market conditions. If necessary, I will reapply for funding and start the process anew."
                />
                <PFMCheckbox
                  value={redistribution}
                  onUpdate={(c) => setRedistribution(c)}
                  typographySx={{ fontSize: "14px" }}
                  label="I agree and understand that I will not attempt to share, hack or crack to any PFM EA and distribute it to others."
                />
              </Stack>
            </Stack>

            <Stack
              sx={{ pt: "10px" }}
              direction={"row"}
              justifyContent={"end"}
              spacing="8px"
            >
              <Button
                onClick={() => props.closeHandler(false)}
                variant="contained"
                sx={{ background: "#101828" }}
              >
                Cancel
              </Button>
              <Button onClick={accept} variant="contained">
                Accept and continue
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
