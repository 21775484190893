import React, { useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Chip,
  IconButton,
  Link,
  Stack,
  TableCell,
  TableRow,
  ToggleButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import SimpleInsightCard from "../../components/SimpleInsightCard";
import { enqueueSnackbar } from "notistack";
import {
  RestGetPayouts,
  RestGetReferrals,
  RestListAffiliateTransactions,
} from "../../core/rest";
import moment from "moment";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import { ContentCopy, LinkRounded } from "@mui/icons-material";
import { AffiliateTransaction, PayoutRequest } from "@pfm/types";
import { useModal } from "mui-modal-provider";
import WithdrawDialog from "../../dialogs/Withdraw";
import { StyledToggleButtonGroup } from "../../components/ToggleButtonGroup";

export default function SectionUserReferrals() {
  const [profile] = useRecoilState(aProfile);
  const [section, setSection] = useRecoilState(aCurrentSection);
  const [sectionDescription, setSectionDescription] = useRecoilState(
    aCurrentSectionDescription
  );
  const [busy, setBusy] = useState(true);
  const desktop = useMediaQuery("(min-width: 700px)");

  const [available, setAvailable] = useState<number>(0);
  const [withdrawn, setWithdrawn] = useState(0);
  const [pending, setPending] = useState(0);
  const [code, setCode] = useState("");
  const [referrals, setReferrals] = useState<any[]>([]);

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [txns, setTxns] = useState<AffiliateTransaction[]>([]);
  const [payouts, setPayouts] = useState<PayoutRequest[]>([]);

  const [view, setView] = useState<"referrals" | "payouts" | "transactions">(
    "referrals"
  );

  const { showModal } = useModal();

  const _available = useMemo(() => {
    const pi = parseStripeAmount(available);
    return `USD $${pi.dollars}.${pi.cents}`;
  }, [available]);
  const _pending = useMemo(() => {
    const pi = parseStripeAmount(pending);
    return `USD $${pi.dollars}.${pi.cents}`;
  }, [pending]);
  const _withdrawn = useMemo(() => {
    const pi = parseStripeAmount(withdrawn);
    return `USD $${pi.dollars}.${pi.cents}`;
  }, [withdrawn]);

  async function loadData(pageNo?: number) {
    try {
      //Load data
      setBusy(true);
      // If view is in referrals
      if (view === "referrals") {
        const r = await RestGetReferrals();
        if (r) {
          setAvailable(r.available);
          setWithdrawn(r.withdrawn);
          setCode(r.code);
          setReferrals(r.referrals);
          setPending(r.pending);
        }
      } else if (view === "payouts") {
        const r3 = await RestGetPayouts(pageNo || 0, 50);
        if (r3) {
          setTotalPages(r3.pageCount);
          setPageNumber(r3.currentPage);
          setTotalUsers(r3.total);
          setPayouts(r3.data);
        }
      } else {
        const r2 = await RestListAffiliateTransactions(pageNo || 0, 50);
        if (r2) {
          setTotalPages(r2.pageCount);
          setPageNumber(r2.currentPage);
          setTotalUsers(r2.total);
          setTxns(r2.data);
        }
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
    }
    setBusy(false);
  }

  function showWithdrawDialog() {
    const mod = showModal(WithdrawDialog, {
      closeHandler(result) {
        mod.destroy();
        if (result === true) {
          loadData(pageNumber);
        }
      },
      data: {
        balance: _available,
      },
    });
  }

  useEffect(() => {
    setSection(`Referrals`);
    setSectionDescription(`Earn commission by referring other users. `);
    loadData();
  }, []);

  function RenderReferral(tx: {
    name: string;
    date: number;
    earnings: number;
  }) {
    const amnt = parseStripeAmount(tx.earnings);
    return (
      <TableRow>
        <TableCell>
          <Typography sx={{ fontSize: desktop ? 16 : 12 }} color="#94969C">
            {tx.name}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "auto", minWidth: "200px" }}>
          <Typography sx={{ fontSize: desktop ? 16 : 12 }} color="#94969C">
            {moment.unix(tx.date).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell sx={{ fontSize: desktop ? 16 : 12 }}>
          USD ${amnt.dollars}.{amnt.cents}
        </TableCell>
      </TableRow>
    );
  }

  function RenderTxn(tx: AffiliateTransaction) {
    const amnt = parseStripeAmount(tx.amount);
    const sym = getCurrencySymbol(tx.currency);
    return (
      <TableRow>
        <TableCell>
          <Chip
            sx={{ fontSize: desktop ? 16 : 12 }}
            size="small"
            label={tx.kind === "payout" ? "Debit" : "Credit"}
          />
        </TableCell>
        <TableCell>
          <Typography sx={{ fontSize: desktop ? 16 : 12 }}>
            {tx.kind === "reward" ? tx.referralName : "N/A"}
          </Typography>
        </TableCell>
        <TableCell
          sx={{ width: "auto", minWidth: "200px", fontSize: desktop ? 16 : 12 }}
        >
          <Typography color="#94969C" sx={{ fontSize: desktop ? 16 : 12 }}>
            {moment.unix(tx.created).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell sx={{ fontSize: desktop ? 16 : 12 }}>
          {sym}
          {amnt.dollars}.{amnt.cents}
        </TableCell>
      </TableRow>
    );
  }

  function RenderPayout(po: PayoutRequest) {
    const amnt = parseStripeAmount(po.amount);
    return (
      <TableRow>
        <TableCell>
          <Chip
            sx={{ fontSize: desktop ? 16 : 12 }}
            variant="identifier"
            label={po.id}
            size="small"
          />
        </TableCell>
        <TableCell>
          <Typography fontSize={desktop ? 16 : 12}>
            {po.payoutMethod.type === "crypto"
              ? `${po.payoutMethod.coin}`
              : "Stripe"}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography sx={{ fontSize: desktop ? 16 : 12 }} color="#94969C">
            {moment.unix(po.createdAt).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>

        <TableCell>
          <Chip
            variant="identifier"
            sx={{ fontSize: desktop ? 16 : 12 }}
            label={po.status}
            size="small"
          />
        </TableCell>
        <TableCell sx={{ fontSize: desktop ? 16 : 12 }}>
          ${amnt.dollars}.{amnt.cents}
        </TableCell>
      </TableRow>
    );
  }

  useEffect(() => {
    loadData();
  }, [view]);

  return (
    <Stack sx={{ px: desktop ? "32px" : "20px", flex: 1 }} spacing="12px">
      <StyledToggleButtonGroup
        sx={{ alignSelf: desktop ? "flex-end" : "center" }}
        exclusive
        value={view}
        onChange={(c, e) => setView(e)}
      >
        <ToggleButton value={"referrals"}>
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
            spacing="16px"
            sx={{ px: desktop ? "16px" : "8px" }}
          >
            <Badge
              variant="dot"
              color={view === "referrals" ? "success" : undefined}
            ></Badge>
            <Typography fontSize={desktop ? 16 : 14}>Referrals</Typography>
          </Stack>
        </ToggleButton>
        <ToggleButton value={"payouts"}>
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
            spacing="16px"
            sx={{ px: desktop ? "16px" : "8px" }}
          >
            <Badge
              variant="dot"
              color={view === "payouts" ? "success" : undefined}
            ></Badge>
            <Typography fontSize={desktop ? 16 : 14}>Payouts</Typography>
          </Stack>
        </ToggleButton>
        <ToggleButton value={"transactions"}>
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
            spacing="16px"
            sx={{ px: desktop ? "16px" : "8px" }}
          >
            <Badge
              variant="dot"
              color={view === "transactions" ? "success" : undefined}
            ></Badge>
            <Typography fontSize={desktop ? 16 : 14}> Balances</Typography>
          </Stack>
        </ToggleButton>
      </StyledToggleButtonGroup>

      {view === "referrals" && (
        <>
          <Stack
            direction={"row"}
            sx={{
              px: "24px",
              py: "20px",
              background: "#101828",
              borderRadius: "12px",
            }}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            rowGap={"14px"}
          >
            <Stack>
              <Typography fontWeight={600} fontSize={desktop ? 20 : 16}>
                Join our referral program
              </Typography>
              <Typography fontSize={desktop ? 16 : 14}>
                Receive a 10% recurring commission on each subscription made by
                users you refer when using your invite link.
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              spacing="8px"
              alignItems={"center"}
              justifyContent={desktop ? undefined : "center"}
            >
              <Typography>{code.toUpperCase()}</Typography>
              <Stack
                direction={"row"}
                justifyContent={desktop ? undefined : "space-between"}
              >
                <IconButton
                  title="Copy Code"
                  onClick={() => {
                    navigator.clipboard.writeText(code.toUpperCase());
                    enqueueSnackbar("Referral code copied to clipboard.");
                  }}
                >
                  <ContentCopy />
                </IconButton>
                <IconButton
                  title="Copy Link"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://portal.propfirmmasters.com/signup?ref=${code}`
                    );
                    enqueueSnackbar("Invite link copied to clipboard.");
                  }}
                >
                  <LinkRounded />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
          <PFMTable
            sx={{ flex: 1 }}
            paginated
            pageNumber={pageNumber}
            busy={busy}
            totalPages={totalPages}
            nextPage={(t) => loadData(t)}
            prevPage={(l) => loadData(l)}
            title={"Referrals"}
            titleBadge={referrals.length.toString()}
            description={"List of all the referred users"}
            tableHead={
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Registered</TableCell>
                <TableCell>Outcome</TableCell>
              </TableRow>
            }
            tableBody={referrals.map((t) => RenderReferral(t))}
          />
        </>
      )}

      {view === "payouts" && (
        <>
          <Stack
            direction={"row"}
            rowGap={"12px"}
            columnGap={"12px"}
            flexWrap={"wrap"}
          >
            <SimpleInsightCard
              accent="#2E90FA"
              title="Current Balance"
              value={_available}
            />
            <SimpleInsightCard
              accent="#2E61FA"
              title="Processing"
              value={_pending}
            />
            <SimpleInsightCard
              accent="#448"
              title="Total Payouts"
              value={_withdrawn}
            />
          </Stack>
          <Stack
            direction={"row"}
            sx={{
              px: "24px",
              py: "20px",
              background: "#101828",
              borderRadius: "12px",
            }}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            rowGap={"12px"}
          >
            <Stack>
              <Typography fontWeight={600} fontSize={desktop ? 20 : 16}>
                Manage Payout Method
              </Typography>
              <Typography fontSize={desktop ? 16 : 14}>
                View or update your wallet information. You can receive payouts
                through ERC-20 (USDT, & USDC) and TRX (USDT only).
              </Typography>
            </Stack>
            <Button
              onClick={showWithdrawDialog}
              size="large"
              variant="contained"
            >
              Manage
            </Button>
          </Stack>
          <PFMTable
            sx={{ flex: 1 }}
            paginated
            pageNumber={pageNumber}
            busy={busy}
            totalPages={totalPages}
            nextPage={(t) => loadData(t)}
            prevPage={(l) => loadData(l)}
            title={"Payouts"}
            titleBadge={payouts.length.toString()}
            description={"View the payout requests."}
            tableHead={
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Method</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            }
            tableBody={payouts.map((tx) => RenderPayout(tx))}
          />
        </>
      )}

      {view === "transactions" && (
        <PFMTable
          sx={{ flex: 1 }}
          paginated
          pageNumber={pageNumber}
          busy={busy}
          totalPages={totalPages}
          nextPage={(t) => loadData(t)}
          prevPage={(l) => loadData(l)}
          title={"Balance History"}
          titleBadge={txns.length.toString()}
          description={"Earnings added to your account from referrals."}
          tableHead={
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          }
          tableBody={txns.map((t) => RenderTxn(t))}
        />
      )}
    </Stack>
  );
}
