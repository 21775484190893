import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./pages/404";
import ThemeManager from "./middlewares/ThemeManager";
import PageDashboard from "./pages/dashboard";
import PageSplashScreen from "./components/SplashScreen";
import AuthenticatedRoute from "./middlewares/AuthenticatedRoute";
import PageLogin from "./pages/login";
import PageSignup from "./pages/signup";
import PageRecovery from "./pages/recovery";
import PageUpdatePassword from "./pages/update-password";
import "./index.css";
import { SnackbarProvider } from "notistack";
import { RecoilRoot } from "recoil";
import PageVerification from "./pages/verify";
import AuthHandler from "./pages/auth";
import ModalProvider from "mui-modal-provider";
import PageSettings from "./pages/settings";
import PageDiscordHandler from "./pages/discord";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <RecoilRoot>
    {/* The theme provider is added at the root level  */}
    <ThemeManager>
      <ModalProvider>
        <SnackbarProvider
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          <PageSplashScreen>
            <BrowserRouter basename="/">
              <Routes>
                <Route path="/signup" element={<PageSignup />} />
                <Route path="/login" element={<PageLogin />} />
                <Route path="/recovery" element={<PageRecovery />} />
                <Route path="/verify" element={<PageVerification />} />
                <Route path="/auth" element={<AuthHandler />} />
                <Route
                  path="/discord"
                  element={
                    <AuthenticatedRoute redirect={"/login"}>
                      <PageDiscordHandler />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/update-password"
                  element={<PageUpdatePassword />}
                />
                {/* <Route path="/settings/*" element={<PageSettings />} /> */}
                <Route
                  path="/*"
                  element={
                    <AuthenticatedRoute redirect={"/login"}>
                      <PageDashboard />
                    </AuthenticatedRoute>
                  }
                />
              </Routes>
            </BrowserRouter>
          </PageSplashScreen>
        </SnackbarProvider>
      </ModalProvider>
    </ThemeManager>
  </RecoilRoot>
);
