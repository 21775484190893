import React, { useEffect, useState } from "react";
import { Button, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import { IC_REFRESH } from "../../assets";
import { useModal } from "mui-modal-provider";
import MySubscriptionCard from "../../components/MySubscriptionCard";
import { Subscription } from "@pfm/types";
import {
  RestGetCustomerPortal,
  RestListActiveSubscriptions,
} from "../../core/rest";
import { enqueueSnackbar } from "notistack";

export default function SectionUserSubscriptions() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const { showModal } = useModal();
  const [busy, setBusy] = useState(false);
  const desktop = useMediaQuery("(min-width: 700px)");

  const [subscriptions, setSubscriptions] = useState<
    { info: Subscription; data: any }[]
  >([]);

  async function loadSubscriptions() {
    try {
      setBusy(true);
      const _subs = await RestListActiveSubscriptions();
      setSubscriptions(_subs);
    } catch (err: any) {
      enqueueSnackbar("Error loading subscriptions. Please try again.", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function showCustomerPortal() {
    try {
      setBusy(true);
      const _link = await RestGetCustomerPortal();
      if (_link) {
        window.open(_link);
      }
    } catch (err: any) {
      enqueueSnackbar("Error getting customer portal link. Please try again.", {
        variant: "error",
      });
    }
    setBusy(false);
  }
  useEffect(() => {
    setSection(`Subscriptions`);
    setSectionDescription(
      `Manage your active subscriptions of all our products, in one place.`
    );
    loadSubscriptions();
  }, []);

  return (
    <Stack sx={{ p: desktop ? "40px" : "24px" }}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ width: "100%" }}
      >
        <Stack>
          <Typography fontSize={desktop ? 24 : 20} fontWeight={400}>
            Your Subscriptions
          </Typography>
          <Typography
            fontSize={desktop ? 14 : 12}
            fontWeight={400}
            color="#94969C"
          >
            Following are your active subscriptions
          </Typography>
        </Stack>
        <Stack direction={"row"} spacing={"8px"}>
          <Button
            onClick={showCustomerPortal}
            color="secondary"
            variant="contained"
            disabled={busy}
          >
            Manage Subscriptions
          </Button>
          <Button
            onClick={loadSubscriptions}
            disabled={busy}
            variant="contained"
            startIcon={<IC_REFRESH />}
            color="secondary"
            sx={{ height: "46px" }}
          >
            Refresh
          </Button>
        </Stack>
      </Stack>
      <Stack
        sx={{ py: "32px" }}
        direction={"row"}
        flexWrap={"wrap"}
        columnGap={"32px"}
        rowGap={"32px"}
      >
        {subscriptions.map((s) => (
          <MySubscriptionCard subscription={s.info} data={s.data} />
        ))}
      </Stack>
    </Stack>
  );
}
