import React, { useState } from "react";
import { Button, CssBaseline, Stack, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AppConfig } from "../config";
import { FbAuth } from "../authentication/firebase";
import { useRecoilState } from "recoil";
import { aToken } from "../core/states";
import { RestSetImpersonationId } from "../api/admin";
import { enqueueSnackbar } from "notistack";

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    identifier: true;
  }
}
declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    input: true;
  }
}
declare module "@mui/material/Switch" {
  interface SwitchPropsColorOverrides {
    pfm: true;
  }
}

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#7F56D9",
      "600": "#7F56D9",
    },
    info: {
      main: "#7A5AF8",
      "600": "#7A5AF8",
    },
    secondary: {
      main: "#7A5AF8",
    },
    background: {
      default: "#0C111D",
    },
  },
  typography: {
    fontFamily: "Inter",
  },
  components: {
    MuiRadio: {
      defaultProps: {
        icon: (
          <Stack
            sx={{
              width: "20px",
              height: "20px",
              background: "transparent",
              border: "1px solid #333741",
              borderRadius: "100px",
            }}
          ></Stack>
        ),
        checkedIcon: (
          <Stack
            sx={{
              width: "20px",
              height: "20px",
              background: "white",
              border: "5px solid #7F56D9",
              borderRadius: "100px",
            }}
          ></Stack>
        ),
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          background: "#434d64aa",
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        sx: {
          p: 0,
        },
      },
      styleOverrides: {
        root: {
          background: "#101828",
          p: 0,
          m: 0,
          borderRadius: "12px",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          background: "#182230",
          border: "1px solid #FFFFFF1F",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "8px",
        },
        containedSecondary: {
          background: "#182230",
          color: "#CECFD2",
          borderRadius: "8px",
          ":hover": {
            background: "#1F242F",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 0,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          height: "44px",
          background: "#182230",
        },
        root: {
          height: "72px",
          background: "#101828",
          "&:nth-of-type(even)": {
            background: "#182230",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.required:after": {
            content: "'*'",
            color: "red",
            padding: "4px",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filled: {
          background: "#182230",
          color: "#CECFD2",
        },
        filledWarning: {
          border: "1px solid #F79009",
          borderRadius: "12px",
        },
        filledInfo: {
          border: "1px solid #079455",
          borderRadius: "12px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        clickable: {
          ":hover": {
            background: "#53389E",
            cursor: "pointer",
          },
        },
      },
      variants: [
        {
          props: {
            variant: "identifier",
          },
          style: {
            background: "#2C1C5F",
            border: "1px solid #53389E",
            borderRadius: "6px",
            color: "#D6BBFB",
            cursor: "default",
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          stroke: "#1F242F",
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: {
            color: "input",
          },
          style: {
            width: "100%",
            height: "100%",
            borderRadius: "6px",
          },
        },
      ],
    },
  },
});

export default function ThemeManager(props: any) {
  const [token] = useRecoilState(aToken);
  const [busy, setBusy] = useState(false);
  async function exitImp() {
    try {
      setBusy(true);
      await RestSetImpersonationId("");
      // Reload
      window.location.reload();
    } catch (err: any) {
      enqueueSnackbar("Error processing request. Please try again. ", {
        variant: "error",
      });
      console.error(err);
    }
  }
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {AppConfig.mode === "debug" && (
        <Stack
          sx={{ background: "gold", px: "12px" }}
          justifyContent={"space-between"}
          direction={"row"}
        >
          <Typography color={"black"}>Debug Mode</Typography>
          <Typography color={"black"}>{AppConfig.version}</Typography>
        </Stack>
      )}
      {AppConfig.mode === "beta" && (
        <Stack
          sx={{ background: "#444488", px: "12px" }}
          justifyContent={"space-between"}
          direction={"row"}
        >
          <Typography>Debug Mode</Typography>
          <Typography>{AppConfig.version}</Typography>
        </Stack>
      )}
      {/* If we are impersonating a user, we say so:  */}
      {token && token.claims.impersonatedUid && (
        <Stack
          sx={{ background: "#D23", px: "12px" }}
          justifyContent={"space-between"}
          direction={"row"}
          alignItems={"center"}
        >
          <Typography>Impersonation Mode</Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={"8px"}
            sx={{ py: "8px" }}
          >
            <Typography>
              ({token?.claims?.impersonatedName as any}){" "}
              {token?.claims?.impersonatedEmail as any} /{" via "}
              {FbAuth.currentUser?.email}
            </Typography>
            <Button disabled={busy} onClick={exitImp} variant="contained">
              Exit
            </Button>
          </Stack>
        </Stack>
      )}
      {props.children}
    </ThemeProvider>
  );
}
