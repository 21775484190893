import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import { IC_ADD_USER, IC_ALERT_WARNING, IC_CLOSE, IC_TRASH } from "../assets";
import IconContainer from "../components/IconContainer";
import PFMAutoComplete from "../components/PFMAutoComplete";
import { enqueueSnackbar } from "notistack";
import { RestAddUser, RestDeleteProduct, RestDisableUser } from "../core/rest";
import { User } from "@pfm/types";
import PFMCheckbox from "../components/PFMCheckbox";
import moment from "moment";

export default function DeleteProductDialog(props: DialogProps<any>) {
  const [busy, setBusy] = useState(false);

  async function deleteProduct() {
    try {
      setBusy(true);
      await RestDeleteProduct(props.data.id);
      enqueueSnackbar(`The product has been deleted.`, {
        variant: "success",
      });
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar("Error deleting product. Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_TRASH />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Delete Product
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Deletes or archives an existing product.
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Product name
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.name}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Description
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.description}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Created
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {moment
                  .unix(props.data.created)
                  .format("MMM DD, YYYY HH:mm:ss")}{" "}
                by {props.data.metadata.createdByName}
              </Typography>
            </Stack>

            <Alert
              color="warning"
              variant="filled"
              icon={<IC_ALERT_WARNING stroke="#F79009" fill="transparent" />}
            >
              <AlertTitle>Warning</AlertTitle>
              <Typography>
                This product will no longer be included in subscriptions, and
                any subscriptions associated with it will be cancelled at the
                end of their schedule. This cannot be undone.
              </Typography>
            </Alert>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={deleteProduct}
              disabled={busy}
              size="large"
              fullWidth
              variant="contained"
              color="error"
            >
              Delete Product
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
