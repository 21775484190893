import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_CLOSE } from "../assets";
import IconContainer from "../components/IconContainer";
import { Subscription } from "@pfm/types";
import PFMCheckbox from "../components/PFMCheckbox";

export default function CancelSubscriptionAdminDialog(
  props: DialogProps<Subscription>
) {
  const [proceed, setProceed] = useState(false);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          <Stack sx={{ width: "100%", px: "24px" }} spacing="8px">
            <IconContainer>
              <IC_CLOSE stroke="red" />
            </IconContainer>
            <Typography fontSize={18} fontWeight={600}>
              Cancel User Subscription: {props.data?.name}
            </Typography>
            <Typography color="#94969C" fontSize={14} fontWeight={400}>
              Are you sure you want to cancel the user's subscription? This will
              cancel the user's subscription immediately which cannot be undone.
            </Typography>
            <PFMCheckbox
              label="I understand what I am doing"
              value={proceed}
              onUpdate={(v) => setProceed(v)}
            />
          </Stack>
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              onClick={() => props.closeHandler(false)}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              No
            </Button>
            <Button
              disabled={!proceed}
              onClick={() => props.closeHandler(true)}
              size="large"
              fullWidth
              variant="contained"
              color="error"
            >
              Cancel Subscription
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
