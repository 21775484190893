import React, { useEffect, useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aHideSidebar,
  aProfile,
} from "../../core/states";
import { IC_CLOSE } from "../../assets";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import { Subscription } from "@pfm/types";
import { RestBrowseSubscriptions } from "../../core/rest";
import BuySubscriptionCard from "../../components/BuySubscriptionCard";

export default function SectionUserProducts() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setHideSidebar] = useRecoilState(aHideSidebar);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const { showModal } = useModal();

  const [busy, setBusy] = useState(false);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  async function loadProducts() {
    try {
      setBusy(true);
      const _subs = await RestBrowseSubscriptions();
      setSubscriptions(_subs);
    } catch (err: any) {
      enqueueSnackbar("Error loading subscriptions. Please try again.", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection("");
    setSectionDescription("");
    loadProducts();
    setHideSidebar(true);
    return () => setHideSidebar(false);
  }, []);

  return (
    <Stack sx={{ p: "22px" }} spacing="8px" flex={1}>
      <Stack
        sx={{ px: "10px" }}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack>
          <Typography fontSize={30} fontWeight={600}>
            Buy Products
          </Typography>
          <Typography fontSize={16} fontWeight={400} color="#94969C">
            Browse the collection of our high-tech trading tools.
          </Typography>
        </Stack>
        <IconButton onClick={() => window.history.back()}>
          <IC_CLOSE stroke="white" />
        </IconButton>
      </Stack>
      <Stack
        flex={1}
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {subscriptions.map((s) => (
          <BuySubscriptionCard subscription={s} />
        ))}
      </Stack>
    </Stack>
  );
}
