import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_USER_CHECK } from "../assets";
import IconContainer from "../components/IconContainer";
import { User } from "@pfm/types";
import { enqueueSnackbar } from "notistack";
import { RestSetImpersonationId } from "../api/admin";

export default function ImpersonateUserDialog(props: DialogProps<User>) {
  const [busy, setBusy] = useState(false);

  async function signin() {
    try {
      setBusy(true);
      await RestSetImpersonationId(props.data?.uid!);
      // Reload
      window.location.reload();
    } catch (err: any) {
      enqueueSnackbar("Error processing request. Please try again. ", {
        variant: "error",
      });
      console.error(err);
    }
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          <Stack sx={{ width: "100%", px: "24px" }} spacing="8px">
            <IconContainer>
              <IC_USER_CHECK />
            </IconContainer>
            <Typography fontSize={18} fontWeight={600}>
              Impersonate: {props.data?.name} ({props.data?.email!})
            </Typography>
            <Typography color="#94969C" fontSize={14} fontWeight={400}>
              The impersonation session will allow you to browse the dashboard
              as User using an admin token. Please note that some of the
              functions might not work as expected.
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={() => props.closeHandler(false)}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={signin}
              disabled={busy}
              size="large"
              fullWidth
              variant="contained"
              color="warning"
            >
              Sign In
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
