import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PFMInput from "../../components/PFMInput";
import PFMCheckbox from "../../components/PFMCheckbox";
import {
  ELLIPTICAL_GLOW,
  HAND_DRAWING,
  IC_EMAIL,
  IC_NAME,
  IC_PASSWORD,
  IC_SHOW_PASSWORD,
  LOGO_VERTICAL,
} from "../../assets";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate } from "react-router";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { FbAuth } from "../../authentication/firebase";
import { enqueueSnackbar } from "notistack";
import LoadingOverlay from "../../components/LoadingOverlay";
import { useModal } from "mui-modal-provider";
import SignupAgreementDialog from "../../dialogs/signup_agreement";
import { RestPostSignup } from "../../core/rest";
import { getCookie, setCookie } from "../../core/helper";

export default function PageSignup() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [referral, setReferral] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const [created, setCreated] = useState(false);
  const [passwordHasEnoughCharacters, setPasswordHasEnoughCharacters] =
    useState(false);
  const [passwordHasSymbol, setPasswordHasSymbol] = useState(false);
  const desktop = useMediaQuery("(min-width:720px)");

  const theme = useTheme();
  const navigate = useNavigate();
  const { showModal } = useModal();
  const loc = useLocation(); // We get the referral code and save it in storage in case user does not type it.
  const parms = new URLSearchParams(loc.search);
  const _referrerCode = parms.get("ref")?.toString() || getCookie("referrer");
  if (_referrerCode) {
    // We save it in cookies/storage
    setCookie("referrer", _referrerCode, 7);
  }

  async function signup(fe: any) {
    fe?.preventDefault?.();
    setError("");
    // Make sure all fields are valid...
    if (!name) {
      setError("name");
      return;
    }
    if (!email) {
      setError("email");
      return;
    }
    if (!password || !passwordHasEnoughCharacters || !passwordHasSymbol) {
      setError("password");
      return;
    }

    showSignupDialog();
  }

  async function continueSignup(signature: any) {
    setBusy(false);
    try {
      setBusy(true);
      console.log(signature);
      // Create account on firebase.
      const acc = await createUserWithEmailAndPassword(FbAuth, email, password);
      if (acc.user) {
        // Update the user id
        await updateProfile(acc.user, {
          displayName: name,
        });
        await RestPostSignup(signature, referral || _referrerCode);
        // Prompt for verification.
        await signOut(FbAuth);
        setCreated(true);
        enqueueSnackbar("Please check your email to confirm your account.");
      }
    } catch (err: any) {
      if (err.code) {
        if (err.code === "auth/weak-password") {
          enqueueSnackbar("Password must be atleast 6 characters long.", {
            variant: "error",
          });
        } else if (err.code === "auth/email-already-in-use") {
          // 2. Try signing in. If we succeed, we then re-send email verification. Otherwise, we login the user.
          try {
            const creds = await signInWithEmailAndPassword(
              FbAuth,
              email,
              password
            );
            if (creds && creds.user.emailVerified) {
              // Login
              navigate("/portal");
            } else if (creds && !creds.user.emailVerified) {
              // Send verification email
              await RestPostSignup(signature, referral || _referrerCode);
              signOut(FbAuth);
              setCreated(true);
              enqueueSnackbar(
                "Please check your email to confirm your account."
              );
            }
          } catch (err: any) {
            // Invalid password?
            signOut(FbAuth);
            enqueueSnackbar(
              "Email is already associated with another account.",
              { variant: "error" }
            );
          }
        } else {
          enqueueSnackbar(
            "Error signing up. Please try again or contact administrators.",
            { variant: "error" }
          );
        }
      } else {
        enqueueSnackbar(
          "Unknown error signing up. Please try again or contact administrators.",
          { variant: "error" }
        );
      }
    }
    setBusy(false);
  }

  async function showSignupDialog() {
    const modal = showModal(SignupAgreementDialog, {
      closeHandler(result?: any) {
        modal.destroy();
        if (result && result.data) {
          continueSignup(result.data);
        }
      },
    });
  }

  useEffect(() => {
    if (FbAuth.currentUser) {
      // Navigate to /
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    const symPat = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@#]/;
    setPasswordHasSymbol(symPat.test(password));
    setPasswordHasEnoughCharacters(password.length >= 8);
  }, [password]);

  return (
    <Stack sx={{ height: "100vh" }}>
      <LoadingOverlay
        busy={busy}
        loadingText="Creating account, please wait..."
      />
      <img
        style={{
          position: desktop ? "fixed" : "initial",
          top: "64px",
          left: "80px",
          pointerEvents: "none",
          height: "80px",
          objectFit: "contain",
          margin: desktop ? undefined : "28px",
        }}
        alt="PFM Logo"
        src={LOGO_VERTICAL}
      />
      <img
        style={{
          position: "fixed",
          zIndex: -1,
          left: "0px",
          bottom: "0px",
          pointerEvents: "none",
          height: desktop ? undefined : "50px",
        }}
        alt="Hand Drawing"
        src={HAND_DRAWING}
      />
      <img
        style={{
          position: "fixed",
          right: "0px",
          top: "0px",
          pointerEvents: "none",
        }}
        alt="Elliptical Glow"
        src={ELLIPTICAL_GLOW}
      />
      <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
        <Stack alignItems={"center"} spacing="12px">
          <Typography
            fontSize={desktop ? "30px" : "20px"}
            fontWeight={"600"}
            sx={{ color: "#F5F5F6" }}
          >
            Create Your Account
          </Typography>
          <Typography
            sx={{ fontSize: desktop ? "16px" : "12px", color: "#94969C" }}
          >
            Ready to get started? Create your account now.
          </Typography>
        </Stack>
        <FormGroup>
          <Stack
            spacing="20px"
            sx={{
              my: "24px",
              width: desktop ? "360px" : "100%",
              px: desktop ? undefined : "24px",
            }}
          >
            <PFMInput
              text={name}
              label="Name"
              errorText={error === "name" ? "Please enter your name." : ""}
              onUpdate={(t) => setName(t)}
              important
              placeholder="Enter your full name"
              startIcon={IC_NAME}
            />
            <PFMInput
              text={email}
              errorText={error === "email" ? "Please enter your email." : ""}
              onUpdate={(t) => setEmail(t)}
              label="Email"
              important
              placeholder="Enter your email"
              startIcon={IC_EMAIL}
            />
            <PFMInput
              text={referral}
              onUpdate={(t) => setReferral(t)}
              label="Referral Code"
              placeholder="Enter your referrer's code"
              startIcon={IC_PASSWORD}
            />
            <PFMInput
              text={password}
              errorText={
                error === "password" ? "Please enter a valid password." : ""
              }
              onUpdate={(t) => setPassword(t)}
              label="Password"
              important
              password
              placeholder="Create a password"
              startIcon={IC_PASSWORD}
              onReturn={signup}
            />

            <Stack spacing={"2px"}>
              <PFMCheckbox
                value={passwordHasEnoughCharacters}
                label="Must be at least 8 characters"
              />
              <PFMCheckbox
                value={passwordHasSymbol}
                label="Must contain one special character"
              />
            </Stack>

            <Button sx={{ mt: "4px" }} onClick={signup} variant="contained">
              Get started
            </Button>
            <Stack
              justifyContent={"center"}
              direction={"row"}
              alignItems={"center"}
              sx={{ mt: "12px" }}
              spacing="8px"
            >
              <Typography color="#94969C" fontSize={"14px"}>
                Already have an account?
              </Typography>
              <Link href="/login" underline="none" fontSize={"14px"}>
                Login
              </Link>
            </Stack>
          </Stack>
        </FormGroup>
      </Stack>
    </Stack>
  );
}
