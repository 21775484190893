import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Link,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PFMInput from "../../components/PFMInput";
import {
  ELLIPTICAL_GLOW,
  HAND_DRAWING,
  IC_EMAIL,
  LOGO_VERTICAL,
} from "../../assets";
import { applyActionCode, sendPasswordResetEmail } from "firebase/auth";
import { FbAuth } from "../../authentication/firebase";
import { enqueueSnackbar } from "notistack";
import LoadingOverlay from "../../components/LoadingOverlay";
import { useLocation, useNavigate } from "react-router";
import { RestPostVerification } from "../../core/rest";

export default function PageVerification() {
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(false);
  const [busy, setBusy] = useState(false);
  const loc = useLocation();
  const code = loc.state?.code;
  const params = new URLSearchParams(loc.search);
  const email = loc.state?.email;
  const stamp = loc.state?.stamp;
  const navigate = useNavigate();
  const desktop = useMediaQuery("(min-width:720px)");

  async function verifyEmail() {
    try {
      setBusy(true);
      // Proceed.
      await applyActionCode(FbAuth, code!);
      // Send back notification
      await RestPostVerification(email!, parseInt(stamp!));
      // Send verification email.
      enqueueSnackbar("Your account has been confirmed.", {
        variant: "success",
      });
      // IF continue url is set, we navigatte.
      setVerified(true);
      setTimeout(() => {
        if (params.has("continue_url")) {
          navigate(params.get("continue_url") || "/login");
        }
      }, 2000);
    } catch (err: any) {
      enqueueSnackbar(
        "Error verifying your account. Please contact administrators.",
        {
          variant: "error",
        }
      );
      setError(true);
    }
    setBusy(false);
  }

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <Stack sx={{ height: "100vh" }}>
      <LoadingOverlay busy={busy} />
      <img
        style={{
          position: desktop ? "fixed" : "initial",
          top: "64px",
          left: "80px",
          pointerEvents: "none",
          height: "80px",
          objectFit: "contain",
          margin: desktop ? undefined : "28px",
        }}
        alt="PFM Logo"
        src={LOGO_VERTICAL}
      />
      <img
        style={{
          position: "fixed",
          left: "0px",
          bottom: "0px",
          height: desktop ? undefined : "50px",
          pointerEvents: "none",
          zIndex: -1,
        }}
        alt="Hand Drawing"
        src={HAND_DRAWING}
      />
      <img
        style={{
          position: "fixed",
          right: "0px",
          top: "0px",
          pointerEvents: "none",
        }}
        alt="Elliptical Glow"
        src={ELLIPTICAL_GLOW}
      />
      {/* Shown when the link has been sent */}
      {verified && (
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
          <Stack alignItems={"center"} spacing="12px">
            <SvgIcon sx={{ fontSize: "96px" }}>
              <IC_EMAIL />
            </SvgIcon>
            <Typography
              fontSize={desktop ? "30px" : "20px"}
              fontWeight={"600"}
              sx={{ color: "#F5F5F6" }}
            >
              Account verified!
            </Typography>
            <Typography
              textAlign={"center"}
              sx={{ fontSize: desktop ? "16px" : "12px", color: "#94969C" }}
            >
              Your account has been verified. You can now login.
            </Typography>
          </Stack>
          <FormGroup>
            <Stack
              spacing="20px"
              sx={{
                mt: "24px",
                width: desktop ? "360px" : "100%",
                px: desktop ? undefined : "24px",
              }}
            >
              <Stack
                justifyContent={"start"}
                direction={"row"}
                alignItems={"center"}
                sx={{ mt: "12px" }}
                spacing="8px"
              >
                <Link href="/login" underline="none" fontSize={"14px"}>
                  &lt;- Back to log in
                </Link>
              </Stack>
            </Stack>
          </FormGroup>
        </Stack>
      )}
      {/* Shown when the link has not yet been sent  */}
      {!verified && !error && (
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
          <Stack alignItems={"center"} spacing="12px">
            <Typography
              fontSize={desktop ? "30px" : "20px"}
              fontWeight={"600"}
              sx={{ color: "#F5F5F6" }}
            >
              Verying email
            </Typography>
            <Typography
              textAlign={"center"}
              sx={{ fontSize: desktop ? "16px" : "12px", color: "#94969C" }}
            >
              Please wait while we confirm your email addresss...
            </Typography>
          </Stack>
        </Stack>
      )}
      {error && !verified && (
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
          <Stack alignItems={"center"} spacing="12px">
            <Typography
              fontSize={desktop ? "30px" : "20px"}
              fontWeight={"600"}
              sx={{ color: "#F5F5F6" }}
            >
              Error verying email
            </Typography>
            <Typography
              textAlign={"center"}
              sx={{ fontSize: desktop ? "16px" : "12px", color: "#94969C" }}
            >
              We were unable to verify your email address. Please try again.
            </Typography>
          </Stack>
          <FormGroup>
            <Stack
              spacing="20px"
              sx={{
                mt: "24px",
                width: desktop ? "360px" : "100%",
                px: desktop ? undefined : "24px",
              }}
            >
              <Stack
                justifyContent={"start"}
                direction={"row"}
                alignItems={"center"}
                sx={{ mt: "12px" }}
                spacing="8px"
              >
                <Link href="/login" underline="none" fontSize={"14px"}>
                  &lt;- Back to log in
                </Link>
              </Stack>
            </Stack>
          </FormGroup>
        </Stack>
      )}
    </Stack>
  );
}
