import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import {
  IC_ADD,
  IC_ADD_IMAGE,
  IC_PEN,
  IC_CLOSE,
  IC_HELP,
  IC_SUPPORT,
} from "../assets";
import IconContainer from "../components/IconContainer";
import { enqueueSnackbar } from "notistack";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { FbAuth, FbStorage } from "../authentication/firebase";
import { RestAddProduct, RestEditProduct } from "../core/rest";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import DeleteProductDialog from "./DeleteProduct";
import ManageProductMediaDialog from "./ProductMedia";
import ManageProductConfigDialog from "./ManageProductConfig";

export default function EditProductDialog(props: DialogProps<any>) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [metadata, setMetadata] = useState<{ key: string; value: string }[]>(
    []
  );

  // File upload
  const [uploadInfo, setUploadInfo] = useState<string>();
  const [uploadState, setUploadState] = useState<
    "uploading" | "uploaded" | "error"
  >();
  const [uploadProgress, setUploadProgress] = useState(0);
  const { showModal } = useModal();

  const [busy, setBusy] = useState(false);

  async function uploadAttachment(attachment: File) {
    try {
      setBusy(true);
      const r = ref(
        FbStorage,
        "/uploads/" + FbAuth.currentUser?.uid! + "/" + attachment.name
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          uid: FbAuth.currentUser!.uid,
        },
      });
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setUploadInfo(url);
          enqueueSnackbar("File uploaded successfully.", {
            variant: "success",
          });
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
    }
  }

  async function deleteProduct() {
    const modal = showModal(DeleteProductDialog, {
      data: props.data,
      closeHandler(resukt) {
        modal.destroy();
        if (resukt) {
          props.closeHandler();
        }
      },
    });
  }

  async function updateProduct() {
    try {
      // Validate
      if (!name) {
        enqueueSnackbar("Please enter a product name.", { variant: "error" });
        return;
      }
      for (let md of metadata) {
        if (!md.key || !md.value) {
          enqueueSnackbar(
            "Please remove empty metadata entries, or enter text.",
            { variant: "error" }
          );
          return;
        }
      }
      setBusy(true);
      // Post
      const _prod = await RestEditProduct({
        id: props.data.id,
        description: description,
        image: uploadInfo,
        metadata: metadata,
        name: name,
      });

      if (_prod) {
        enqueueSnackbar("Product updated successfully.", {
          variant: "success",
        });
        props.closeHandler();
      }
    } catch (err: any) {
      enqueueSnackbar("Error updating product. Error: " + err.message, {
        variant: "error",
      });
    }
    setBusy(false);
  }

  function showMediaDialog() {
    const modal = showModal(ManageProductMediaDialog, {
      data: props.data,
      closeHandler(resukt) {
        modal.destroy();
      },
    });
  }

  function showConfigurationDialog() {
    const modal = showModal(ManageProductConfigDialog, {
      data: props.data,
      closeHandler(resukt) {
        modal.destroy();
      },
    });
  }

  useEffect(() => {
    setName(props.data.name);
    setDescription(props.data.description);
    setUploadInfo(props.data.images[0]);
    // Re-construct metadata
    const kv = Object.entries(props.data.metadata);
    const _md = [];
    for (let _kv of kv) {
      // Add all kv's.
      // Note: We ignore createdByName, createdByUid because we show it below as text
      if (
        _kv[0] === "createdByName" ||
        _kv[0] === "createdByUid" ||
        _kv[0] === "modifiedByName" ||
        _kv[0] === "modifiedByUid" ||
        _kv[0] === "pfm" ||
        _kv[0] === "images" ||
        _kv[0] === "videos"
      )
        continue;
      // Add to metadata
      _md.push({ key: _kv[0], value: _kv[1] });
    }
    setMetadata(_md as any);
  }, []);

  function RenderMetadata(index: number, key: string, value: string) {
    return (
      <Stack direction={"row"} spacing="12px" alignItems={"center"}>
        <PFMInput
          placeholder="Key"
          fullWidth
          text={key}
          onUpdate={(t) => {
            // Update key
            metadata[index].key = t;
            setMetadata([...metadata]);
          }}
        />
        <PFMInput
          placeholder="Value"
          fullWidth
          text={value}
          onUpdate={(t) => {
            // Update key
            metadata[index].value = t;
            setMetadata([...metadata]);
          }}
        />
        <IconButton
          size="small"
          sx={{ width: "40px", height: "40px" }}
          onClick={() => {
            // Delete
            setMetadata([
              ...metadata.slice(0, index),
              ...metadata.slice(index + 1),
            ]);
          }}
        >
          <IC_CLOSE stroke="#94969C" />
        </IconButton>
      </Stack>
    );
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
      maxWidth={"md"}
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_PEN />
            </IconContainer>
            <Stack>
              <Stack direction={"row"} alignItems={"center"} spacing="8px">
                <Typography fontSize={18} fontWeight={600}>
                  Edit Product
                </Typography>
                <Tooltip title="Click to copy product id.">
                  <Chip
                    onClick={() => navigator.clipboard.writeText(props.data.id)}
                    size="small"
                    label={props.data.id}
                    variant="identifier"
                  />
                </Tooltip>
              </Stack>
              <Typography fontSize={14} fontWeight={400}>
                Update product description and look.
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Product Name
              </Typography>
              <PFMInput
                text={name}
                disabled={busy}
                onUpdate={(t) => setName(t)}
                fullWidth
                placeholder="Profit Flow 1"
              />
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Product Description
              </Typography>
              <PFMInput
                disabled={busy}
                text={description}
                onUpdate={(t) => setDescription(t)}
                multiline
                rows={4}
                fullWidth
                placeholder="Elevate your Prop Firms business with our Profit Flow 1.1."
              />
            </Stack>
            <input
              id="file-upload"
              type="file"
              style={{ opacity: 0, position: "absolute", zIndex: "-9999" }}
              onChange={(fe) => {
                uploadAttachment(fe.target?.files?.[0]!);
              }}
            />
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Product Image
              </Typography>
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                spacing="20px"
                alignItems={"center"}
              >
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    sx={{ width: "64px", height: "64px" }}
                    src={uploadInfo}
                  >
                    <IC_ADD_IMAGE />
                  </Avatar>
                  {uploadState === "uploading" && (
                    <CircularProgress
                      variant="determinate"
                      value={uploadProgress}
                      size="68px"
                      sx={{ position: "absolute" }}
                    />
                  )}
                </div>
                <Stack
                  onClick={(c) => {
                    if (busy) return;
                    document.getElementById("file-upload")?.click();
                  }}
                  flex={1}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{
                    background: "#182230",
                    p: "16px",
                    borderRadius: "12px",
                    cursor: "pointer",
                  }}
                >
                  <Typography fontSize={14} fontWeight={600} color="#CECFD2">
                    Click to upload
                  </Typography>
                  <Typography fontSize={12} fontWeight={400} color="#94969C">
                    PNG, JPG (max. 800x400)
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Stack
                direction={"row"}
                sx={{
                  maxWidth: "220px",
                  minWidth: "150px",
                }}
                spacing={"8px"}
              >
                <Typography fontSize={14} fontWeight={500}>
                  Metadata
                </Typography>
                <Tooltip title="Add extra key-value pairs to the product. Can be used for comments and extra reasoning.">
                  <IC_HELP />
                </Tooltip>
              </Stack>
              <Stack
                flex={1}
                spacing="12px"
                sx={{ width: "100%" }}
                key={metadata as any}
              >
                {metadata.map((kv, ind) =>
                  RenderMetadata(ind, kv.key, kv.value)
                )}
                <Button
                  disabled={busy}
                  onClick={() =>
                    setMetadata([...metadata, { key: "", value: "" }])
                  }
                  sx={{ alignSelf: "start" }}
                  startIcon={<IC_ADD />}
                >
                  Add metadata
                </Button>
              </Stack>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Manage product
              </Typography>
              <Stack
                direction={"row"}
                sx={{ width: "100%", px: "32px" }}
                spacing="12px"
              >
                <Button
                  disabled={busy}
                  size="small"
                  onClick={showConfigurationDialog}
                  fullWidth
                  variant="contained"
                  color="secondary"
                >
                  Configuration Template
                </Button>
                <Button
                  disabled={busy}
                  size="small"
                  onClick={showMediaDialog}
                  fullWidth
                  variant="contained"
                  color="secondary"
                >
                  Manage Media
                </Button>
                <Button
                  disabled={busy}
                  size="small"
                  onClick={deleteProduct}
                  fullWidth
                  variant="contained"
                  color="error"
                >
                  Delete Product
                </Button>
              </Stack>
            </Stack>
            <Divider />
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={20}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Activity
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Last modified
              </Typography>
              <Typography fontSize={14} color="#94969C">
                {moment
                  .unix(props.data?.updated || props.data?.created)
                  .format("MMM DD, YYYY HH:mm:ss")}{" "}
                {props.data.metadata.modifiedByUid
                  ? " by " + props.data.metadata.modifiedByName
                  : ""}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Created
              </Typography>
              <Typography fontSize={14} color="#94969C">
                {moment
                  .unix(props.data?.created!)
                  .format("MMM DD, YYYY HH:mm:ss")}
                {props.data.metadata.createdByUid
                  ? " by " + props.data.metadata.createdByName
                  : ""}
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={updateProduct}
              disabled={busy}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
            >
              Update
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
