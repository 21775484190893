import React, { useEffect, useState } from "react";
import { Badge, Stack, ToggleButton, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { aCurrentSection, aCurrentSectionDescription } from "../../core/states";
import { StyledToggleButtonGroup } from "../../components/ToggleButtonGroup";
import SectionSettingsGeneral from "../settings/general";
import SectionSettingsAdmin from "../settings/admin";

export default function SectionAdminSettings() {
  const [view, setView] = useState<"general" | "configuration">("general");
  const [section, setSection] = useRecoilState(aCurrentSection);
  const [sectionDescription, setSectionDescription] = useRecoilState(
    aCurrentSectionDescription
  );

  useEffect(() => {
    setSection(`Settings`);
    setSectionDescription(
      `View and manage referrees, payouts, and transactions for affiliate program.`
    );
  }, []);
  return (
    <Stack sx={{ p: "32px", flex: 1 }} spacing="12px">
      <StyledToggleButtonGroup
        sx={{ alignSelf: "flex-end" }}
        exclusive
        value={view}
        onChange={(c, e) => setView(e)}
      >
        <ToggleButton value={"general"}>
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
            spacing="16px"
            sx={{ px: "16px" }}
          >
            <Badge
              variant="dot"
              color={view === "general" ? "success" : undefined}
            ></Badge>
            <Typography>General</Typography>
          </Stack>
        </ToggleButton>
        <ToggleButton value={"configuration"}>
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
            spacing="16px"
            sx={{ px: "16px" }}
          >
            <Badge
              variant="dot"
              color={view === "configuration" ? "success" : undefined}
            ></Badge>
            <Typography>Configuration</Typography>
          </Stack>
        </ToggleButton>
      </StyledToggleButtonGroup>

      {view === "general" && (
        <>
          <SectionSettingsGeneral />
        </>
      )}
      {view === "configuration" && <SectionSettingsAdmin />}
    </Stack>
  );
}
