import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import { IC_ADD, IC_PEN, IC_CLOSE, IC_SETTINGS } from "../assets";
import IconContainer from "../components/IconContainer";
import { enqueueSnackbar } from "notistack";
import {
  ProductConfigTemplate,
  StripeProduct,
  TemplateField,
} from "@pfm/types";

import {
  Title as IC_TEXT_INPUT,
  CheckBox as IC_CHECKBOX,
  Description as IC_TEXT_AREA,
  List as IC_OPTIONS,
  Label as IC_LABEL,
  Password as IC_PASSWORD,
  FileUpload as IC_FILE,
  HorizontalRule as IC_DIVIDER,
} from "@mui/icons-material";

import PFMAutoComplete from "../components/PFMAutoComplete";
import PFMCheckbox from "../components/PFMCheckbox";
import {
  RestEditProductConfigTemplate,
  RestGetProductConfigTemplate,
  RestGetRecentTemplate,
} from "../core/rest";
import { useModal } from "mui-modal-provider";
import LoadRecentTemplateDialog from "./LoadRecentTemplate";

const FieldTypeOptions = [
  { label: "Text Input", id: "text_input" },
  { label: "Text Area", id: "text_area" },
  { label: "Checkbox", id: "checkbox" },
  { label: "Select", id: "option" },
  { label: "Multi-Select", id: "options" },
  { label: "Password", id: "hidden" },
  { label: "File Input", id: "file" },
  { label: "Divider", id: "divider" },
  { label: "Label", id: "label" },
];

export default function ManageProductConfigDialog(
  props: DialogProps<StripeProduct>
) {
  const [template, setTemplate] = useState<Partial<ProductConfigTemplate>>();
  const [templateFields, setTemplateFields] = useState<TemplateField[]>([]);
  const [selectedField, setSelectedField] = useState<number>(-1);
  const [selectedFieldKind, setSelectedFieldKind] = useState<any>();

  const [busy, setBusy] = useState(false);
  const { showModal } = useModal();

  async function saveTemplate() {
    try {
      // Update
      setBusy(true);
      const _temp = template;
      _temp!.fields = templateFields;
      await RestEditProductConfigTemplate(props.data?.id!, _temp!);
      enqueueSnackbar("Product configuration template has been updated.", {
        variant: "success",
      });
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar(
        "Error updating product configuration template. Error: " + err.message,
        {
          variant: "error",
        }
      );
    }
    setBusy(false);
  }

  function addNewField() {
    // Append the field
    setTemplateFields([
      ...templateFields,
      { type: "text_input", id: "field_" + (templateFields.length + 1) },
    ]);
  }

  function removeField(ind: number) {
    const fields = templateFields.filter((f, i) => i !== ind);
    setTemplateFields(fields);
    if (selectedField === ind) {
      setSelectedField(-1);
    }
  }

  async function loadTemplate() {
    try {
      setBusy(true);
      const temp = await RestGetProductConfigTemplate(props.data?.id!);
      if (temp && temp.fields) {
        setTemplate(temp);
        setTemplateFields(temp.fields || []);
      } else {
        const _temp = await RestGetRecentTemplate();
        if (_temp && _temp.fields && _temp.fields.length > 0) {
          // Ask for loading the tmepate.
          const modal = showModal(LoadRecentTemplateDialog, {
            async closeHandler(result) {
              if (result) {
                // Load recent templawlte
                try {
                  if (_temp) {
                    setTemplate(_temp);
                    setTemplateFields(_temp.fields);
                  } else {
                    setTemplate({});
                    setTemplateFields([]);
                  }
                } catch (err: any) {
                  enqueueSnackbar("Error loading template. ", {
                    variant: "error",
                  });
                }
              } else {
                setTemplate({});
                setTemplateFields([]);
              }
              modal.destroy();
            },
          });
        } else {
          setTemplate({});
          setTemplateFields([]);
        }
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading template. Please try again. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  useEffect(() => {
    loadTemplate();
  }, []);

  function RenderFieldIcon(f: TemplateField) {
    switch (f.type) {
      case "checkbox": {
        return <IC_CHECKBOX />;
      }
      case "label":
        return <IC_LABEL />;
      case "option":
      case "options":
        return <IC_OPTIONS />;
      case "text_area":
        return <IC_TEXT_AREA />;
      case "text_input":
        return <IC_TEXT_INPUT />;
      case "hidden":
        return <IC_PASSWORD />;
      case "file":
        return <IC_FILE />;
      case "divider":
        return <IC_DIVIDER />;
    }
  }

  function RenderField(index: number, field: TemplateField) {
    return (
      <Paper
        sx={{
          height: "40px",
          background: index === selectedField ? "#4488FFFF" : "#4488FF44",
          borderRadius: "8px",
          px: "10px",
          transition: "all .2s",
          cursor: "pointer",
          ":hover": {
            background: index === selectedField ? "#4488FFFF" : "#4488FFAA",
          },
        }}
        elevation={0}
        onClick={() => setSelectedField(index)}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing="8px"
          sx={{ height: "100%" }}
        >
          {/* icon of the field  */}
          <RenderFieldIcon {...field} />
          {/* label of the field  */}
          <Typography>{field.label || "No Label"}</Typography>
          {/* id of the field  */}
          <Typography
            sx={{
              fontWeight: 400,
              color: "#84CAFF",
              background: "#102A56",
              px: "8px",
              borderRadius: "25px",
              border: "1px solid #1849A9",
            }}
          >
            {field.id}
          </Typography>

          {field.required && (
            <Typography
              sx={{
                fontWeight: 400,
                color: "#FAA7E0",
                background: "#4E0D30",
                border: "1px solid #9E165F",
                px: "8px",
                borderRadius: "25px",
              }}
            >
              required
            </Typography>
          )}
          <div style={{ flex: 1 }} />
          {/* delete icon  */}
          <Typography
            sx={{
              fontWeight: 400,
              color: "#84CAFF",
              background: "#102A56",
              px: "8px",
              borderRadius: "25px",
              border: "1px solid #1849A9",
            }}
          >
            {field.type}
          </Typography>
          <Tooltip title="Delete field">
            <IconButton
              sx={{ width: "30px", height: "30px" }}
              onClick={(e) => {
                removeField(index);
                e.stopPropagation();
              }}
            >
              <IC_CLOSE stroke="white" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Paper>
    );
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullScreen
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px", height: "100%" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_SETTINGS fill="white" />
            </IconContainer>
            <Stack>
              <Stack direction={"row"} alignItems={"center"} spacing="8px">
                <Typography fontSize={18} fontWeight={600}>
                  Edit Configuration Template
                </Typography>
              </Stack>
              <Typography fontSize={14} fontWeight={400}>
                View or edit product's configuration template to aquire product
                config from users.
              </Typography>
            </Stack>
          </Stack>
          <Divider />

          <Stack direction={"row"} flex={1} sx={{ px: "24px" }} spacing="18px">
            {/* Contains the current fields of the template, and add button  */}
            <Stack flex={1} spacing="8px">
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography color="#94969C">Template Fields</Typography>
                <Button
                  disabled={busy}
                  onClick={addNewField}
                  color="secondary"
                  variant="contained"
                  startIcon={<IC_ADD />}
                >
                  Add field
                </Button>
              </Stack>
              <Stack spacing="8px">
                {templateFields?.map((f, i) => RenderField(i, f))}
              </Stack>
            </Stack>

            {/* Contains the property editor  */}
            <Stack sx={{ width: "350px" }} spacing="8px">
              <Typography color="#94969C">Field Properties</Typography>
              {selectedField > -1 && (
                <Stack spacing="12px">
                  <PFMInput
                    label="Field ID"
                    important
                    placeholder="Unique Field ID"
                    text={templateFields?.[selectedField]?.id}
                    onUpdate={(t) => {
                      templateFields[selectedField].id = t;
                      setTemplateFields([...templateFields]);
                    }}
                  />
                  <PFMInput
                    label="Field Label"
                    important
                    placeholder="Label"
                    text={templateFields?.[selectedField]?.label}
                    onUpdate={(t) => {
                      templateFields[selectedField].label = t;
                      setTemplateFields([...templateFields]);
                    }}
                  />
                  <PFMAutoComplete
                    label="Field Type"
                    important
                    multiple={false}
                    onUpdate={(v: any) => {
                      setSelectedFieldKind(v);
                      templateFields[selectedField].type = v.id;
                      setTemplateFields([...templateFields]);
                    }}
                    value={selectedFieldKind}
                    options={FieldTypeOptions}
                  />

                  {/* For options, we show a text area */}
                  {(templateFields?.[selectedField]?.type === "option" ||
                    templateFields?.[selectedField]?.type === "options") && (
                    <PFMInput
                      label="Options"
                      placeholder="Separate each item on new line..."
                      multiline
                      rows={4}
                      text={templateFields?.[selectedField]?.options?.reduce(
                        (pv, cv, ci) => (pv ? pv + "\n" : "") + cv,
                        ""
                      )}
                      onUpdate={(t) => {
                        templateFields[selectedField].options = t.split("\n");
                        setTemplateFields([...templateFields]);
                      }}
                    />
                  )}

                  {/* If field type is text input, text area, select, multi-select, password, we add placeholder option  */}
                  {(templateFields?.[selectedField]?.type === "option" ||
                    templateFields?.[selectedField]?.type === "options" ||
                    templateFields?.[selectedField]?.type === "text_area" ||
                    templateFields?.[selectedField]?.type === "text_input" ||
                    templateFields?.[selectedField]?.type === "hidden") && (
                    <PFMInput
                      placeholder="Placeholder.."
                      label="Placeholder"
                      text={templateFields?.[selectedField]?.placeholder}
                      onUpdate={(t) => {
                        templateFields[selectedField].placeholder = t;
                        setTemplateFields([...templateFields]);
                      }}
                    />
                  )}

                  {templateFields?.[selectedField]?.type !== "divider" &&
                    templateFields?.[selectedField]?.type !== "label" &&
                    templateFields?.[selectedField]?.type !== "checkbox" && (
                      <PFMInput
                        placeholder="Hint text.."
                        label="Hint Text"
                        text={templateFields?.[selectedField]?.hint}
                        onUpdate={(t) => {
                          templateFields[selectedField].hint = t;
                          setTemplateFields([...templateFields]);
                        }}
                      />
                    )}

                  {templateFields?.[selectedField]?.type !== "divider" &&
                    templateFields?.[selectedField]?.type !== "label" &&
                    templateFields?.[selectedField]?.type !== "checkbox" && (
                      <PFMCheckbox
                        key={selectedField}
                        label="Field is required"
                        value={
                          templateFields?.[selectedField]?.required
                            ? true
                            : false
                        }
                        onUpdate={(t) => {
                          templateFields[selectedField].required = t;
                          setTemplateFields([...templateFields]);
                        }}
                      />
                    )}
                </Stack>
              )}
            </Stack>
          </Stack>

          <Divider />
          {/* The dialog footer / buttons  */}
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={saveTemplate}
              disabled={busy}
              size="large"
              variant="contained"
              color="primary"
            >
              Update
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
