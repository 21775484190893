import { atom } from "recoil";
import { User } from "@pfm/types";
import { IdTokenResult } from "firebase/auth";

export const aCurrentSection = atom({
  key: "pfm_current_section",
  default: "Dashboard",
});
export const aCurrentSectionDescription = atom({
  key: "pfm_current_section_desc",
  default: "Welcome",
});

export const aProfile = atom<User | null>({
  key: "pfm_profile",
  default: null,
});

export const aToken = atom<IdTokenResult | null>({
  key: "pfm_profile_token",
  default: null,
});

export const aHideSidebar = atom<boolean>({
  default: false,
  key: "pfm_hide_sidebar",
});
