import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import { IC_ADD_USER, IC_USER_ROUNDED_ } from "../assets";
import IconContainer from "../components/IconContainer";
import PFMAutoComplete from "../components/PFMAutoComplete";
import { enqueueSnackbar } from "notistack";
import { RestAddUser, RestDisableUser, RestEditUser } from "../core/rest";
import { User } from "@pfm/types";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import DisableUserDialog from "./DisableUserDialog";
import EnableUserDialog from "./EnableUserDialog";
import SuspendUserDialog from "./SuspendUser";

export default function EditUserDialog(props: DialogProps<User>) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");
  const [role, setRole] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [busy, setBusy] = useState(false);

  const { showModal } = useModal();

  async function updateUser() {
    try {
      setBusy(true);
      await RestEditUser({
        uid: props.data?.uid,
        name: name,
        email: email,
        role: role as any,
        comments: comments,
      });
      enqueueSnackbar(`User updated successfully.`, {
        variant: "success",
      });
    } catch (err: any) {
      enqueueSnackbar("Error updating user. Error: " + err.message, {
        variant: "error",
      });
    }
    setBusy(false);
  }

  function showDisableDialog() {
    const modal = showModal(DisableUserDialog, {
      data: props.data,
      closeHandler(resukt) {
        modal.destroy();
        if (resukt) {
          setDisabled(true);
        }
      },
    });
  }

  function showEnableDialog() {
    const modal = showModal(EnableUserDialog, {
      data: props.data,
      closeHandler(resukt) {
        modal.destroy();
        if (resukt) {
          setDisabled(false);
        }
      },
    });
  }

  function showSuspendDialog() {
    const modal = showModal(SuspendUserDialog, {
      data: props.data,
      closeHandler(resukt) {
        modal.destroy();
        if (resukt) {
          props.closeHandler();
        }
      },
    });
  }

  useEffect(() => {
    setName(props.data?.name || "");
    setEmail(props.data?.email || "");
    setComments(props.data?.comments || "");
    setRole(props.data?.role || "");
    setDisabled(props.data?.disabled || false);
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_USER_ROUNDED_ />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Edit User
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Update the user's name or role.
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            {props.data?.signature && (
              <Stack direction={"row"} sx={{ width: "100%" }}>
                <Typography
                  className="required"
                  fontSize={14}
                  fontWeight={500}
                  sx={{ minWidth: "150px" }}
                >
                  Signature
                </Typography>
                <img
                  src={`${props.data?.signature}`}
                  style={{ height: "70px" }}
                />
              </Stack>
            )}
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                User's Full Name
              </Typography>
              <PFMInput
                text={name}
                onUpdate={(t) => setName(t)}
                fullWidth
                disabled={busy}
                placeholder="John Doe"
              />
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Email Address
              </Typography>
              <PFMInput
                disabled
                text={email}
                fullWidth
                placeholder="someone@example.com"
              />
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Role
              </Typography>
              <PFMAutoComplete
                options={[{ label: "Administrator" }, { label: "User" }]}
                placeholder="Select a role.."
                onUpdate={(v) => setRole((v as any)?.label || "")}
                value={{ label: role } as any}
                disabled={busy}
              />
            </Stack>

            <Divider />
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Comments
              </Typography>
              <PFMInput
                onUpdate={(t) => setComments(t)}
                multiline
                disabled={busy}
                text={comments}
                rows={4}
                fullWidth
                placeholder="Add comments, reasons, or notes (optional)"
              />
            </Stack>
            <Divider />
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Manage account
              </Typography>
              <Stack
                direction={"row"}
                sx={{ width: "100%", px: "32px" }}
                spacing="12px"
              >
                {disabled && (
                  <Button
                    disabled={busy}
                    onClick={showEnableDialog}
                    size="small"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Enable
                  </Button>
                )}
                {!disabled && (
                  <Button
                    disabled={busy}
                    onClick={showDisableDialog}
                    size="small"
                    fullWidth
                    variant="contained"
                    color="secondary"
                  >
                    Disable
                  </Button>
                )}
                <Button
                  onClick={showSuspendDialog}
                  disabled={busy}
                  size="small"
                  fullWidth
                  variant="contained"
                  color="error"
                >
                  Delete
                </Button>
              </Stack>
            </Stack>
            <Divider />
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={20}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Activity
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Last modified
              </Typography>
              <Typography fontSize={14} color="#94969C">
                {moment
                  .unix(
                    props.data?.updatedTimestamp! ||
                      props.data?.registeredTimestamp!
                  )
                  .format("MMM DD, YYYY HH:mm:ss")}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.addedByUid ? "Added on" : "Registered on"}
              </Typography>
              <Typography fontSize={14} color="#94969C">
                {moment
                  .unix(props.data?.registeredTimestamp!)
                  .format("MMM DD, YYYY HH:mm:ss")}
              </Typography>
            </Stack>
            {props.data?.addedByUid && (
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent={"space-between"}
              >
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  sx={{ minWidth: "150px" }}
                >
                  Added by
                </Typography>
                <Tooltip title={props.data.addedByUid}>
                  <Typography fontSize={14} color="#94969C">
                    {props.data.addedByName}
                  </Typography>
                </Tooltip>
              </Stack>
            )}
          </Stack>

          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              disabled={busy}
              onClick={updateUser}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
