import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import SimpleInsightCard from "../../components/SimpleInsightCard";
import { StripeProduct, Subscription } from "@pfm/types";
import { enqueueSnackbar } from "notistack";
import {
  RestBrowseSubscriptions,
  RestListActiveSubscriptions,
} from "../../core/rest";
import DashboardProductCard from "../../components/DashboardProductCard";
import { useNavigate } from "react-router";
import BuySubscriptionCard from "../../components/BuySubscriptionCard";
import { IC_BAG_CHECKED, IC_BAG_OUTLINED } from "../../assets";

export default function SectionUserDashboard() {
  const [profile] = useRecoilState(aProfile);
  const [busy, setBusy] = useState(false);
  const [section, setSection] = useRecoilState(aCurrentSection);
  const [subscriptions, setSubscriptions] = useState<
    { info: Subscription; data: any }[]
  >([]);
  const [sectionDescription, setSectionDescription] = useRecoilState(
    aCurrentSectionDescription
  );

  const [products, setProducts] = useState<
    {
      product: StripeProduct;
      subscription: Subscription;
      subscriptionId: string;
    }[]
  >([]);
  const [availableSubscriptions, setAvailableSubscriptions] = useState<
    Subscription[]
  >([]);

  const theme = useTheme();
  const nav = useNavigate();
  const desktop = useMediaQuery("(min-width: 700px)");

  async function loadProducts() {
    try {
      setBusy(true);
      const _op = await RestListActiveSubscriptions();
      console.log(_op);
      setSubscriptions(_op);
      // Load products
      const prods: {
        product: StripeProduct;
        subscription: Subscription;
        subscriptionId: string;
      }[] = [];
      for (let sub of _op) {
        for (let p of sub.info.products) {
          // Add each product to the list
          prods.push({
            product: p,
            subscription: sub.info,
            subscriptionId: sub.data?.id,
          });
        }
      }
      setProducts(prods);
      // Load other available subscriptions
      const _subs = await RestBrowseSubscriptions();
      setAvailableSubscriptions(_subs);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again.", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`Dashboard`);
    setSectionDescription(`Welcome back, ${profile?.name}!`);
    loadProducts();
  }, []);

  return (
    <Stack sx={{ p: desktop ? "32px" : "24px" }}>
      <Grid
        spacing={"8px"}
        container
        sx={{ pt: desktop ? "32px" : "0px" }}
        columns={4}
        rowSpacing={desktop ? "32px" : "20px"}
      >
        <Grid item>
          <SimpleInsightCard
            accent="#2E90FA"
            title="Active Subscriptions"
            value={subscriptions.length.toString() || "0"}
          />
        </Grid>
        <Grid item>
          <SimpleInsightCard
            accent="#FF692E"
            title="Products Owned"
            value={products.length.toString() || "0"}
          />
        </Grid>
        {products.length > 0 && (
          <>
            <Grid item xs={4}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ width: "100%" }}
              >
                <Stack>
                  <Stack direction={"row"} alignItems={"center"} spacing="8px">
                    <IC_BAG_CHECKED />
                    <Typography fontSize={24} fontWeight={400}>
                      Owned Products
                    </Typography>
                  </Stack>
                  <Typography fontSize={14} fontWeight={400} color="#94969C">
                    List of products that you are currently own.
                  </Typography>
                </Stack>
              </Stack>
            </Grid>

            {products.map((p) => (
              <DashboardProductCard
                product={p.product}
                subscription={p.subscription}
                subscriptionId={p.subscriptionId}
              />
            ))}
          </>
        )}

        {availableSubscriptions.length > 0 && (
          <>
            <Grid item xs={4}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ width: "100%" }}
              >
                <Stack>
                  <Stack direction={"row"} alignItems={"center"} spacing="8px">
                    <IC_BAG_OUTLINED />
                    <Typography fontSize={24} fontWeight={400}>
                      Add Products
                    </Typography>
                  </Stack>
                  <Typography fontSize={14} fontWeight={400} color="#94969C">
                    Following are the subscriptions you can buy.
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack
                direction={"row"}
                columnGap={"12px"}
                rowGap={"12px"}
                flexWrap={"wrap"}
              >
                {availableSubscriptions.map((s) => (
                  <BuySubscriptionCard subscription={s} />
                ))}
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );
}
