import React, { ReactElement } from "react";
import {
  Button,
  Divider,
  Link,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

/**
 * Just like the insights card on dashboard, but more simplified.
 * @param props
 * @returns
 */
export default function SimpleInsightCard(props: {
  title?: string;
  value?: string;
  subheading?: any;
  accent?: string;
}) {
  const desktop = useMediaQuery("(min-width: 700px)");
  return (
    <Paper
      elevation={0}
      sx={{
        minHeight: desktop ? "120px" : "96px",
        minWidth: desktop ? "350px" : "200px",
        background: "#101828",
        borderTop: "4px solid " + props.accent,
        borderRadius: "12px",
        p: "24px",
      }}
    >
      <Stack spacing="8px">
        <Stack direction={"row"} alignItems={"center"} spacing="12px">
          <Typography
            fontSize={desktop ? 14 : 12}
            fontWeight={500}
            color="#94969C"
          >
            {props.title}
          </Typography>
        </Stack>
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <Typography fontSize={desktop ? 36 : 20} fontWeight={600}>
            {props.value}
          </Typography>
          <Typography
            fontSize={desktop ? 16 : 14}
            fontWeight={400}
            color="#85888E"
          >
            {props.subheading}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
}
