import { Zoom } from "react-slideshow-image";
import React from "react";
import { Stack, SxProps } from "@mui/material";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import ReactPlayer from "react-player";

export default function ProductSlideshow(props: {
  urls: string[];
  sx: SxProps;
  disableAnimation?: boolean;
  kind?: "videos" | "images";
}) {
  return (
    <Stack sx={props.sx}>
      <div
        className="slide-container"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <Slide infinite={false} autoplay={!props.disableAnimation}>
          {props.urls.map((slideImage, index) =>
            props.kind === "images" ? (
              <img
                src={slideImage}
                style={{ width: "100%", height: "400px", objectFit: "contain" }}
              />
            ) : (
              <ReactPlayer url={slideImage} width={"100%"} height={"400px"} />
            )
          )}
        </Slide>
      </div>
    </Stack>
  );
}
