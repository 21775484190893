import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import {
  IC_ADD,
  IC_ADD_IMAGE,
  IC_PEN,
  IC_CLOSE,
  IC_HELP,
  IC_CART_PLUS,
} from "../assets";
import IconContainer from "../components/IconContainer";
import { enqueueSnackbar } from "notistack";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { FbAuth, FbStorage } from "../authentication/firebase";
import { RestAddProduct, RestGetMailerliteGroups } from "../core/rest";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../components/PFMAutoComplete";

export default function AddProductDialog(props: DialogProps<any>) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [groups, setGroups] = useState<AutocompleteOption[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<AutocompleteOption>();
  const [metadata, setMetadata] = useState<{ key: string; value: string }[]>(
    []
  );

  // File upload
  const [uploadInfo, setUploadInfo] = useState<string>();
  const [uploadState, setUploadState] = useState<
    "uploading" | "uploaded" | "error"
  >();
  const [uploadProgress, setUploadProgress] = useState(0);

  const [busy, setBusy] = useState(false);

  async function uploadAttachment(attachment: File) {
    try {
      setBusy(true);
      const r = ref(
        FbStorage,
        "/uploads/" + FbAuth.currentUser?.uid! + "/" + attachment.name
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          uid: FbAuth.currentUser!.uid,
        },
      });
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setUploadInfo(url);
          enqueueSnackbar("File uploaded successfully.", {
            variant: "success",
          });
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
    }
  }

  async function addProduct() {
    try {
      // Validate
      if (!name) {
        enqueueSnackbar("Please enter a product name.", { variant: "error" });
        return;
      }
      for (let md of metadata) {
        if (!md.key || !md.value) {
          enqueueSnackbar(
            "Please remove empty metadata entries, or enter text.",
            { variant: "error" }
          );
          return;
        }
      }
      setBusy(true);
      if (selectedGroup) {
        metadata.push({ key: "mailerlite_group", value: selectedGroup.id });
      }
      // Post
      const _prod = await RestAddProduct({
        description: description,
        image: uploadInfo,
        metadata: metadata,
        name: name,
      });

      if (_prod) {
        enqueueSnackbar("Product added successfully.", { variant: "success" });
        props.closeHandler();
      }
    } catch (err: any) {
      enqueueSnackbar("Error adding a new product. Error: " + err.message, {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function loadMlGroups() {
    try {
      const oos: AutocompleteOption[] = [];
      const gs = await RestGetMailerliteGroups();
      for (let g of gs) {
        oos.push({
          label: g.name,
          id: g.id,
        });
      }
      setGroups(oos);
    } catch (err: any) {
      enqueueSnackbar("Error loading mailerlite groups.", { variant: "error" });
    }
  }

  function RenderMetadata(index: number, key: string, value: string) {
    return (
      <Stack direction={"row"} spacing="12px" alignItems={"center"}>
        <PFMInput
          placeholder="Key"
          fullWidth
          text={key}
          onUpdate={(t) => {
            // Update key
            metadata[index].key = t;
            setMetadata([...metadata]);
          }}
        />
        <PFMInput
          placeholder="Value"
          fullWidth
          text={value}
          onUpdate={(t) => {
            // Update key
            metadata[index].value = t;
            setMetadata([...metadata]);
          }}
        />
        <IconButton
          size="small"
          sx={{ width: "40px", height: "40px" }}
          onClick={() => {
            // Delete
            setMetadata([
              ...metadata.slice(0, index),
              ...metadata.slice(index + 1),
            ]);
          }}
        >
          <IC_CLOSE stroke="#94969C" />
        </IconButton>
      </Stack>
    );
  }

  useEffect(() => {
    loadMlGroups();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
      maxWidth={"md"}
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_CART_PLUS />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Add Product
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Create your company profile for free in less than 5 minutes.
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Product Name
              </Typography>
              <PFMInput
                text={name}
                disabled={busy}
                onUpdate={(t) => setName(t)}
                fullWidth
                placeholder="Profit Flow 1"
              />
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Product Description
              </Typography>
              <PFMInput
                disabled={busy}
                text={description}
                onUpdate={(t) => setDescription(t)}
                multiline
                rows={4}
                fullWidth
                placeholder="Elevate your Prop Firms business with our Profit Flow 1.1."
              />
            </Stack>
            <input
              id="file-upload"
              type="file"
              style={{ opacity: 0, position: "absolute", zIndex: "-9999" }}
              onChange={(fe) => {
                uploadAttachment(fe.target?.files?.[0]!);
              }}
            />
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Product Image
              </Typography>
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                spacing="20px"
                alignItems={"center"}
              >
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    sx={{ width: "64px", height: "64px" }}
                    src={uploadInfo}
                  >
                    <IC_ADD_IMAGE />
                  </Avatar>
                  {uploadState === "uploading" && (
                    <CircularProgress
                      variant="determinate"
                      value={uploadProgress}
                      size="68px"
                      sx={{ position: "absolute" }}
                    />
                  )}
                </div>
                <Stack
                  onClick={(c) => {
                    if (busy) return;
                    document.getElementById("file-upload")?.click();
                  }}
                  flex={1}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{
                    background: "#182230",
                    p: "16px",
                    borderRadius: "12px",
                    cursor: "pointer",
                  }}
                >
                  <Typography fontSize={14} fontWeight={600} color="#CECFD2">
                    Click to upload
                  </Typography>
                  <Typography fontSize={12} fontWeight={400} color="#94969C">
                    PNG, JPG (max. 800x400)
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Stack
                direction={"row"}
                sx={{
                  maxWidth: "220px",
                  minWidth: "150px",
                }}
                spacing={"8px"}
              >
                <Typography fontSize={14} fontWeight={500}>
                  Metadata
                </Typography>
                <Tooltip title="Add extra key-value pairs to the product. Can be used for comments and extra reasoning.">
                  <IC_HELP />
                </Tooltip>
              </Stack>
              <Stack
                flex={1}
                spacing="12px"
                sx={{ width: "100%" }}
                key={metadata as any}
              >
                {metadata.map((kv, ind) =>
                  RenderMetadata(ind, kv.key, kv.value)
                )}
                <Button
                  disabled={busy}
                  onClick={() =>
                    setMetadata([...metadata, { key: "", value: "" }])
                  }
                  sx={{ alignSelf: "start" }}
                  startIcon={<IC_ADD />}
                >
                  Add metadata
                </Button>
              </Stack>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Stack
                direction={"row"}
                sx={{
                  maxWidth: "220px",
                  minWidth: "150px",
                }}
                spacing={"8px"}
              >
                <Typography fontSize={14} fontWeight={500}>
                  Mailerlite Group
                </Typography>
                <Tooltip title="Buyers of this product will be added to this mailerlite group.">
                  <IC_HELP />
                </Tooltip>
              </Stack>
              <PFMAutoComplete
                key={groups.length}
                options={groups}
                placeholder="Assign a mailerlite group.."
                onUpdate={(sel) => setSelectedGroup(sel as any)}
                value={selectedGroup}
              />
            </Stack>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={addProduct}
              disabled={busy}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
            >
              Add Product
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
