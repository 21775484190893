import React, { useEffect } from "react";
import { useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  IC_CUSTOMERS,
  IC_DASHBOARD,
  IC_DISCORD,
  IC_PAYMENTS,
  IC_PRODUCTS,
  IC_SETTINGS,
  IC_SIGNOUT,
  IC_SUBSCRIPTIONS,
  IC_SUPPORT,
  IC_USERS,
  LOGO_SIDEBAR,
} from "../assets";
import { signOut } from "firebase/auth";
import { FbAuth } from "../authentication/firebase";
import { useRecoilState } from "recoil";
import { aHideSidebar, aProfile } from "../core/states";
import { useNavigate } from "react-router";
import { PfmRole } from "@pfm/types";
import { AppConfig } from "../config";
import { RestCheckDiscord } from "../core/rest";
import { useModal } from "mui-modal-provider";
import CreateTicketDialog from "../dialogs/CreateTicketDialog";
import {
  ArrowBack,
  ContactSupport,
  People,
  PeopleOutline,
} from "@mui/icons-material";

export default function Sidebar() {
  const navigate = useNavigate();
  const [profile] = useRecoilState(aProfile);
  const [discordProfile, setDiscordProfile] = useState<false | any | undefined>(
    undefined
  );
  const [hideSidebar, setHideSidebar] = useRecoilState(aHideSidebar);
  const [selectedItem, setSelectedItem] = useState<
    | "dashboard"
    | "products"
    | "customers"
    | "users"
    | "subscriptions"
    | "payments"
    | "settings"
    | "support"
    | "referrals"
    | "dashboard" // alias for user/admin dashboard
  >();

  const { showModal } = useModal();

  const desktop = useMediaQuery("(min-width: 700px)");

  async function checkDiscord() {
    try {
      const discordProfile = await RestCheckDiscord();
      if (discordProfile) {
        setDiscordProfile(discordProfile);
      }
    } catch (err: any) {}
  }

  useEffect(() => {
    if (!selectedItem) return;
    // if the user is admin
    if (
      profile?.role === PfmRole.Administrator ||
      profile?.role === PfmRole.Analyst
    ) {
      // use /admin/ sub-route
      navigate("/admin/" + selectedItem);
    } else {
      navigate("/user/" + selectedItem);
    }
    setHideSidebar(true);
  }, [selectedItem]);

  function showTicketDialog() {
    const modal = showModal(CreateTicketDialog, {
      closeHandler(result) {
        modal.destroy();
      },
    });
  }

  useEffect(() => {
    if (profile?.discord && profile.role === PfmRole.User) checkDiscord();
  }, [profile]);

  return (
    <Stack
      sx={{
        width: desktop ? "280px" : "300px",
        px: "20px",
        py: "32px",
        background: "#101828",
        "& .MuiButton-containedPrimary:hover": {
          background: "#182230",
        },
        maxHeight: "100vh",
        position: desktop ? undefined : "fixed",
        zIndex: 100,
      }}
      spacing="32px"
    >
      <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
        {!desktop && (
          <IconButton onClick={() => setHideSidebar(true)}>
            <ArrowBack />
          </IconButton>
        )}
        <img src={LOGO_SIDEBAR} alt="PFM Logo" style={{ height: "40px" }} />
      </Stack>
      {/* Items  */}
      <Stack sx={{ py: "16px" }} spacing="8px">
        <Divider />
        <Button
          onClick={() => setSelectedItem("dashboard")}
          disableElevation
          sx={{
            borderRadius: "8px",
            justifyContent: "start",
            alignItems: "center",
            pointerEvents: selectedItem === "dashboard" ? "none" : undefined,
            background:
              selectedItem === "dashboard" ? undefined : "transparent",
            color: selectedItem === "dashboard" ? "#FCFCFD" : "#94969C",
          }}
          color="primary"
          variant="contained"
          startIcon={
            <SvgIcon htmlColor={selectedItem === "dashboard" ? "#FCFCFD" : ""}>
              <IC_DASHBOARD />
            </SvgIcon>
          }
        >
          Dashboard
        </Button>

        {profile?.role === PfmRole.Administrator && (
          <Button
            onClick={() => setSelectedItem("products")}
            disableElevation
            sx={{
              borderRadius: "8px",
              justifyContent: "start",
              alignItems: "center",
              pointerEvents: selectedItem === "products" ? "none" : undefined,
              background:
                selectedItem === "products" ? undefined : "transparent",
              color: selectedItem === "products" ? "#FCFCFD" : "#94969C",
            }}
            variant="contained"
            startIcon={
              <SvgIcon htmlColor={selectedItem === "products" ? "#FCFCFD" : ""}>
                <IC_PRODUCTS />
              </SvgIcon>
            }
          >
            Products
          </Button>
        )}

        <Button
          onClick={() => setSelectedItem("subscriptions")}
          disableElevation
          sx={{
            borderRadius: "8px",
            justifyContent: "start",
            alignItems: "center",
            pointerEvents:
              selectedItem === "subscriptions" ? "none" : undefined,
            background:
              selectedItem === "subscriptions" ? undefined : "transparent",
            color: selectedItem === "subscriptions" ? "#FCFCFD" : "#94969C",
          }}
          variant="contained"
          startIcon={
            <SvgIcon
              htmlColor={selectedItem === "subscriptions" ? "#FCFCFD" : ""}
            >
              <IC_SUBSCRIPTIONS />
            </SvgIcon>
          }
        >
          Subscriptions
        </Button>

        {(profile?.role === PfmRole.Administrator ||
          profile?.role === PfmRole.Analyst) && (
          <Button
            onClick={() => setSelectedItem("customers")}
            disableElevation
            sx={{
              borderRadius: "8px",
              justifyContent: "start",
              alignItems: "center",
              pointerEvents: selectedItem === "customers" ? "none" : undefined,
              background:
                selectedItem === "customers" ? undefined : "transparent",
              color: selectedItem === "customers" ? "#FCFCFD" : "#94969C",
            }}
            variant="contained"
            startIcon={
              <SvgIcon
                htmlColor={selectedItem === "customers" ? "#FCFCFD" : ""}
              >
                <IC_CUSTOMERS />
              </SvgIcon>
            }
          >
            Customers
          </Button>
        )}

        {(profile?.role === PfmRole.Administrator ||
          profile?.role === PfmRole.Analyst) && (
          <Button
            onClick={() => setSelectedItem("users")}
            disableElevation
            sx={{
              borderRadius: "8px",
              justifyContent: "start",
              alignItems: "center",
              pointerEvents: selectedItem === "users" ? "none" : undefined,
              background: selectedItem === "users" ? undefined : "transparent",
              color: selectedItem === "users" ? "#FCFCFD" : "#94969C",
            }}
            variant="contained"
            startIcon={
              <SvgIcon htmlColor={selectedItem === "users" ? "#FCFCFD" : ""}>
                <IC_USERS />
              </SvgIcon>
            }
          >
            IAM Center
          </Button>
        )}

        <Button
          onClick={() => setSelectedItem("payments")}
          disableElevation
          sx={{
            borderRadius: "8px",
            justifyContent: "start",
            alignItems: "center",
            pointerEvents: selectedItem === "payments" ? "none" : undefined,
            background: selectedItem === "payments" ? undefined : "transparent",
            color: selectedItem === "payments" ? "#FCFCFD" : "#94969C",
          }}
          variant="contained"
          startIcon={
            <SvgIcon htmlColor={selectedItem === "payments" ? "#FCFCFD" : ""}>
              <IC_PAYMENTS />
            </SvgIcon>
          }
        >
          Payments
        </Button>

        <Button
          onClick={() => setSelectedItem("referrals")}
          disableElevation
          sx={{
            borderRadius: "8px",
            justifyContent: "start",
            alignItems: "center",
            pointerEvents: selectedItem === "referrals" ? "none" : undefined,
            background:
              selectedItem === "referrals" ? undefined : "transparent",
            color: selectedItem === "referrals" ? "#FCFCFD" : "#94969C",
          }}
          variant="contained"
          startIcon={
            <SvgIcon htmlColor={selectedItem === "referrals" ? "#FCFCFD" : ""}>
              <PeopleOutline />
            </SvgIcon>
          }
        >
          Affiliation
        </Button>
      </Stack>

      <Stack spacing="8px">
        <Divider />
        <Button
          onClick={() => setSelectedItem("settings")}
          disableElevation
          sx={{
            borderRadius: "8px",
            justifyContent: "start",
            alignItems: "center",
            pointerEvents: selectedItem === "settings" ? "none" : undefined,
            background: selectedItem === "settings" ? undefined : "transparent",
            color: selectedItem === "settings" ? "#FCFCFD" : "#94969C",
          }}
          variant="contained"
          startIcon={
            <SvgIcon htmlColor={selectedItem === "settings" ? "#FCFCFD" : ""}>
              <IC_SETTINGS />
            </SvgIcon>
          }
        >
          Settings
        </Button>
        {profile?.role === PfmRole.User && (
          <Button
            href="https://support.propfirmmasters.com/"
            target="_blank"
            disableElevation
            sx={{
              borderRadius: "8px",
              justifyContent: "start",
              alignItems: "center",
              background: "transparent",
              color: "#94969C",
            }}
            variant="contained"
            startIcon={
              <SvgIcon>
                <IC_SUPPORT />
              </SvgIcon>
            }
          >
            FAQ
          </Button>
        )}
      </Stack>

      <Stack spacing={"24px"}>
        <Divider />
        <Stack direction={"row"} alignItems={"center"} spacing="12px">
          <Avatar
            sx={{ width: "40px", height: "40px", color: "white" }}
            src={FbAuth.currentUser?.photoURL || ""}
          />
          <Stack flex={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#CECFD2",
                maxWidth: "160px",
                wordWrap: "unset",
                overflowWrap: "anywhere",
              }}
            >
              {profile?.name}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                color: "#94969C",
                wordBreak: "break-word",
              }}
            >
              {profile?.email}
            </Typography>
          </Stack>
          <IconButton
            onClick={() => {
              signOut(FbAuth);
              window.location.reload();
            }}
            title="Sign out"
          >
            <IC_SIGNOUT />
          </IconButton>
        </Stack>
        {profile?.role === PfmRole.User && !profile.discord && (
          <Button
            variant="contained"
            color="info"
            onClick={() =>
              (window.location.href = AppConfig.discord.authorization_url)
            }
            startIcon={<IC_DISCORD width="32px" />}
          >
            Connect Discord
          </Button>
        )}
        {profile?.role === PfmRole.User &&
          profile.discord &&
          discordProfile === false && (
            <Button
              variant="contained"
              color="info"
              onClick={() => window.open(AppConfig.discord.invite_link)}
              startIcon={<IC_DISCORD width="32px" />}
            >
              Join Server
            </Button>
          )}
      </Stack>
      {profile?.role === PfmRole.User && (
        <Stack flex={1} justifyContent={"flex-end"} alignItems={"center"}>
          <Typography textAlign={"center"} fontSize={12}>
            Need help? Chat with us or create a ticket.
          </Typography>
          <Button
            onClick={showTicketDialog}
            disableElevation
            sx={{
              borderRadius: "8px",
              justifyContent: "start",
              alignItems: "center",
              background: "transparent",
              color: "#94969C",
            }}
            variant="contained"
            startIcon={
              <SvgIcon>
                <ContactSupport />
              </SvgIcon>
            }
          >
            Create a Ticket
          </Button>
        </Stack>
      )}
      <Stack flex={1} />
      <Typography fontSize={12} sx={{ alignSelf: "center" }}>
        {AppConfig.version} build {AppConfig.build}
      </Typography>
    </Stack>
  );
}
