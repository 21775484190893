import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Stack,
  Typography,
  Divider,
  Button,
  CircularProgress,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aHideSidebar,
  aProfile,
} from "../core/states";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import LoadingOverlay from "../components/LoadingOverlay";
import Sidebar from "../components/Sidebar";
import SectionAdminDashboard from "../sections/admin/dashboard";
import SectionAdminProducts from "../sections/admin/products";
import SectionAdminCustomers from "../sections/admin/customers";
import SectionAdminUsers from "../sections/admin/users";
import SectionAdminSubscriptions from "../sections/admin/subscriptions";
import SectionAdminPayments from "../sections/admin/payments";
import SectionAdminSettings from "../sections/admin/settings";
import { RestGetUserProfile } from "../core/rest";
import { Notification, PfmRole, Ticket, TicketMessage } from "@pfm/types";
import SectionUserDashboard from "../sections/user/dashboard";
import SectionUserProducts from "../sections/user/products";
import SectionUserSubscriptions from "../sections/user/subscriptions";
import SectionUserPayments from "../sections/user/payments";
import SectionUserSupport from "../sections/user/support";
import useWebSocket, {
  ReadyState,
  resetGlobalState,
} from "react-use-websocket";
import { AppConfig } from "../config";
import SectionAdminSupport from "../sections/admin/support";
import { PFMServerMessage } from "../core/sockTypes";
import { enqueueSnackbar } from "notistack";
import { useModal } from "mui-modal-provider";
import SectionTicket from "../sections/common/ticket";
import { LiveChatWidget } from "@livechat/widget-react";
import SectionUserReferrals from "../sections/user/referrals";
import SectionAdminReferrals from "../sections/admin/referrals";
import { ArrowBack, Menu, MoreVert } from "@mui/icons-material";
import SectionUserSettings from "../sections/user/settings";

export default function PageDashboard() {
  const [section] = useRecoilState(aCurrentSection);
  const [hideSidebar, setHideSidebar] = useRecoilState(aHideSidebar);
  const [sectionInfo] = useRecoilState(aCurrentSectionDescription);
  const [profile, setProfile] = useRecoilState(aProfile);
  const nav = useNavigate();
  const { showModal } = useModal();
  const routeInfo = useLocation();
  const [connectionError, setConnectionError] = useState(false);

  const desktop = useMediaQuery("(min-width: 700px)");

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    AppConfig.socks,
    {
      share: true,
      reconnectInterval: 5,
      shouldReconnect(event) {
        return true;
      },
      reconnectAttempts: 500,
      onError(event) {
        setConnectionError(true);
      },
      onOpen(event) {
        setConnectionError(false);
      },
    }
  );
  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  async function loadProfile() {
    if (!desktop) setHideSidebar(true);
    try {
      const prof = await RestGetUserProfile();
      setProfile(prof);
      if (!desktop) setHideSidebar(true);
    } catch (err: any) {
      // Error loading profile.
    }
  }

  useEffect(() => {
    loadProfile();
  }, []);

  useEffect(() => {}, [readyState]);

  useEffect(() => {
    if (lastJsonMessage) {
      const _msg = lastJsonMessage as PFMServerMessage;
      if (_msg.type === "notification") {
        const _data = _msg.data as Notification;
        enqueueSnackbar(_data.content, {
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
          autoHideDuration: 10000,
          variant: "info",
        });
      } else if (_msg.type === "message") {
        // If this route does not have the message's ticket open, we show notification.
        const msgData = _msg.data as TicketMessage;
        if (routeInfo.pathname.includes(msgData.ticketId) === false) {
          // Show new message notification
          // Message notifications are only shown if certain conditions met: such as
          // - current user is author of the ticket
          // - current user is in list of followers of the ticket
          // - current user is assignee of the ticket
          let showNoti = false;
          showNoti = msgData.ticketInfo?.assignee === profile?.uid;
          showNoti = showNoti || msgData.ticketInfo?.user === profile?.uid;
          showNoti =
            showNoti ||
            Boolean(
              msgData.ticketInfo?.followers?.filter(
                (f) => f.uid === profile?.uid
              )?.length
            );
          if (showNoti) {
            enqueueSnackbar(`New message from ${msgData.authorInfo?.name}.`, {
              variant: "info",
              anchorOrigin: {
                horizontal: "right",
                vertical: "bottom",
              },
              autoHideDuration: 8000,
            });
          }
        }
      }
    }
  }, [lastJsonMessage]);

  if (!profile) {
    return <LoadingOverlay busy />;
  }

  return (
    <Stack sx={{ height: "100vh" }} direction={"row"}>
      {connectionError && (
        <Stack
          sx={{
            position: "absolute",
            background: "gold",
            color: "black",
            width: "100%",
          }}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing="8px"
        >
          <Typography>Connection interrupted. Reconnecting...</Typography>
          <CircularProgress size={"20px"} />
        </Stack>
      )}
      {(!hideSidebar || desktop) && <Sidebar />}
      <Stack flex={1} overflow={"auto"}>
        {section && (
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{ px: desktop || !hideSidebar ? 0 : "24px" }}
          >
            {!desktop && hideSidebar && (
              <IconButton
                size="medium"
                onClick={() => {
                  setHideSidebar(false);
                }}
              >
                <Menu />
              </IconButton>
            )}
            <Stack sx={{ m: "32px" }}>
              <Typography fontSize={desktop ? 36 : 20} fontWeight={600}>
                {section || "Dashboard"}
              </Typography>
              <Typography fontSize={desktop ? 16 : 12} fontWeight={400}>
                {sectionInfo || "Welcome"}
              </Typography>
            </Stack>
            <Divider sx={{ mt: "16px" }} />
          </Stack>
        )}
        {profile &&
          (profile.role === PfmRole.Administrator ||
            profile.role === PfmRole.Analyst) && (
            <Routes>
              <Route path="/admin/" element={<SectionAdminDashboard />} />
              <Route
                path="/admin/dashboard"
                element={<SectionAdminDashboard />}
              />
              <Route
                path="/admin/products"
                element={<SectionAdminProducts />}
              />
              <Route
                path="/admin/customers"
                element={<SectionAdminCustomers />}
              />
              <Route path="/admin/users" element={<SectionAdminUsers />} />
              <Route
                path="/admin/subscriptions"
                element={<SectionAdminSubscriptions />}
              />
              <Route
                path="/admin/payments"
                element={<SectionAdminPayments />}
              />
              <Route
                path="/admin/referrals"
                element={<SectionAdminReferrals />}
              />
              <Route
                path="/admin/settings"
                element={<SectionAdminSettings />}
              />
              <Route path="/*" element={<SectionAdminDashboard />} />
            </Routes>
          )}
        {profile && profile.role === PfmRole.User && (
          <Routes>
            <Route path="/user/" element={<SectionUserDashboard />} />
            <Route path="/user/dashboard" element={<SectionUserDashboard />} />
            <Route path="/user/products" element={<SectionUserProducts />} />
            <Route
              path="/user/subscriptions"
              element={<SectionUserSubscriptions />}
            />
            <Route path="/user/payments" element={<SectionUserPayments />} />
            <Route path="/user/referrals" element={<SectionUserReferrals />} />
            <Route path="/user/settings" element={<SectionUserSettings />} />
            <Route path="/*" element={<SectionUserDashboard />} />
          </Routes>
        )}
      </Stack>
      {profile && profile.role === PfmRole.User && (
        <LiveChatWidget
          license={AppConfig.livechatLicense}
          customerEmail={profile.email}
          customerName={profile.name}
          visibility="minimized"
        />
      )}
    </Stack>
  );
}
