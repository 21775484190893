import {
  Button,
  Chip,
  IconButton,
  Paper,
  Typography,
  Stack,
  useTheme,
  Divider,
  Tooltip,
  AvatarGroup,
  Avatar,
} from "@mui/material";
import React, { useState } from "react";
import { IC_HELP, IC_SETTINGS, LOGO_PFM } from "../assets";
import { StripeProduct, StripeSubscription, Subscription } from "@pfm/types";
import { getCurrencySymbol, parseStripeAmount } from "../core/helper";
import { useModal } from "mui-modal-provider";
import ProductInfoDialog from "../dialogs/ProductInfo";
import moment from "moment";
import { PFMSwitch } from "./PFMSwitch";
import CancelSubscriptionDialog from "../dialogs/CancelSubscription";
import { enqueueSnackbar } from "notistack";
import { RestCancelSubscription, RestToggleAutoRenew } from "../core/rest";
export default function MySubscriptionCard(props: {
  subscription: Subscription;
  data: StripeSubscription;
}) {
  const theme = useTheme();
  const { showModal } = useModal();
  const [busy, setBusy] = useState(false);
  const [autoRenew, setAutoRenew] = useState(
    props.data?.collection_method === "charge_automatically"
  );
  const priceInfo = parseStripeAmount(props.subscription.price.amount);
  const priceTag = `${getCurrencySymbol(props.subscription.price.currency)}${
    priceInfo.dollars
  }.${priceInfo.cents}`;
  const [cancelled, setCancelled] = useState(false);

  function showInfoDialog(p: StripeProduct) {
    const modal = showModal(ProductInfoDialog, {
      closeHandler(result) {
        modal.destroy();
      },
      data: p,
    });
  }

  function showCancelDialog() {
    const modal = showModal(CancelSubscriptionDialog, {
      async closeHandler(result) {
        if (result) {
          // cancel subscription
          try {
            await RestCancelSubscription(props.data.id, result);
            enqueueSnackbar(
              "Your subscription has been scheduled to be cancelled at the end of its period.",
              { variant: "success" }
            );
            setCancelled(true);
          } catch (err: any) {
            enqueueSnackbar(
              "Error updating your subscription. Please try again.",
              { variant: "error" }
            );
          }
        }
        modal.destroy();
      },
      data: props.subscription,
    });
  }

  async function updateAutoRenew(renew: boolean) {
    try {
      setBusy(true);
      await RestToggleAutoRenew(props.data.id, renew);
      enqueueSnackbar("Subscription has been updated.", { variant: "success" });
      setAutoRenew(renew);
    } catch (err: any) {
      enqueueSnackbar("Error updating your subscription. Please try again.", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  return (
    <Paper
      sx={{
        width: "388px",
        background: "#101828",
        borderRadius: "12px",
        pt: "36px",
        pb: "24px",
      }}
      elevation={0}
    >
      <Stack spacing="24px">
        {/* Icon and Price  */}
        <Stack
          sx={{ px: "24px" }}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <img
            src={LOGO_PFM}
            style={{
              height: "48px",
              width: "48px",
              objectFit: "contain",
            }}
          />

          {props.data && (
            <Stack direction={"row"} spacing="8px" alignItems={"center"}>
              <Typography>Auto Renew</Typography>
              <PFMSwitch
                disabled={busy}
                checked={autoRenew}
                onChange={() => updateAutoRenew(!autoRenew)}
              />
            </Stack>
          )}
        </Stack>

        {/* Name and description  */}
        <Stack spacing="8px" sx={{ px: "24px" }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontSize={24} fontWeight={500}>
              {props.subscription.name}
            </Typography>
          </Stack>

          {/* <Typography
            sx={{
              textOverflow: "ellipsis",
              overflow: "auto",
              maxHeight: "48px",
            }}
          >
            Subscription description
          </Typography> */}
        </Stack>

        {/* Price  */}
        <Stack spacing="0px" sx={{ px: "24px" }}>
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <Typography fontSize={16} fontWeight={500}>
              Price
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing="8px">
            <Typography fontSize={30} fontWeight={600}>
              {priceTag}
            </Typography>
          </Stack>
        </Stack>

        {/* Subscription Info Labels  */}
        <Stack spacing="8px" sx={{ px: "24px" }}>
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <Typography fontSize={16} fontWeight={500}>
              Included Products
            </Typography>
            <Tooltip
              title="Click on a product's name to view its details."
              arrow
              placement="right"
            >
              <IC_HELP />
            </Tooltip>
          </Stack>
          <Stack direction={"row"} spacing="8px">
            {props.subscription.products.map((p) => (
              <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                <Avatar
                  sx={{ height: "32px", width: "32px", color: "white" }}
                  src={Boolean(p.images?.[0]) ? p.images?.[0] : LOGO_PFM}
                />
                <Typography
                  sx={{ cursor: "pointer" }}
                  onClick={() => showInfoDialog(p)}
                >
                  {p.name}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>

        <Stack
          spacing="8px"
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
          sx={{ px: "24px" }}
        >
          <Stack spacing="8px">
            <Typography fontSize={16} fontWeight={500}>
              Schedule
            </Typography>
            <Stack direction={"row"} spacing="8px">
              <Chip
                size="small"
                variant="identifier"
                sx={{ textTransform: "capitalize" }}
                label={props.subscription.kind}
              />
              {props.subscription.schedule && (
                <Chip
                  sx={{ textTransform: "capitalize" }}
                  size="small"
                  variant="identifier"
                  label={props.subscription.schedule}
                />
              )}
            </Stack>
          </Stack>
        </Stack>

        <Stack
          spacing="8px"
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
          sx={{ px: "24px" }}
        >
          <Stack spacing="8px">
            <Typography fontSize={16} fontWeight={500}>
              Expiry
            </Typography>
            <Stack direction={"row"} spacing="8px">
              {props.subscription.kind === "onetime" && (
                <Typography>Never</Typography>
              )}
              {props.subscription.kind === "recurring" &&
                props.data &&
                props.data.current_period_end && (
                  <Typography>
                    {moment
                      .unix(props.data.current_period_end)
                      .format("MMM DD, YYYY HH:mm")}{" "}
                    ({moment.unix(props.data.current_period_end).fromNow()})
                  </Typography>
                )}
            </Stack>
          </Stack>
        </Stack>

        {false &&
          props.data &&
          !props.data.cancel_at_period_end &&
          !cancelled && (
            <>
              <Divider />
              <Stack
                sx={{ px: "24px" }}
                direction={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                spacing="8px"
              >
                <Button
                  onClick={showCancelDialog}
                  color="error"
                  variant="contained"
                >
                  Cancel Subscription
                </Button>
              </Stack>
            </>
          )}
      </Stack>
    </Paper>
  );
}
