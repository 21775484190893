import { Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function ButtonSwitch(props: {
  checkedLabel?: string;
  label?: string;
  onChange?: (checked: boolean) => void;
  checked?: boolean;
}) {
  const [checked, setChecked] = useState(props.checked || false);

  useEffect(() => {
    props.onChange?.(checked);
  }, [checked]);

  return (
    <Stack
      direction={"row"}
      sx={{
        background: "#1F242F",
        borderRadius: "6px",
        position: "relative",
      }}
    >
      <Stack
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          p: "4px",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "50%",
            borderRadius: "6px",
            background: "#101828",
            backgroundClip: "content-box",
            marginLeft: checked ? "50%" : "0",
            transition: "all .3s",
          }}
        />
      </Stack>

      <Button
        disableElevation
        disableRipple
        fullWidth
        disableTouchRipple
        size="large"
        disableFocusRipple
        sx={{
          mx: "8px",
          color: checked ? "#94969C" : "white",
          ":hover": { background: "transparent" },
        }}
        onClick={() => setChecked(false)}
      >
        {props.label}
      </Button>
      <Button
        fullWidth
        disableElevation
        disableRipple
        size="large"
        disableTouchRipple
        disableFocusRipple
        sx={{
          mx: "8px",
          color: !checked ? "#94969C" : "white",
          ":hover": { background: "transparent" },
        }}
        onClick={() => setChecked(true)}
      >
        {props.checkedLabel}
      </Button>
    </Stack>
  );
}
