import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_USERS } from "../assets";
import IconContainer from "../components/IconContainer";
import { BatchPayout, User } from "@pfm/types";
import PFMTable from "../components/PFMTable";
import { RestListReferralsByUser } from "../api/admin";
import { enqueueSnackbar } from "notistack";
import { parseStripeAmount } from "../core/helper";
import moment from "moment";

export default function ViewPayoutUsersDialog(props: DialogProps<BatchPayout>) {
  const [busy, setBusy] = useState(false);
  const theme = useTheme();

  function RenderRef(ui: {
    uid: string;
    address: string;
    amount: number;
    image?: string;
    name: string;
    email: string;
  }) {
    const pi = parseStripeAmount(ui.amount);
    return (
      <TableRow>
        <TableCell>
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={ui.image} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {ui.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {ui.email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">{ui.address}</Typography>
        </TableCell>
        <TableCell>
          <Typography>
            ${pi.dollars}.{pi.cents}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
      maxWidth={"lg"}
    >
      <DialogContent sx={{ overflow: "hidden" }}>
        <Stack spacing="20px" sx={{ py: "24px", overflow: "hidden" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_USERS fill="white" />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Batch Payout
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Users paid through batch payout
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
              <Typography fontSize={14} color={"#FFFA"}>
                Batch ID:{" "}
              </Typography>
              <Chip variant="identifier" label={props.data?.id} size="small" />
            </Stack>

            <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
              <Typography fontSize={14} color={"#FFFA"}>
                Currency:{" "}
              </Typography>
              <Typography>{props.data?.coin}</Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
              <Typography fontSize={14} color={"#FFFA"}>
                Wallet:{" "}
              </Typography>
              <Typography>{props.data?.wallet}</Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
              <Typography fontSize={14} color={"#FFFA"}>
                Transaction ID:{" "}
              </Typography>
              <Typography>{props.data?.transactionId}</Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
              <Typography fontSize={14} color={"#FFFA"}>
                Created By:{" "}
              </Typography>
              <Typography>{props.data?.createdByEmail}</Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
              <Typography fontSize={14} color={"#FFFA"}>
                Updated:{" "}
              </Typography>
              <Typography>
                {moment.unix(props.data?.updated!).fromNow()}
              </Typography>
            </Stack>
          </Stack>
          <PFMTable
            sx={{ maxHeight: "500px", overflow: "auto" }}
            title="Users"
            description="List of all the users paid. "
            tableHead={
              <TableRow>
                <TableCell>
                  <Typography>User</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Address</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Amount</Typography>
                </TableCell>
              </TableRow>
            }
            tableBody={props.data?.recipients.map((u) => RenderRef(u as any))}
          />
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              size="large"
              onClick={props.closeHandler}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
