export function parseStripeAmount(cents: number) {
  const dollars = Math.floor(cents / 100);
  const remainingCents = cents % 100;
  return { dollars, cents: Math.abs(Math.floor(remainingCents)) };
}
export function getCurrencySymbol(currencyCode: string): string {
  try {
    // Use the Intl.NumberFormat to get the currency symbol for the given currency code.
    const currencyFormatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: currencyCode,
    });
    const parts = currencyFormatter.formatToParts(1);

    for (let i = 0; i < parts.length; i++) {
      if (parts[i].type === "currency") {
        return parts[i].value;
      }
    }
  } catch (error) {
    // Handle any errors, such as unsupported currency codes.
    console.error(
      `Error getting currency symbol for ${currencyCode}: ${error}`
    );
  }

  // Return an empty string if the currency symbol couldn't be determined.
  return "";
}
export function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
export function getCookie(cname: string) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function checkCookie(cname: string) {
  let user = getCookie(cname);
  if (user != "") {
    return true;
  } else {
    return false;
  }
}
