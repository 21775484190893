import React, { useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Radio,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_USER_CHECK } from "../assets";
import IconContainer from "../components/IconContainer";
import { enqueueSnackbar } from "notistack";
import {
  Business,
  CurrencyBitcoin,
  LocalPostOffice,
} from "@mui/icons-material";
import { User } from "@pfm/types";
import { RestUpdateAffilateType } from "../api/admin";

export default function UpdateReferralTypeDialog(props: DialogProps<User>) {
  const [busy, setBusy] = useState(false);
  const [type, setType] = useState<"firm" | "user">(
    props.data?.referralKind || "user"
  );

  const theme = useTheme();

  async function save() {
    try {
      setBusy(true);
      await RestUpdateAffilateType(props.data?.uid!, type);
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar("Error updating user details. Please try later.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <Avatar
                src={props.data?.image || ""}
                sx={{
                  border: "0px",
                  background: "transparent",
                  color: "#FFF",
                }}
              />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                {props.data?.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                {props.data?.email}
              </Typography>
            </Stack>
          </Stack>
          <Divider />

          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            {/* Here we show the two custom radios with Stripe Connect, and Cryptocurrency  */}
            <Typography>
              Change the type of the referrer for different outcomes.
            </Typography>
            <Typography>Select referrer type</Typography>
            <Stack
              onClick={(e) => setType("user")}
              direction={"row"}
              alignItems={"center"}
              spacing="8px"
              sx={{
                transition: "all .3s",
                cursor: "pointer",
                py: "12px",
                px: "10px",
                borderRadius: "8px",
                border:
                  type === "user"
                    ? `2px solid ${theme.palette.primary.main}`
                    : "2px solid #333",
              }}
            >
              <Radio checked={type === "user"} />
              <IC_USER_CHECK />
              <Stack>
                <Typography>Individual User</Typography>
                <Typography>
                  Receives 10% commission on each payment.
                </Typography>
              </Stack>
            </Stack>
            <Stack
              onClick={(e) => setType("firm")}
              spacing="8px"
              sx={{
                transition: "all .3s",
                cursor: "pointer",
                py: "12px",
                px: "10px",
                borderRadius: "8px",
                border:
                  type === "firm"
                    ? `2px solid ${theme.palette.primary.main}`
                    : "2px solid #444",
              }}
            >
              <Stack direction={"row"} alignItems={"center"} spacing="8px">
                <Radio checked={type === "firm"} />
                <Business />
                <Stack>
                  <Typography>Firm / Company</Typography>
                  <Typography>
                    Receives 20% commission on each payment.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
            <Button
              disabled={busy}
              size="large"
              fullWidth
              onClick={save}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
