import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { enqueueSnackbar } from "notistack";
import { ProductConfig, StripeProduct, TemplateField, User } from "@pfm/types";
import { IC_SETTINGS } from "../assets";
import { RestGetProductConfig, RestViewProductConfig } from "../core/rest";
import PFMInput from "../components/PFMInput";
import PFMCheckbox from "../components/PFMCheckbox";
import PFMAutoComplete from "../components/PFMAutoComplete";
import { ContentCopy } from "@mui/icons-material";

export default function ViewProductConfigDialog(
  props: DialogProps<{
    customer: User;
    product: StripeProduct;
    subscriptionId: string;
  }>
) {
  const [busy, setBusy] = useState(false);
  const [config, setConfig] = useState<Partial<ProductConfig>>();
  const [fields, setFields] = useState<TemplateField[]>([]);
  const product = props.data?.product;
  const customer = props.data?.customer;

  async function loadConfig() {
    try {
      setBusy(true);
      const cfg = await RestViewProductConfig(
        customer?.uid!,
        product?.id!,
        props.data?.subscriptionId!
      );
      if (cfg && cfg.fields.length > 0) {
        setConfig(cfg);
        setFields([...cfg.fields]);
      } else {
        enqueueSnackbar("No configuration found. ", { variant: "warning" });
        props.closeHandler(false);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading product config. Please try again. ", {
        variant: "error",
      });
      props.closeHandler(false);
    }
    setBusy(false);
  }

  useEffect(() => {
    loadConfig();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="16px" sx={{ py: "24px", mx: "24px" }}>
          <Stack
            justifyContent={"space-between"}
            direction={"row"}
            alignItems={"center"}
          >
            <Stack
              sx={{
                width: "56px",
                height: "56px",
                border: "1px solid #333741",
                borderRadius: "12px",
              }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <IC_SETTINGS fill="white" />
            </Stack>
          </Stack>
          <Stack spacing={"8px"}>
            <Typography fontSize={18} fontWeight={600}>
              {product?.name}
            </Typography>
            <Typography color="#94969C" fontSize={14} fontWeight={400}>
              View product configuration of the user.
            </Typography>
          </Stack>
          <Divider />
          <Stack spacing="8px">
            {/* Render the config form here  */}
            {fields.map((f, i) => (
              <>
                {f.type === "label" && (
                  <Typography color="#94969C">{f.label}</Typography>
                )}
                {f.type === "divider" && <Divider />}
                {f.type === "text_input" && (
                  <PFMInput
                    label={f.label}
                    placeholder={f.placeholder}
                    important={f.required}
                    helperText={f.hint}
                    text={fields[i]?.value}
                    buttonIcon={ContentCopy}
                    buttonClick={() => {
                      navigator.clipboard.writeText(fields[i].value);
                    }}
                  />
                )}
                {f.type === "text_area" && (
                  <PFMInput
                    label={f.label}
                    placeholder={f.placeholder}
                    important={f.required}
                    helperText={f.hint}
                    multiline
                    rows={4}
                    text={fields[i]?.value}
                    buttonIcon={ContentCopy}
                    buttonClick={() => {
                      navigator.clipboard.writeText(fields[i].value);
                    }}
                  />
                )}
                {f.type === "hidden" && (
                  <PFMInput
                    label={f.label}
                    placeholder={f.placeholder}
                    important={f.required}
                    helperText={f.hint}
                    password
                    text={fields[i]?.value}
                    buttonIcon={ContentCopy}
                    buttonClick={() => {
                      navigator.clipboard.writeText(fields[i].value);
                    }}
                  />
                )}
                {f.type === "checkbox" && (
                  <PFMCheckbox
                    label={f.label}
                    value={Boolean(fields[i]?.value)}
                  />
                )}
                {f.type === "file" && (
                  <>
                    <PFMInput
                      placeholder="No file..."
                      label={f.label}
                      helperText={f.hint}
                      important={f.required}
                      text={fields[i]?.value}
                      buttonIcon={ContentCopy}
                      buttonClick={() => {
                        navigator.clipboard.writeText(fields[i].value);
                      }}
                    />
                  </>
                )}
                {(f.type === "option" || f.type === "options") && (
                  <PFMInput
                    placeholder="No option selected..."
                    label={f.label}
                    helperText={f.hint}
                    important={f.required}
                    text={fields[i]?.value?.label}
                    buttonIcon={ContentCopy}
                    buttonClick={() => {
                      navigator.clipboard.writeText(fields[i].value);
                    }}
                  />
                )}
              </>
            ))}
          </Stack>

          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
