import axios from "axios";

/**
 * Returns the current user object of specified access token.
 */
export async function DiscordGetMe(token: string) {
  const resp = await axios.get("https://discord.com/api/v10/users/@me", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return resp.data;
}
