import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Link,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router";
import LoadingOverlay from "../components/LoadingOverlay";
import {
  ELLIPTICAL_GLOW,
  HAND_DRAWING,
  IC_DISCORD,
  LOGO_VERTICAL,
} from "../assets";
import { DiscordGetMe } from "../core/discord";
import { RestUpdateDiscord } from "../core/rest";

export default function PageDiscordHandler() {
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(false);
  const [busy, setBusy] = useState(false);
  const loc = useLocation();
  const hParams = new URLSearchParams(loc.hash);

  const navigate = useNavigate();

  async function syncDiscordProfile() {
    try {
      setBusy(true);
      // Get the discord user's profile
      const prof = await DiscordGetMe(hParams.get("access_token")!);
      // Update the profile
      if (prof) await RestUpdateDiscord(prof);
      setVerified(true);
      setTimeout(() => {
        navigate("/user/dashboard");
      }, 3000);
    } catch (err: any) {
      enqueueSnackbar(
        "Error verifying your account. Please contact administrators.",
        {
          variant: "error",
        }
      );
      setError(true);
    }
    setBusy(false);
  }

  useEffect(() => {
    if (!hParams.has("access_token")) {
      // Token not sent
      navigate("/");
    } else {
      syncDiscordProfile();
    }
  }, []);

  return (
    <Stack sx={{ height: "100vh" }}>
      <LoadingOverlay busy={busy} />
      <img
        style={{
          position: "fixed",
          top: "64px",
          left: "80px",
          pointerEvents: "none",
          height: "80px",
        }}
        alt="PFM Logo"
        src={LOGO_VERTICAL}
      />
      <img
        style={{
          position: "fixed",
          left: "0px",
          bottom: "0px",
          pointerEvents: "none",
        }}
        alt="Hand Drawing"
        src={HAND_DRAWING}
      />
      <img
        style={{
          position: "fixed",
          right: "0px",
          top: "0px",
          pointerEvents: "none",
        }}
        alt="Elliptical Glow"
        src={ELLIPTICAL_GLOW}
      />

      {verified && (
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
          <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
            <IC_DISCORD width="32px" />
            <Typography
              fontSize={"30px"}
              fontWeight={"600"}
              sx={{ color: "#F5F5F6" }}
            >
              Account linked!
            </Typography>
            <Typography
              textAlign={"center"}
              width={"360px"}
              sx={{ fontSize: "16px", color: "#94969C" }}
            >
              Successfully linked your discord account with PFM.
            </Typography>
          </Stack>
          <FormGroup>
            <Stack spacing="20px" sx={{ mt: "32px", width: "360px" }}>
              <Stack
                justifyContent={"start"}
                direction={"row"}
                alignItems={"center"}
                sx={{ mt: "12px" }}
                spacing="8px"
              >
                <Link href="/user/dashboard" underline="none" fontSize={"14px"}>
                  &lt;- Back to Dashboard
                </Link>
              </Stack>
            </Stack>
          </FormGroup>
        </Stack>
      )}
      {/* Shown when the link has not yet been sent  */}
      {!verified && !error && (
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
          <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
            <Typography
              fontSize={"30px"}
              fontWeight={"600"}
              sx={{ color: "#F5F5F6" }}
            >
              Loading profile...
            </Typography>
            <Typography
              textAlign={"center"}
              width={"360px"}
              sx={{ fontSize: "16px", color: "#94969C" }}
            >
              Please wait while we load your profile from discord..
            </Typography>
          </Stack>
        </Stack>
      )}
      {error && !verified && (
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
          <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
            <Typography
              fontSize={"30px"}
              fontWeight={"600"}
              sx={{ color: "#F5F5F6" }}
            >
              Error loading discord profile!
            </Typography>
            <Typography
              textAlign={"center"}
              width={"360px"}
              sx={{ fontSize: "16px", color: "#94969C" }}
            >
              We were unable to load your profile from Discord. Please try
              again.
            </Typography>
          </Stack>
          <FormGroup>
            <Stack spacing="20px" sx={{ mt: "32px", width: "360px" }}>
              <Stack
                justifyContent={"start"}
                direction={"row"}
                alignItems={"center"}
                sx={{ mt: "12px" }}
                spacing="8px"
              >
                <Link href="/user/dashboard" underline="none" fontSize={"14px"}>
                  &lt;- Back to Dashboard
                </Link>
              </Stack>
            </Stack>
          </FormGroup>
        </Stack>
      )}
    </Stack>
  );
}
