import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Badge,
  Button,
  Chip,
  Paper,
  Popover,
  Popper,
  Stack,
  TableCell,
  TableRow,
  ToggleButton,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import SimpleInsightCard from "../../components/SimpleInsightCard";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import {
  AffiliateTransaction,
  BatchPayout,
  PayoutRequest,
  User,
} from "@pfm/types";
import { useModal } from "mui-modal-provider";
import { StyledToggleButtonGroup } from "../../components/ToggleButtonGroup";
import {
  RestGetAffiliationOverview,
  RestGetBatchPayouts,
  RestGetReferrees,
  RestListAffiliationPayouts,
  RestListAffiliationTransactions,
} from "../../api/admin";
import ViewReferralsDialog from "../../dialogs/ViewReferrals";
import PayoutDialog from "../../dialogs/ConfirmPayout";
import UpdateReferralTypeDialog from "../../dialogs/UpdateReferralType";
import MassPayoutDialog from "../../dialogs/MassPayout";
import ConfirmPayoutDialog from "../../dialogs/ConfirmPayout";
import ViewPayoutUsersDialog from "../../dialogs/PayoutUsers";

export default function SectionAdminReferrals() {
  const [profile] = useRecoilState(aProfile);
  const [dataView, setDataView] = useState<
    "referrees" | "payouts" | "transactions" | "batch-payouts"
  >("referrees");
  const [section, setSection] = useRecoilState(aCurrentSection);
  const [sectionDescription, setSectionDescription] = useRecoilState(
    aCurrentSectionDescription
  );
  const [busy, setBusy] = useState(true);

  const [balance, setBalance] = useState<number>(0);
  const [withdrawn, setWithdrawn] = useState(0);
  const [pending, setPending] = useState(0);

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [dataCount, setDataCount] = useState(0);

  const [txns, setTxns] = useState<AffiliateTransaction[]>([]);
  const [referrees, setReferrees] = useState<User[]>([]);
  const [payouts, setPayouts] = useState<PayoutRequest[]>([]);
  const [batchPayouts, setBatchPayouts] = useState<BatchPayout[]>([]);

  const { showModal } = useModal();

  const _available = useMemo(() => {
    const pi = parseStripeAmount(balance);
    return `USD $${pi.dollars}.${pi.cents}`;
  }, [balance]);
  const _pending = useMemo(() => {
    const pi = parseStripeAmount(pending);
    return `USD $${pi.dollars}.${pi.cents}`;
  }, [pending]);
  const _withdrawn = useMemo(() => {
    const pi = parseStripeAmount(withdrawn);
    return `USD $${pi.dollars}.${pi.cents}`;
  }, [withdrawn]);

  async function loadData(pageNo?: number) {
    try {
      //Load data
      setBusy(true);
      // If view is in referrals
      if (dataView === "referrees") {
        const r = await RestGetReferrees(pageNo || 0, 50, undefined);
        if (r) {
          setReferrees(r.data);
          setPageNumber(r.currentPage);
          setDataCount(r.total);
          setTotalPages(r.pageCount);
        }
      } else if (dataView === "transactions") {
        const r2 = await RestListAffiliationTransactions(pageNo || 0, 50);
        if (r2) {
          setTotalPages(r2.pageCount);
          setPageNumber(r2.currentPage);
          setDataCount(r2.total);
          setTxns(r2.data);
        }
      } else if (dataView === "payouts") {
        // Get payouts
        const r3 = await RestListAffiliationPayouts(pageNo || 0, 50);
        if (r3) {
          setTotalPages(r3.pageCount);
          setPageNumber(r3.currentPage);
          setDataCount(r3.total);
          setPayouts(r3.data);
        }
      } else {
        const r4 = await RestGetBatchPayouts();
        if (r4) {
          setDataCount(r4.length);
          setBatchPayouts(r4);
        }
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
    }
    setBusy(false);
  }

  async function loadOverview() {
    try {
      //Load data
      setBusy(true);
      // If view is in referrals
      const da = await RestGetAffiliationOverview();
      if (da) {
        setPending(da.requested);
        setBalance(da.balance);
        setWithdrawn(da.withdrawn);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`Affiliation`);
    setSectionDescription(
      `View and manage referrees, payouts, and transactions for affiliate program.`
    );
    loadData();
    loadOverview();
  }, []);

  function RenderReferral(ui: User) {
    const pi = parseStripeAmount(
      (ui.affiliation?.balance || 0) + (ui.affiliation?.withdrawn || 0)
    );
    const pi2 = parseStripeAmount(ui.affiliation?.balance || 0);
    return (
      <TableRow>
        <TableCell>
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={ui.image || ""} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {ui.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {ui.email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>

        <TableCell sx={{ width: "auto", minWidth: "200px" }}>
          <Button
            title="Click to change"
            onClick={() => {
              const modal = showModal(UpdateReferralTypeDialog, {
                data: ui,
                closeHandler(result) {
                  modal.destroy();
                  if (result === true) {
                    // Reload
                    loadData(pageNumber);
                  }
                },
              });
            }}
          >
            {ui.referralKind === "firm" ? "Firm" : "Individual"}
          </Button>
        </TableCell>
        <TableCell sx={{ width: "auto", minWidth: "200px" }}>
          <Typography color="#94969C">{ui.referrals}</Typography>
        </TableCell>
        <TableCell sx={{ width: "auto", minWidth: "200px" }}>
          <Typography>
            ${pi.dollars}.{pi.cents}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "auto", minWidth: "200px" }}>
          <Typography fontWeight={500}>
            ${pi2.dollars}.{pi2.cents}
          </Typography>
        </TableCell>
        <TableCell>
          <Button
            onClick={() => {
              const modal = showModal(ViewReferralsDialog, {
                data: ui,
                closeHandler(result) {
                  modal.destroy();
                },
              });
            }}
          >
            View Referrals
          </Button>
          <Button
            onClick={() => {
              const modal = showModal(ConfirmPayoutDialog, {
                data: ui,
                closeHandler(result) {
                  modal.destroy();
                  if (result === true) {
                    // Reload
                    loadData(pageNumber);
                  }
                },
              });
            }}
          >
            Pay
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  function RenderTxn(tx: AffiliateTransaction) {
    const amnt = parseStripeAmount(tx.amount);
    const sym = getCurrencySymbol(tx.currency);
    return (
      <TableRow>
        <TableCell>
          <Typography color="#94969C">
            {tx.kind === "payout" ? "Payout" : "Credit"}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography>
            {tx.kind === "reward" ? tx.referralName : "N/A"}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "auto", minWidth: "200px" }}>
          <Typography color="#94969C">
            {moment.unix(tx.created).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>

        <TableCell>
          {sym}
          {amnt.dollars}.{amnt.cents}
        </TableCell>

        <TableCell>
          <Chip
            variant="identifier"
            size="small"
            label={tx.kind === "payout" ? tx.status : "Complete"}
          />
        </TableCell>
      </TableRow>
    );
  }

  function RenderPayout(po: PayoutRequest & { user?: any }) {
    const amnt = parseStripeAmount(po.amount);
    return (
      <TableRow>
        <TableCell>
          <Chip variant="identifier" label={po.id} size="small" />
        </TableCell>
        <TableCell>
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={po.user.image || ""} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {po.user.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {po.user.email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography>
            {po.payoutMethod.type === "crypto"
              ? `Crypto - ${po.payoutMethod.coin} (${po.payoutMethod.network})`
              : "Stripe"}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            {moment.unix(po.createdAt).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>

        <TableCell>
          <Chip variant="identifier" label={po.status} size="small" />
        </TableCell>
        <TableCell>
          ${amnt.dollars}.{amnt.cents}
        </TableCell>
      </TableRow>
    );
  }

  function RenderMassPayout(po: BatchPayout) {
    const amnt = parseStripeAmount(0);
    return (
      <TableRow>
        <TableCell>
          <Chip variant="identifier" label={po.id} size="small" />
        </TableCell>
        <TableCell>
          <Chip variant="identifier" label={po.transactionId} size="small" />
        </TableCell>
        <TableCell>
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <AvatarGroup max={5}>
              {po.recipients.map((u) => (
                <Avatar src={u.image || ""} sx={{ color: "white" }} />
              ))}
            </AvatarGroup>
            <Button
              onClick={() => {
                const modal = showModal(ViewPayoutUsersDialog, {
                  closeHandler(result) {
                    modal.destroy();
                  },
                  data: po,
                });
              }}
            >
              View
            </Button>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography>{po.coin}</Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            {moment.unix(po.updated).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>

        <TableCell>
          <Chip variant="identifier" label={po.status} size="small" />
        </TableCell>
        <TableCell>
          ${amnt.dollars}.{amnt.cents}
        </TableCell>
      </TableRow>
    );
  }

  useEffect(() => {
    loadData();
  }, [dataView]);

  return (
    <Stack sx={{ p: "32px", flex: 1 }} spacing="12px">
      <StyledToggleButtonGroup
        sx={{ alignSelf: "flex-end" }}
        exclusive
        value={dataView}
        onChange={(c, e) => setDataView(e)}
      >
        <ToggleButton value={"referrees"}>
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
            spacing="16px"
            sx={{ px: "16px" }}
          >
            <Badge
              variant="dot"
              color={dataView === "referrees" ? "success" : undefined}
            ></Badge>
            <Typography>Referrees</Typography>
          </Stack>
        </ToggleButton>
        <ToggleButton value={"payouts"}>
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
            spacing="16px"
            sx={{ px: "16px" }}
          >
            <Badge
              variant="dot"
              color={dataView === "payouts" ? "success" : undefined}
            ></Badge>
            <Typography>Payouts</Typography>
          </Stack>
        </ToggleButton>
        <ToggleButton value={"batch-payouts"}>
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
            spacing="16px"
            sx={{ px: "16px" }}
          >
            <Badge
              variant="dot"
              color={dataView === "batch-payouts" ? "success" : undefined}
            ></Badge>
            <Typography>Mass Payouts</Typography>
          </Stack>
        </ToggleButton>
        <ToggleButton value={"transactions"}>
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
            spacing="16px"
            sx={{ px: "16px" }}
          >
            <Badge
              variant="dot"
              color={dataView === "transactions" ? "success" : undefined}
            ></Badge>
            <Typography>Balances</Typography>
          </Stack>
        </ToggleButton>
      </StyledToggleButtonGroup>

      {/* If view is referrees, we show referrals */}
      {dataView === "referrees" && (
        <PFMTable
          sx={{ flex: 1 }}
          paginated
          pageNumber={pageNumber}
          busy={busy}
          totalPages={totalPages}
          nextPage={(t) => loadData(t)}
          prevPage={(l) => loadData(l)}
          title={"Affiliates"}
          titleBadge={referrees.length.toString()}
          description={"List of all the affiliates"}
          tableHead={
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Referrals</TableCell>
              <TableCell>Total Outcome</TableCell>
              <TableCell>Current Balance</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          }
          tableBody={referrees.map((t) => RenderReferral(t))}
        />
      )}

      {/* Or if the view is payouts, we show overview, and current in progress payout requests  */}
      {(dataView === "payouts" || dataView === "batch-payouts") && (
        <>
          <Stack direction={"row"} spacing="12px">
            <SimpleInsightCard
              accent="#2E90FA"
              title="Total Due"
              value={_available}
            />
            <SimpleInsightCard
              accent="#2E61FA"
              title="Processing"
              value={_pending}
            />
            <SimpleInsightCard
              accent="#448"
              title="Total Paid"
              value={_withdrawn}
            />
          </Stack>
          <Stack
            direction={"row"}
            sx={{
              px: "24px",
              py: "20px",
              background: "#101828",
              borderRadius: "12px",
            }}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack>
              <Typography fontWeight={600} fontSize={20}>
                Mass Payout
              </Typography>
              <Typography>
                Automatically send payouts to all the users to their preferred
                payout method.
              </Typography>
            </Stack>
            <Button
              onClick={() => {
                const modal = showModal(MassPayoutDialog, {
                  closeHandler(result) {
                    if (result === true) loadData(pageNumber);
                    modal.destroy();
                  },
                });
              }}
              size="large"
              variant="contained"
            >
              Mass Payout
            </Button>
          </Stack>
        </>
      )}

      {/* Payouts for normal ones  */}
      {dataView === "payouts" && (
        <PFMTable
          sx={{ flex: 1 }}
          paginated
          pageNumber={pageNumber}
          busy={busy}
          totalPages={totalPages}
          nextPage={(t) => loadData(t)}
          prevPage={(l) => loadData(l)}
          title={"Payouts"}
          titleBadge={payouts.length.toString()}
          description={"View the payout requests."}
          tableHead={
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Method</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          }
          tableBody={payouts.map((tx) => RenderPayout(tx))}
        />
      )}

      {/* Mass payouts  */}
      {dataView === "batch-payouts" && (
        <PFMTable
          sx={{ flex: 1 }}
          busy={busy}
          title={"Mass Payouts"}
          titleBadge={batchPayouts.length.toString()}
          description={"View the mass payouts created in batches."}
          tableHead={
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Tx</TableCell>
              <TableCell>Users</TableCell>
              <TableCell>Coin</TableCell>
              <TableCell>Last updated</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          }
          tableBody={batchPayouts.map((tx) => RenderMassPayout(tx))}
        />
      )}

      {/* Or if its transactions, we only show previous transactions  */}
      {dataView === "transactions" && (
        <PFMTable
          sx={{ flex: 1 }}
          paginated
          pageNumber={pageNumber}
          busy={busy}
          totalPages={totalPages}
          nextPage={(t) => loadData(t)}
          prevPage={(l) => loadData(l)}
          title={"Balance History"}
          titleBadge={txns.length.toString()}
          description={"Balances added or subtracted for users."}
          tableHead={
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          }
          tableBody={txns.map((t) => RenderTxn(t))}
        />
      )}
    </Stack>
  );
}
