import {
  Button,
  Chip,
  IconButton,
  Paper,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { IC_SETTINGS, LOGO_PFM } from "../assets";
import { StripeProduct, Subscription } from "@pfm/types";
import { getCurrencySymbol, parseStripeAmount } from "../core/helper";
import ProductInfoDialog from "../dialogs/ProductInfo";
import { useModal } from "mui-modal-provider";
import ProductConfigDialog from "../dialogs/ProductConfig";
export default function DashboardProductCard(props: {
  subscription: Subscription;
  product: StripeProduct;
  subscriptionId: string;
}) {
  const theme = useTheme();
  const desktop = useMediaQuery("(min-width: 700px)");
  const { showModal } = useModal();

  function showInfoDialog() {
    const modal = showModal(ProductInfoDialog, {
      closeHandler(result) {
        modal.destroy();
      },
      data: props.product,
    });
  }
  function showConfigDialog() {
    const modal = showModal(ProductConfigDialog, {
      closeHandler(result) {
        modal.destroy();
      },
      data: { ...props.product, subscriptionId: props.subscriptionId },
    });
  }

  return (
    <Paper
      sx={{
        width: desktop ? "388px" : "300px",
        background: "#101828",
        borderRadius: "12px",
        px: "24px",
        py: desktop ? "36px" : "24px",
        m: "8px",
      }}
      elevation={0}
    >
      <Stack spacing="24px" sx={{ height: "100%" }}>
        {/* Icon and configuration button  */}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <img
            src={props.product.images?.[0] || LOGO_PFM}
            style={{
              height: "48px",
              width: "48px",
              objectFit: "contain",
            }}
          />

          {/* Only visible if template field exists on the product.  */}
          {props.product.template && (
            <IconButton
              onClick={showConfigDialog}
              color="secondary"
              sx={{
                borderRadius: "8px",
              }}
            >
              <IC_SETTINGS fill="white" />
            </IconButton>
          )}
        </Stack>

        {/* Name and description  */}
        <Stack spacing="8px">
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontSize={24} fontWeight={500}>
              {props.product.name}
            </Typography>
          </Stack>

          <Typography
            sx={{
              textOverflow: "ellipsis",
              overflow: "auto",
              height: "100px",
            }}
          >
            {props.product.description}
          </Typography>
        </Stack>

        {/* Subscription Info Labels  */}
        <Stack spacing="8px">
          <Typography fontSize={16} fontWeight={500}></Typography>
        </Stack>

        <Stack
          spacing="8px"
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
        >
          <Stack spacing="8px">
            <Typography fontSize={16} fontWeight={500}>
              Included in
            </Typography>
            <Stack direction={"row"} spacing="8px">
              <Chip
                label={props.subscription.name}
                variant="identifier"
                color="default"
              />
            </Stack>
          </Stack>
        </Stack>

        <Stack
          flex={1}
          spacing="8px"
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
        >
          <Button
            onClick={showInfoDialog}
            variant="contained"
            color="secondary"
          >
            Product Details
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}
