import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Radio,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { IC_ALERT_WARNING, IC_CARD, IC_STRIPE } from "../assets";
import IconContainer from "../components/IconContainer";
import { enqueueSnackbar } from "notistack";
import { CurrencyBitcoin } from "@mui/icons-material";
import { useRecoilState } from "recoil";
import { aProfile } from "../core/states";
import { useModal } from "mui-modal-provider";
import PFMInput from "../components/PFMInput";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../components/PFMAutoComplete";
import {
  RestGetStripeConnectUrl,
  RestGetUserProfile,
  RestRequestWithdraw,
  RestUpdateAffiliateProfile,
} from "../core/rest";

export default function WithdrawDialog(props: DialogProps<any>) {
  const [profile, setProfile] = useRecoilState(aProfile);
  const [busy, setBusy] = useState(false);
  const [mode, setMode] = useState<"stripe" | "web3">("web3");
  const [network, setNetwork] = useState<AutocompleteOption>();
  const [coin, setCoin] = useState<AutocompleteOption>();
  const [address, setAddress] = useState("");
  const theme = useTheme();
  const isSelectedMethodReady = useMemo(() => {
    if (mode === "stripe") {
      if (profile?.affiliation?.stripe) return true;
      return false;
    } else {
      if (address && network && coin) return true;
      return false;
    }
  }, [mode, profile, address, network, coin]);
  const { showModal } = useModal();
  const [editWeb3, setEditWeb3] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [ALLCOINS] = useState<AutocompleteOption[]>([
    {
      label: "USDT",
      id: "ETH_USDT",
      network: "ethereum",
    },
    {
      label: "USDC",
      id: "ETH_USDC",
      network: "ethereum",
    },
    {
      label: "USDT",
      id: "USDT-TRX",
      network: "tron",
    },
  ]);
  const [COINS, setCOINS] = useState<AutocompleteOption[]>([]);
  const [NETWORKS] = useState<AutocompleteOption[]>([
    {
      label: "Ethereum",
      id: "ethereum",
    },
    {
      label: "Tron",
      id: "tron",
    },
  ]);
  const [step, setStep] = useState<"method" | "confirmation">("method");

  function load() {
    // Set the network
    if (profile?.affiliation?.web3) {
      const n = NETWORKS.find(
        (n) => n.id === profile.affiliation?.web3?.walletNetwork
      );
      setNetwork(n);
      setAddress(profile.affiliation?.web3?.walletAddress || "");
      setCoin(
        ALLCOINS.find((c) => c.id === profile.affiliation?.web3?.walletCoin)
      );
    }
    if (profile?.affiliation?.preferred === "crypto") {
      setMode("web3");
    } else {
      setMode("stripe");
    }
  }

  async function setupStripe() {
    try {
      const url = await RestGetStripeConnectUrl("setup");
      if (url) {
        // Navigate in new page
        window.open(url, "_blank");
      }
    } catch (err: any) {
      enqueueSnackbar(
        "Error creating stripe connect session. Please try later.",
        { variant: "error" }
      );
      console.log(err);
    }
  }

  async function loginStripe() {
    try {
      const url = await RestGetStripeConnectUrl("login");
      if (url) {
        // Navigate in new page
        window.open(url, "_blank");
      }
    } catch (err: any) {
      enqueueSnackbar(
        "Error creating stripe connect session. Please try later.",
        { variant: "error" }
      );
      console.log(err);
    }
  }

  async function save() {
    try {
      // If we have selected web3, make sure the fields are valid.
      if (mode === "web3") {
        if (!address) {
          enqueueSnackbar("Please enter your wallet address. ", {
            variant: "error",
          });
          return;
        }
        if (!network?.id) {
          enqueueSnackbar("Please select your preferred network.", {
            variant: "error",
          });
          return;
        }
        if (!coin?.id) {
          enqueueSnackbar("Please select your preferred coin.", {
            variant: "error",
          });
          return;
        }
        setBusy(true);
        await RestUpdateAffiliateProfile({
          preferred: "crypto",
          address: address,
          network: network.id,
          coin: coin.id,
        });
        const prof = await RestGetUserProfile();
        setProfile(prof);
        setEditWeb3(false);
        props.closeHandler(true);
      } else {
        // Close the dialog only if the user has stripe set up
        setBusy(true);
        const _prf = await RestGetUserProfile();
        if (_prf && _prf.affiliation?.stripe) {
          await RestUpdateAffiliateProfile({
            preferred: "stripe",
          });
          // Close
          props.closeHandler(true);
        } else {
          enqueueSnackbar("Please set up your stripe account first. ", {
            variant: "error",
          });
        }
      }
    } catch (err: any) {
      enqueueSnackbar(
        "Error updating your account details. Please try later.",
        { variant: "error" }
      );
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    setCOINS([...ALLCOINS.filter((c) => c.network === network?.id)]);
    setRefresh(refresh + 1);
  }, [network]);

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_CARD />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Manage Payout Options
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                View or update your payout information.
              </Typography>
            </Stack>
          </Stack>
          <Divider />

          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            {/* Here we show the two custom radios with Stripe Connect, and Cryptocurrency  */}
            <Typography>Select your preferred payout method</Typography>
            {/* <Stack
              onClick={(e) => setMode("stripe")}
              direction={"row"}
              alignItems={"center"}
              spacing="8px"
              sx={{
                transition: "all .3s",
                cursor: "pointer",
                py: "12px",
                px: "10px",
                borderRadius: "8px",
                border:
                  mode === "stripe"
                    ? `2px solid ${theme.palette.primary.main}`
                    : "2px solid #333",
              }}
            >
              <Radio checked={mode === "stripe"} />
              <IC_STRIPE />
              <Typography>Stripe Connect</Typography>
              {!profile?.affiliation?.stripe && (
                <Button onClick={setupStripe}>Setup</Button>
              )}
              {profile?.affiliation?.stripe && (
                <Button onClick={loginStripe}>Manage</Button>
              )}
            </Stack> */}
            <Stack
              onClick={(e) => setMode("web3")}
              spacing="8px"
              sx={{
                transition: "all .3s",
                cursor: "pointer",
                py: "12px",
                px: "10px",
                borderRadius: "8px",
                border:
                  mode === "web3"
                    ? `2px solid ${theme.palette.primary.main}`
                    : "2px solid #444",
              }}
            >
              <Stack direction={"row"} alignItems={"center"} spacing="8px">
                <Radio checked={mode === "web3"} />
                <CurrencyBitcoin />
                <Typography>Crypto (USDT, USDC, etc.)</Typography>
                {!editWeb3 && (
                  <Button onClick={() => setEditWeb3(true)}>Edit</Button>
                )}
              </Stack>
              {/* Shown only if the mode is web3, and no web3 info is present in user profile.  */}
              {/* Can also be shown in edit mode in case the info is already present */}
              {((mode === "web3" && !isSelectedMethodReady) || editWeb3) && (
                <Stack spacing="12px">
                  <Stack direction={"row"} spacing="8px">
                    <PFMAutoComplete
                      important
                      options={NETWORKS}
                      multiple={false}
                      value={network}
                      onUpdate={(n) => {
                        setNetwork(n as any);
                        setCoin(undefined);
                      }}
                      fullWidth
                      label="Network"
                      placeholder="Select your preferred network..."
                    />
                    <PFMAutoComplete
                      key={refresh}
                      important
                      label="Coin"
                      placeholder="Select coin..."
                      options={COINS}
                      value={coin}
                      onUpdate={(t) => setCoin(t as any)}
                    />
                  </Stack>
                  <PFMInput
                    text={address}
                    onUpdate={(t) => setAddress(t)}
                    important
                    label="Address"
                    placeholder="Enter your wallet address..."
                  />
                </Stack>
              )}
              {/* The info will be used from profile if exists, or the user will be asked to enter it.  */}
            </Stack>
            <Alert color="warning" icon={<IC_ALERT_WARNING stroke="gold" />}>
              <AlertTitle>Note</AlertTitle>
              <Typography>
                Network and transaction fees will be deducted from your final
                payout.
              </Typography>
            </Alert>
          </Stack>

          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={() => {
                if (step === "confirmation") {
                  // Go back
                  setStep("method");
                } else if (step === "method") {
                  // Cancel
                  props.closeHandler();
                }
              }}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              {step === "method" ? "Cancel" : "Back"}
            </Button>
            <Button
              disabled={busy || !isSelectedMethodReady}
              size="large"
              fullWidth
              onClick={save}
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
