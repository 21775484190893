const debug = {
  api: "http://192.168.100.70:3001",
  socks: "ws://192.168.100.70:3001",
  firebaseConfig: {
    apiKey: "AIzaSyCRdKZrTN2IabQBHDc66GaIECiUDDT2ksw",
    authDomain: "propfirmmasters-debug.firebaseapp.com",
    projectId: "propfirmmasters-debug",
    storageBucket: "propfirmmasters-debug.appspot.com",
    messagingSenderId: "971077817288",
    appId: "1:971077817288:web:3fc4fff280e44ef79f66fd",
  },
  discord: {
    client_secret: "C7LAgxczSbINci0tKzC2bSVNKh1GIpvo",
    client_id: "1172279997914955846",
    redirect_url: "http://localhost:3000/discord",
    invite_link: "https://discord.gg/veCy9BpUm6",
    authorization_url:
      "https://discord.com/api/oauth2/authorize?client_id=1172279997914955846&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdiscord&response_type=token&scope=identify",
  },
  livechatLicense: "16627317",
  jeff: "http://127.0.0.1:7070/",
  mode: "debug",
  version: "1.1.0",
  build: "11-01-2024",
};

const prod = {
  api: "https://service.propfirmmasters.com",
  socks: "wss://service.propfirmmasters.com",
  firebaseConfig: {
    apiKey: "AIzaSyDAHRCcQQKVK1T3EtY8O5_-ovsVUcwV-TY",
    authDomain: "propfirmmasters.firebaseapp.com",
    projectId: "propfirmmasters",
    storageBucket: "propfirmmasters.appspot.com",
    messagingSenderId: "350205077516",
    appId: "1:350205077516:web:f61da5a7bb499f7e412629",
  },
  discord: {
    client_secret: "C7LAgxczSbINci0tKzC2bSVNKh1GIpvo",
    client_id: "1172279997914955846",
    invite_link: "https://discord.gg/veCy9BpUm6",
    redirect_url: "https://portal.propfirmmasters.com/discord",
    authorization_url:
      "https://discord.com/api/oauth2/authorize?client_id=1172279997914955846&redirect_uri=https%3A%2F%2Fportal.propfirmmasters.com%2Fdiscord&response_type=token&scope=identify",
  },
  livechatLicense: "16627317",
  jeff: "https://service.propfirmmasters.com:7070/",
  mode: "production",
  version: "2024.07.01",
  build: "#40",
};

const beta = {
  api: "https://service.propfirmmasters.com",
  socks: "wss://service.propfirmmasters.com",
  firebaseConfig: {
    apiKey: "AIzaSyDAHRCcQQKVK1T3EtY8O5_-ovsVUcwV-TY",
    authDomain: "propfirmmasters.firebaseapp.com",
    projectId: "propfirmmasters",
    storageBucket: "propfirmmasters.appspot.com",
    messagingSenderId: "350205077516",
    appId: "1:350205077516:web:f61da5a7bb499f7e412629",
  },
  discord: {
    client_secret: "C7LAgxczSbINci0tKzC2bSVNKh1GIpvo",
    client_id: "1172279997914955846",
    invite_link: "https://discord.gg/veCy9BpUm6",
    redirect_url: "https://portal.propfirmmasters.com/discord",
    authorization_url:
      "https://discord.com/api/oauth2/authorize?client_id=1172279997914955846&redirect_uri=https%3A%2F%2Fportal.propfirmmasters.com%2Fdiscord&response_type=token&scope=identify",
  },
  livechatLicense: "16627317",
  jeff: "https://service.propfirmmasters.com:7070/",
  mode: "beta",
  version: "2.1.0",
  build: "01-01-2024",
};

const DEBUG = process.env.REACT_APP_DEBUG === "true";

const MODE = process.env.REACT_APP_MODE;

export const AppConfig = DEBUG ? debug : MODE === "staging" ? beta : prod;
