import React, { ReactElement, useEffect, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import {
  IC_AMEX,
  IC_DISCOVER,
  IC_MASTERCARD,
  IC_PAYPAL,
  IC_STRIPE,
  IC_VISA,
  LOGO_PFM,
} from "../assets";
import { enqueueSnackbar } from "notistack";
import {
  RestCancelSubscriptionAdmin,
  RestGetRecentPayments,
} from "../core/rest";
import { User } from "@pfm/types";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { getCurrencySymbol, parseStripeAmount } from "../core/helper";
import CancelSubscriptionAdminDialog from "./CancelSubscriptionAdmin";
export default function CustomerDialog(props: DialogProps<User>) {
  const customer = props.data;

  const [invoices, setInvoices] = useState<any[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);

  const [busy, setBusy] = useState(false);

  const { showModal } = useModal();

  async function loadInvoices() {
    // Get invoices of the customer.
    try {
      setBusy(true);
      const txns = await RestGetRecentPayments(customer?.customer_info.id!);
      setInvoices(txns.invoices);
      setPaymentMethods(txns.methods);
    } catch (err: any) {
      enqueueSnackbar(
        "Error loading invoices of the customer. Please try again.",
        { variant: "error" }
      );
    }
    setBusy(false);
  }

  function showCancelDialog(sub: any) {
    const modal = showModal(CancelSubscriptionAdminDialog, {
      data: sub,
      async closeHandler(resukt) {
        modal.destroy();
        if (resukt) {
          // cancel the subscription
          try {
            await RestCancelSubscriptionAdmin(sub.data.id);
            enqueueSnackbar("The subscription has been cancelled. ", {
              variant: "success",
            });
            props.closeHandler();
          } catch (err: any) {
            enqueueSnackbar(
              "Error cancelling subscription. Please try again.",
              { variant: "error" }
            );
          }
        }
      },
    });
  }

  useEffect(() => {
    loadInvoices();
  }, []);

  function RenderPaymentMethod() {
    if (!paymentMethods || paymentMethods.length === 0) {
      return (
        <Typography sx={{ opacity: 0.6 }}>No payment methods found.</Typography>
      );
    }
    const pm = paymentMethods[0];
    let ic: ReactElement;
    if (pm.type === "card") {
      // Card brands icons
      if (pm.card.brand === "american_express") {
        // Amex
        ic = <IC_AMEX />;
      } else if (pm.card.brand === "discover") {
        // Discover
        ic = <IC_DISCOVER />;
      } else if (pm.card.brand === "mastercard") {
        // MasterCard
        ic = <IC_MASTERCARD />;
      } else if (pm.card.brand === "visa") {
        // Visa
        ic = <IC_VISA />;
      } else {
        // Unknown
        ic = <CreditCardIcon sx={{ width: "32px", height: "32px" }} />;
      }
    } else if (pm.type === "paypal") {
      // Paypal icon
      ic = <IC_PAYPAL />;
    } else if (pm.type === "customer_balance") {
      // Stripe?
      ic = <IC_STRIPE />;
    } else {
      // Unknown
      ic = <CreditCardIcon sx={{ width: "32px", height: "32px" }} />;
    }

    return (
      <Stack direction={"row"} spacing="8px" alignItems={"center"}>
        {ic}
        <Stack>
          {pm.card && (
            <>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={"#F5F5F6"}
                sx={{ "::first-letter": { textTransform: "capitalize" } }}
              >
                {pm.card?.brand} ending with {pm.card?.last4}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                Expiry {pm.card?.exp_month}/{pm.card?.exp_year}
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
    );
  }

  function RenderSubscriptions() {
    const subs = customer?.subscriptions;
    if (!subs || subs.length === 0) {
      return (
        <Typography sx={{ opacity: 0.6 }}>No active subscriptions.</Typography>
      );
    }
    return (
      <>
        {subs?.map((s) => (
          <>
            {s.name && (
              <Stack
                direction={"row"}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
                sx={{ width: "100%" }}
              >
                <Tooltip
                  title={
                    "Includes " +
                    s.products?.reduce(
                      (pv, cv, i) => pv + (pv ? ", " : "") + cv.name,
                      ""
                    )
                  }
                >
                  <Button sx={{ color: "#94969C" }}>{s.name}</Button>
                </Tooltip>
                {s.data && (
                  <Button
                    disabled={(s as any).data?.status === "cancelled"}
                    color="error"
                    sx={{ alignSelf: "flex-end" }}
                    startIcon={<NotInterestedIcon />}
                    onClick={() => showCancelDialog(s)}
                  >
                    Cancel Subscription
                  </Button>
                )}
              </Stack>
            )}
          </>
        ))}
      </>
    );
  }

  function RenderInvoices() {
    if (busy) return <Typography>Loading invoices...</Typography>;
    if (!invoices || invoices.length === 0) {
      return <Typography sx={{ opacity: 0.6 }}>No invoices found.</Typography>;
    }
    return (
      <>
        {invoices.map((i) => {
          const amnt = parseStripeAmount(i.amount_paid);
          const sym = getCurrencySymbol(i.currency);
          return (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{ borderBottom: "1px solid #94969C55", my: "6px" }}
            >
              <Typography color="#94969C">{i.id}</Typography>
              <Stack alignItems={"center"} direction={"row"} spacing={"8px"}>
                <AvatarGroup>
                  {i.subscription_info?.products?.map((p: any) => (
                    <Avatar
                      src={p.images?.[0] || LOGO_PFM}
                      sx={{ height: "32px", width: "32px" }}
                    />
                  ))}
                </AvatarGroup>
                <Typography>{i.subscription_info?.name}</Typography>
              </Stack>
              <Typography>
                {sym}
                {amnt.dollars}.{amnt.cents}
              </Typography>
              <Typography color="#94969C">
                {moment.unix(i.created).format("MMM DD, YYYY HH:mm")}
              </Typography>
            </Stack>
          );
        })}
      </>
    );
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
      maxWidth={"md"}
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <Avatar
              variant="square"
              src={customer?.image}
              sx={{
                height: "56px",
                width: "56px",
                borderRadius: "12px",
                color: "white",
              }}
            />
            <Stack>
              <Typography fontSize={14} fontWeight={400} color={"#94969C"}>
                Customer Details
              </Typography>
              <Typography fontSize={18} fontWeight={600}>
                {customer?.name}
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={18}
                fontWeight={500}
                color="#CECFD2"
                sx={{ minWidth: "150px" }}
              >
                Email
              </Typography>
              <Typography
                fontSize={18}
                fontWeight={500}
                color="#F5F5F6"
                sx={{ minWidth: "150px" }}
              >
                {customer?.email}
              </Typography>
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={18}
                fontWeight={500}
                color="#CECFD2"
                sx={{ minWidth: "150px" }}
              >
                Registeration
              </Typography>
              <Typography
                fontSize={18}
                fontWeight={500}
                color="#F5F5F6"
                sx={{ minWidth: "150px" }}
              >
                {moment
                  .unix(customer?.registeredTimestamp!)
                  .format("MMM DD, YYYY")}
              </Typography>
            </Stack>

            {customer?.discord && (
              <Stack direction={"row"} sx={{ width: "100%" }}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing="8px"
                  sx={{ minWidth: "150px" }}
                >
                  <Typography fontSize={18} fontWeight={500} color="#CECFD2">
                    Discord
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing="8px"
                  sx={{ minWidth: "150px" }}
                >
                  <Avatar
                    src={`https://cdn.discordapp.com/avatars/${customer.discord.id}/${customer.discord.avatar}.png`}
                  />
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    color={customer.discord.banner_color || "#F5F5F6"}
                    sx={{ minWidth: "150px" }}
                  >
                    {customer?.discord.global_name} (@
                    {customer?.discord.username})
                  </Typography>
                </Stack>
              </Stack>
            )}

            <Divider />

            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={"12px"}
              sx={{ width: "100%" }}
            >
              <Typography
                fontSize={18}
                fontWeight={500}
                color="#CECFD2"
                sx={{ minWidth: "150px" }}
              >
                Payment Method
              </Typography>
              <RenderPaymentMethod />
            </Stack>
            <Divider />

            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ width: "100%" }}
            >
              <Typography
                fontSize={18}
                fontWeight={500}
                color="#CECFD2"
                sx={{ minWidth: "150px" }}
              >
                Recent Invoices
              </Typography>
            </Stack>
            <Stack>
              <RenderInvoices />
            </Stack>

            <Divider />

            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ width: "100%" }}
            >
              <Typography
                fontSize={18}
                fontWeight={500}
                color="#CECFD2"
                sx={{ minWidth: "150px" }}
              >
                Active Subscriptions
              </Typography>
            </Stack>
            <RenderSubscriptions />

            <Divider />

            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ width: "100%" }}
            >
              <Typography
                fontSize={18}
                fontWeight={500}
                color="#CECFD2"
                sx={{ minWidth: "150px" }}
              >
                Owned Products
              </Typography>
            </Stack>
            <Stack justifyContent={"start"} direction={"row"}>
              {(!customer?.subscriptions ||
                customer.subscriptions.length === 0) && (
                <Typography sx={{ opacity: 0.6 }}>
                  No products found.
                </Typography>
              )}
              <AvatarGroup>
                {customer?.subscriptions?.map((s) => (
                  <>
                    {s?.products?.map((p) => (
                      <Tooltip title={p.name}>
                        <Avatar src={p.images?.[0] || LOGO_PFM} />
                      </Tooltip>
                    ))}
                  </>
                ))}
              </AvatarGroup>
            </Stack>
            <Divider />
            <Stack direction={"row"} sx={{ width: "100%" }} spacing="12px">
              <Button
                disabled={busy}
                onClick={props.closeHandler}
                size="large"
                fullWidth
                variant="contained"
                color="secondary"
              >
                Close
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
