// The REST API for PFM Dashboard. All the exported function names start with Rest* to indicate these are the rest functions

import { GetCurrentToken } from "../authentication/firebase";
import axios from "axios";
import { AppConfig } from "../config";
import {
  AffiliateTransaction,
  Config,
  PFMActivity,
  PaginatedResponse,
  PayoutRequest,
  ProductConfig,
  ProductConfigTemplate,
  StripeSubscription,
  Subscription,
  User,
} from "@pfm/types";

const BASE_URL = AppConfig.api;

/**
 * Returns the dashboard data for admins.
 */
export async function RestGetAdminDashboard(time: number) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + "/admin/?time=" + time, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as {
    balance: number;
    customers: number;
    sales: number;
    currency: string;
    activities: PFMActivity[];
  };
}

/**
 * Called after a successful signup event. Sends an account confirmation email for the logged in user.
 */
export async function RestPostSignup(signature: any, referrer?: string) {
  const token = await GetCurrentToken();

  // Send the request
  await axios.post(
    BASE_URL + "/accounts/signup",
    { sign: signature, referrer: referrer?.toLowerCase() },
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

/**
 * Update user prefs
 */
export async function RestUpdatePrefs(prefs: {
  newsletter?: boolean;
  account_activity?: boolean;
  subscriptions?: boolean;
}) {
  const token = await GetCurrentToken();
  // Send the request
  const resp = await axios.post(BASE_URL + `/user/prefs`, prefs, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
}

/**
 * Called when user connects teir discord to the pfm
 */
export async function RestUpdateDiscord(profile: any) {
  const token = await GetCurrentToken();

  // Send the request
  await axios.post(BASE_URL + "/user/discord/", profile, {
    headers: {
      Authorization: token,
    },
  });

  return true;
}

/**
 * Returns the user's discord member profile, if they have joined the server.
 */
export async function RestCheckDiscord() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + "/user/discord/check", {
    headers: {
      Authorization: token,
    },
  });

  return resp.data;
}

/**
 * Called after a successful verification event. Sends a welcome email for the registered user.
 */
export async function RestPostVerification(email: string, stamp: number) {
  // Send the request
  await axios.post(BASE_URL + "/accounts/confirmed", {
    stamp: stamp,
    email: email,
  });
}

/**
 * Sends a password reset link via email. Called from forgot password section.
 */
export async function RestRequestPasswordReset(email: string) {
  // Send the request
  await axios.post(BASE_URL + "/accounts/password-reset", {
    email: email,
  });
}

/**
 * Returns the user's profile.
 */
export async function RestGetUserProfile() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + "/accounts/", {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as User;
}

/**
 * Returns the list of users. Paginated.
 */
export async function RestListUsers(
  page: number,
  count: number,
  search?: string
) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(
    BASE_URL +
      `/admin/users/?page=${page}&count=${count}${
        search ? `&search=${search}` : ""
      }`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as PaginatedResponse<User>;
}

/**
 * Adds a new user.
 */
export async function RestAddUser(user: Partial<User>) {
  const token = await GetCurrentToken();
  // Send the request
  const resp = await axios.post(BASE_URL + `/admin/users/`, user, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as User;
}

/**
 * Updates the specified user. Only role, comment, and name is updated.
 */
export async function RestEditUser(user: Partial<User>) {
  const token = await GetCurrentToken();
  // Send the request
  const resp = await axios.put(BASE_URL + `/admin/users/`, user, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
}

/**
 * Disables the login for given user, optionally notifying them.
 */
export async function RestDisableUser(
  userId: string,
  reason: string,
  notify: boolean
) {
  const token = await GetCurrentToken();
  // Send the request
  const resp = await axios.post(
    BASE_URL + `/admin/users/${userId}/disable`,
    {
      notify: notify,
      reason: reason,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data;
}

/**
 * Updates the specified user.
 */
export async function RestEnableUser(userId: string, notify: boolean) {
  const token = await GetCurrentToken();
  // Send the request
  const resp = await axios.post(
    BASE_URL + `/admin/users/${userId}/enable`,
    {
      notify: notify,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data;
}

/**
 * Suspends the specified user.
 */
export async function RestSuspendUser(
  userId: string,
  reason: string,
  notify: boolean
) {
  const token = await GetCurrentToken();
  // Send the request
  const resp = await axios.post(
    BASE_URL + `/admin/users/${userId}/suspend`,
    {
      notify: notify,
      reason: reason,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data;
}

/**
 * Adds a new product to the Stripe.
 */
export async function RestAddProduct(
  product: Partial<{
    name: string;
    description: string;
    image: string;
    metadata: any;
  }>
) {
  const token = await GetCurrentToken();
  // Send the request
  const resp = await axios.post(BASE_URL + `/admin/products/`, product, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as any;
}

/**
 * Adds a new product to the Stripe.
 */
export async function RestEditProduct(
  product: Partial<{
    id: string;
    name: string;
    description: string;
    image: string;
    metadata: any;
  }>
) {
  const token = await GetCurrentToken();
  // Send the request
  const resp = await axios.put(BASE_URL + `/admin/products/`, product, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as any;
}

/**
 * Updates the product's configuration template.
 */
export async function RestEditProductConfigTemplate(
  productId: string,
  template: Partial<ProductConfigTemplate>
) {
  const token = await GetCurrentToken();
  // Send the request
  const resp = await axios.put(
    BASE_URL + `/admin/products/${productId}/template`,
    template,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as any;
}

/**
 * Returns the product's configuration template.
 */
export async function RestGetProductConfigTemplate(productId: string) {
  const token = await GetCurrentToken();
  // Send the request
  const resp = await axios.get(
    BASE_URL + `/admin/products/${productId}/template`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as ProductConfigTemplate | false;
}

/**
 * Returns product config of the specified product for current user.
 */
export async function RestGetProductConfig(
  productId: string,
  subscriptionId: string
) {
  const token = await GetCurrentToken();
  // Send the request
  const resp = await axios.get(
    BASE_URL +
      `/user/products/${productId}/config?subscription=${subscriptionId}`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as ProductConfig | false;
}

/**
 * Returns product config of the specified product of the specified user.
 */
export async function RestViewProductConfig(
  customerId: string,
  productId: string,
  subscriptionId: string
) {
  const token = await GetCurrentToken();
  // Send the request
  const resp = await axios.get(
    BASE_URL +
      `/admin/products/config/${customerId}/${productId}/?subscription=${subscriptionId}`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as ProductConfig | false;
}

/**
 * Returns product config of the specified product for current user.
 */
export async function RestSetProductConfig(
  productId: string,
  cfg: Partial<ProductConfig>
) {
  const token = await GetCurrentToken();
  // Send the request
  console.log("Saving config: ", cfg);
  const resp = await axios.post(
    BASE_URL + `/user/products/${productId}/config`,
    cfg,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as string;
}

/**
 * Updates the product's media.
 */
export async function RestEditProductMedia(
  id: string,
  videos: string[],
  images: string[]
) {
  const token = await GetCurrentToken();
  // Send the request
  const resp = await axios.put(
    BASE_URL + `/admin/products/${id}/media`,
    { images: images, videos: videos },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as any;
}

/**
 * Lists all the existing stripe products.
 * @param page
 * @param count
 * @returns
 */
export async function RestListProducts() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + `/admin/products/`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as any[];
}

/**
 * Lists all the existing stripe products.
 * @param page
 * @param count
 * @returns
 */
export async function RestDeleteProduct(product: string) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.delete(BASE_URL + `/admin/products/${product}/`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as any[];
}

/**
 * Creates a new subscription object.
 */
export async function RestCreateSubscription(sub: Partial<Subscription>) {
  const token = await GetCurrentToken();
  // Send the request
  const resp = await axios.post(BASE_URL + `/admin/subscriptions/`, sub, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as Subscription;
}

export async function RestListSubscriptions() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + `/admin/subscriptions/`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as Subscription[];
}

/**
 * Updates an existing subscription object.
 */
export async function RestEditSubscription(sub: Partial<Subscription>) {
  const token = await GetCurrentToken();
  // Send the request
  const resp = await axios.put(BASE_URL + `/admin/subscriptions/`, sub, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as Subscription;
}

/**
 * Deletes the given subscription.
 * @param sub
 * @returns
 */
export async function RestDeleteSubscription(sub: string) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.delete(BASE_URL + `/admin/subscriptions/${sub}/`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as any;
}

/**
 * Returns a list of subscriptions that users can buy.
 * @returns
 */
export async function RestBrowseSubscriptions() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + `/user/subscriptions/browse`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as Subscription[];
}

export async function RestGetProductMedia(productId: string) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + `/user/products/${productId}/media`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as { videos: string[]; images: string[] };
}

/**
 * Returns a list of active subscriptions of the user
 * @returns
 */
export async function RestListActiveSubscriptions() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + `/user/subscriptions/`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as { data: any; info: Subscription }[];
}

export async function RestGetCustomerPortal() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + `/user/subscriptions/manage`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as string;
}

export async function RestCreateCheckoutSession(subscriptionId: string) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(
    BASE_URL + `/user/subscriptions/${subscriptionId}/checkout`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as { url: string };
}

export async function RestCancelSubscription(
  subscriptionId: string,
  reasons?: string
) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.post(
    BASE_URL + `/user/subscriptions/${subscriptionId}/cancel`,
    { reason: reasons },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data;
}

export async function RestToggleAutoRenew(
  subscriptionId: string,
  renew: boolean
) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.post(
    BASE_URL + `/user/subscriptions/${subscriptionId}/auto-renew`,
    { enable: renew },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data;
}

/**
 * Lists all the existing stripe customers.
 * @param page
 * @param count
 * @returns
 */
export async function RestListCustomers(
  page: number,
  count: number,
  search?: string
) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(
    BASE_URL +
      `/admin/customers/?page=${page}&count=${count}${
        search ? `&search=${search}` : ""
      }`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as PaginatedResponse<User>;

  // const token = await GetCurrentToken();

  // // Send the request
  // const resp = await axios.get(
  //   BASE_URL + `/admin/customers/${from ? "?from=" + from : ""}`,
  //   {
  //     headers: {
  //       Authorization: token,
  //     },
  //   }
  // );
  // return resp.data as (User & {
  //   subscriptions: (Subscription & { data: StripeSubscription })[];
  // })[];
}

/**
 * Lists all the existing stripe customers.
 * @param page
 * @param count
 * @returns
 */
export async function RestListPayments() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + `/admin/payments/`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as {
    balance: number;
    transactions: any[];
    sales: number;
    currency: string;
  };
}

export async function RestGetConfigs() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + `/admin/configs/`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as Config[];
}

export async function RestGetRecentTemplate() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + `/admin/products/recent_template`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as ProductConfigTemplate | false;
}

export async function RestGetMailerliteGroups() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + `/admin/configs/mailerlite_groups`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as any[];
}

export async function RestSetConfig(key: string, value: any) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.post(
    BASE_URL + `/admin/configs/`,
    { key: key, value: value },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data;
}

/**
 * Lists all the existing stripe customers.
 * @param page
 * @param count
 * @returns
 */
export async function RestGetUserInvoices() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + `/user/payments/`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as {
    next_invoice: any;
    transactions: any[];
  };
}

/**
 * Gets the recent payments of a specified customer.
 */
export async function RestGetRecentPayments(cid: string) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + `/admin/customers/${cid}/payments`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as { invoices: any[]; methods: any[] };
}

export async function RestCancelSubscriptionAdmin(sid: string) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.post(
    BASE_URL + `/admin/subscriptions/${sid}/cancel`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as any;
}

export async function RestUpdateJeff(instructions?: string, files?: string) {
  const token = await GetCurrentToken();

  const resp = await axios.post(
    AppConfig.jeff,
    {
      instructions: instructions || undefined,
      files: files || undefined,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return resp;
}
export async function RestToggleJeff(online: boolean) {
  const token = await GetCurrentToken();

  const resp = await axios.post(
    AppConfig.jeff + "power",
    {
      on: online ? true : undefined,
      off: online ? undefined : true,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return resp;
}

export async function RestGetJeffPrompt() {
  const token = await GetCurrentToken();
  const resp = await axios.get(AppConfig.jeff, {
    headers: { Authorization: token },
  });
  return resp.data;
}

export async function RestGetReferrals() {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(BASE_URL + `/user/referrals/`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as {
    withdrawn: number;
    available: number;
    pending: number;
    code: string;
    referrals: { name: string; date: number; earnings: number }[];
  };
}

export async function RestGetPayouts(
  page: number,
  count: number,
  search?: string
) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(
    BASE_URL +
      `/user/referrals/payouts?page=${page}&count=${count}${
        search ? `&search=${search}` : ""
      }`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as PaginatedResponse<PayoutRequest>;
}

/**
 * Returns the transactions of an affiliate user. Supports pagination.
 */
export async function RestListAffiliateTransactions(
  page: number,
  count: number,
  search?: string
) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.get(
    BASE_URL +
      `/user/referrals/transactions?page=${page}&count=${count}${
        search ? `&search=${search}` : ""
      }`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as PaginatedResponse<AffiliateTransaction>;
}

/**
 * Returns the stripe connect url for setting up the account.
 */
export async function RestGetStripeConnectUrl(mode: "login" | "setup") {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.post(
    BASE_URL + `/user/referrals/connect`,
    { mode: mode },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return resp.data as string;
}

/**
 * Updates the user's web3 addresses for affiliation.
 * @param address
 * @param network
 * @param coin
 * @returns
 */
export async function RestUpdateAffiliateProfile(opts: {
  preferred?: string;
  address?: string;
  network?: string;
  coin?: string;
}) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.post(BASE_URL + `/user/referrals/config`, opts, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as string;
}

/**
 * Performs a withdraw request, subtracting the user's balance.
 * @param pr
 * @returns
 */
export async function RestRequestWithdraw(pr: Partial<PayoutRequest>) {
  const token = await GetCurrentToken();

  // Send the request
  const resp = await axios.post(BASE_URL + `/user/referrals/withdraw`, pr, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data as string;
}
