import React, { useEffect, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Button,
  Chip,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { IC_ADD_USER, IC_EDIT, IC_REFRESH, LOGO_PFM } from "../../assets";
import CreateSubscriptionDialog from "../../dialogs/CreateSubscription";
import { useModal } from "mui-modal-provider";
import { RestEditSubscription, RestListSubscriptions } from "../../core/rest";
import { enqueueSnackbar } from "notistack";
import { Subscription } from "@pfm/types";
import moment from "moment";
import EditSubscriptionDialog from "../../dialogs/EditSubscription";
import { getCurrencySymbol, parseStripeAmount } from "../../core/helper";
import { Delete, Edit } from "@mui/icons-material";
import DeleteSubscriptionDialog from "../../dialogs/DeleteSubscription";
import DisableSubscriptionDialog from "../../dialogs/DisableSubscription";

export default function SectionAdminSubscriptions() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const { showModal } = useModal();

  const [busy, setBusy] = useState(true);

  const [data, setData] = useState<any[]>([]);

  async function updateSubscription(sub: Subscription) {
    try {
      setBusy(true);
      // Add the subscription
      await RestEditSubscription(sub);
      enqueueSnackbar("Subscription has been updated. ", {
        variant: "success",
      });
      loadSubscriptions();
    } catch (err: any) {
      enqueueSnackbar("Error updating a subscription. Error: " + err.message, {
        variant: "error",
      });
    }
    setBusy(false);
  }

  async function loadSubscriptions() {
    try {
      //Load data
      setBusy(true);
      const _data = await RestListSubscriptions();
      setData(_data || []);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
    }
    setBusy(false);
  }

  function showCreateDialog() {
    const modal = showModal(CreateSubscriptionDialog, {
      closeHandler(result) {
        modal.destroy();
        if (result) {
          loadSubscriptions();
        }
      },
    });
  }

  function showEditDialog(sub: Subscription) {
    const modal = showModal(EditSubscriptionDialog, {
      closeHandler(result) {
        modal.destroy();
        if (result) {
          loadSubscriptions();
        }
      },
      data: sub,
    });
  }
  function showDeleteDialog(sub: Subscription) {
    const modal = showModal(DeleteSubscriptionDialog, {
      async closeHandler(result) {
        if (result) {
          // proceed to delete the subscription
          sub.deleted = true;
          await updateSubscription(sub);
          loadSubscriptions();
        }
        modal.destroy();
      },
      data: sub,
    });
  }

  async function showDisableDialog(sub: Subscription) {
    // If subscription is disabled, we enable it.
    if (sub.disabled) {
      // Enable
      sub.disabled = false;
      await updateSubscription(sub);
      return;
    } else {
      // Otherwise
      const modal = showModal(DisableSubscriptionDialog, {
        async closeHandler(result) {
          if (result) {
            // Disable the subscription
            sub.disabled = true;
            await updateSubscription(sub);
            loadSubscriptions();
          }
          modal.destroy();
        },
        data: sub,
      });
    }
  }

  useEffect(() => {
    setSection(`Subscriptions`);
    setSectionDescription(
      `Discover subscription plans, customize user experience with price plans and linked products.`
    );
    loadSubscriptions();
  }, []);

  function RenderSubscription(sub: Subscription) {
    return (
      <TableRow>
        <TableCell>
          <Typography color="#94969C">{sub.id}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{sub.name}</Typography>
        </TableCell>
        <TableCell>
          {sub.disabled && (
            <Chip
              label="Disabled"
              variant="outlined"
              color="warning"
              size="small"
            />
          )}
          {!sub.disabled && (
            <Chip
              label="Active"
              variant="outlined"
              color="success"
              size="small"
            />
          )}
        </TableCell>
        <TableCell align="left">
          <Typography color="#94969C">
            {moment.unix(sub.modifiedTimestamp).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C" sx={{ textTransform: "capitalize" }}>
            {sub.kind === "onetime" ? "Lifetime" : sub.schedule}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C" sx={{ textTransform: "capitalize" }}>
            {getCurrencySymbol(sub.price.currency)}
            {parseStripeAmount(sub.price.amount).dollars +
              "." +
              parseStripeAmount(sub.price.amount).cents}
          </Typography>
        </TableCell>
        <TableCell>
          <AvatarGroup
            sx={{
              justifyContent: "start",
              "& .MuiAvatar-root": {
                width: "32px",
                height: "32px",
                fontSize: "14px",
                fontWeight: 600,
                color: "#94969C",
                background: "#1F242F",
              },
            }}
          >
            {sub.products.map((p) => (
              <Tooltip title={p.name}>
                <Avatar src={p.images?.[0] || LOGO_PFM} />
              </Tooltip>
            ))}
          </AvatarGroup>
        </TableCell>

        <TableCell align="right">
          {sub.disabled && (
            <Button
              disabled={busy}
              color="success"
              title="Enable the subscription"
              onClick={() => showDisableDialog(sub)}
            >
              Enable
            </Button>
          )}
          {!sub.disabled && (
            <Button
              disabled={busy}
              color="warning"
              title="Disable the subscription"
              onClick={() => showDisableDialog(sub)}
            >
              Disable
            </Button>
          )}
          <IconButton
            disabled={busy}
            color="error"
            title="Delete"
            onClick={() => showDeleteDialog(sub)}
          >
            <Delete />
          </IconButton>
          <IconButton disabled={busy} onClick={() => showEditDialog(sub)}>
            <Edit />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ p: "40px" }} flex={1}>
      <PFMTable
        sx={{ height: "100%" }}
        title="Subscriptions"
        description="List of all the subscriptions."
        titleBadge={data.length + " Subscriptions"}
        actionButtons={
          <>
            <Button
              variant="contained"
              disabled={busy}
              onClick={loadSubscriptions}
              color="secondary"
              startIcon={<IC_REFRESH />}
            >
              Refresh
            </Button>
            <Button
              disabled={busy}
              variant="contained"
              color="primary"
              startIcon={<IC_ADD_USER />}
              onClick={showCreateDialog}
            >
              Create Subscription
            </Button>
          </>
        }
        tableHead={
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Subscription Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Last Modified</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Products</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderSubscription(d))}</>}
      />
    </Stack>
  );
}
